import React, { useContext, useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from "react-pro-sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../contexts/AuthContext";
import { useError } from "../contexts/ErrorContext";
import { SidebarContext } from "../contexts/SidebarContext";
import { BrandContext } from "../contexts/BrandContext";

import BrandSelect from "../components/wizard/BrandSelect";
import logo from "../assets/logo_icon_white.svg";
import "react-pro-sidebar/dist/css/styles.css";

const Sidebar = (props) => {
    const { collapsed, selectedSidebarNav, sidebarNavArray } = useContext(SidebarContext);
    const { apiUser, setLogOut } = useContext(AuthContext);
    const { setSelectedBrand, brand } = useContext(BrandContext);
    const reportError = useError();

    const [allowedSidebarNav, setAllowedSidebarNav] = useState(undefined);

    useEffect(() => {
        try {
            let allowedPages = apiUser?.orgPages;
            let allowedSidebarNavBuild = [];
            if (allowedPages !== undefined) {
                sidebarNavArray.map((navArray, index) => {
                    if (navArray.children !== undefined && navArray.children.length > 0) {
                        let allowedChildren = []; 
                        navArray.children.map((childArray, childIndex) => {
                            if (allowedPages.includes(childArray.key)) {
                                allowedChildren.push(childArray);
                            }
                        })
    
                        if (allowedChildren.length > 0) {
                            let allowedNav = navArray;
                            allowedNav.children = allowedChildren;
                            allowedSidebarNavBuild.push(allowedNav);
                        }
                    }
                    else {
                        if (allowedPages.includes(navArray.key)) {
                            allowedSidebarNavBuild.push(navArray);
                        }
                    }
                });
            }

            setAllowedSidebarNav(allowedSidebarNavBuild);
  
        } catch (error) {

            reportError(error);

        }

    }, [reportError, apiUser]);

    const isSidebarActive = (sidebarItem) => {
        if (location.pathname === "/") {
            return false;
        } else if (sidebarItem.children !== undefined) {
            return Object.values(sidebarItem.children).reduce((accumulator, childSidebarItem) => {
                return accumulator || location.pathname === childSidebarItem.navLink;
            }, false);
        } else {
            return location.pathname === sidebarItem.navLink;
        }
    };

    const isChildActive = (childSidebarItem) => {
        if (location.pathname === "/") {
            return false;
        } else {
            return location.pathname === childSidebarItem.navLink;
        }
    };

    const location = useLocation();

    // Update Brand on change
    const onBrandSelectChange = (newValue) => {
        setSelectedBrand(newValue.brandId);
    };

    const onLogOut = async () => {
        setLogOut();
    };

    return (
        <ProSidebar collapsed={collapsed} breakPoint={collapsed ? "md" : ''} className="overflow-hidden d-print-none">
            <SidebarHeader className="d-flex align-items-center" style={{ height: "80px"}}>
                <div className="d-flex flex-row align-content-center w-100 m-2">
                    <div className="border-right align-self-center pr-2 ">
                        <Image src={logo} alt="Fortress" width="38" height="38" />
                    </div>
                    {collapsed === false && (
                    <div className="ml-2 w-100 align-self-center body-1">
                        <BrandSelect
                            dropdownOnly={true}
                            brand={brand}
                            onChange={onBrandSelectChange}
                        />
                    </div>
                    )}
                </div>
            </SidebarHeader>
            <SidebarContent>
                <Menu iconShape="square">
                    <div>
                        {allowedSidebarNav !== undefined && allowedSidebarNav.length > 0 && allowedSidebarNav.map((sidebarItem, index) => {
                            if (sidebarItem.key === 'timeline-overview') {
                                return;
                            }
                            
                            return sidebarItem.children !== undefined
                                ? sidebarItem.children.reduce((carry, item) => {
                                      return carry || selectedSidebarNav[item.key];
                                  }, false) === true && (
                                      <SubMenu
                                          key={index}
                                          title={sidebarItem.display}
                                          icon={sidebarItem.icon}
                                          className={isSidebarActive(sidebarItem) ? "active" : ""}
                                      >
                                          {sidebarItem.children.map(
                                                (childSidebarItem, cIndex) => 
                                                    selectedSidebarNav[childSidebarItem.key] === true && (
                                                        <MenuItem key={cIndex} active={isChildActive(childSidebarItem)}>
                                                            {childSidebarItem.display} <Link to={childSidebarItem.navLink} />
                                                        </MenuItem>
                                                    )
                                          )}
                                      </SubMenu>
                                  )
                                : selectedSidebarNav[sidebarItem.key] === true && (
                                      <MenuItem
                                          key={index}
                                          icon={sidebarItem.icon}
                                          className={isSidebarActive(sidebarItem) ? "active" : ""}
                                      >
                                          {sidebarItem.display} <Link to={sidebarItem.navLink} />
                                      </MenuItem>
                                  )
                        })}
                    </div>
                </Menu>
            </SidebarContent>
            <SidebarFooter>
                <Menu iconShape="square" className="pb-0">
                    <SubMenu title="User" icon={<FontAwesomeIcon icon={faUser} />}>
                        <MenuItem>
                            {apiUser?.name}'s Profile <Link to="/" />
                        </MenuItem>
                        <MenuItem>
                            Switch Brands <Link to="/brand" />
                        </MenuItem>
                        {( apiUser.organizationid === 401 && (apiUser.role === "Admin" || apiUser.role === "Manager")) ? (
                                <MenuItem>
                                    Invite Client <Link to="/invite/client" />
                                </MenuItem>
                            ) : null
                        }
                        <MenuItem className="text-danger" onClick={onLogOut}>
                            Logout
                        </MenuItem>
                    </SubMenu>
                </Menu>
            </SidebarFooter>
        </ProSidebar>
    );
};

export default Sidebar;
