import React, { useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";

import { HeaderContext } from "../../contexts/HeaderContext";

import CategoryLevelSalesTable from "../../components/dataRender/dashboardTables/CategoryLevelSalesTable";

const CategoryPerformance = (props) => {
    // console.log("CategoryPerformance", props);

    const { setPageTitle, setPageTimeframe } = useContext(HeaderContext);

    useEffect(() => {
        setPageTitle("Category Performance");
        setPageTimeframe("timeframe");
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    });

    return (
        <main className="d-flex justify-content-start align-items-top">
            <Col>
                <Row className="mt-4">
                    <Col>
                        <CategoryLevelSalesTable />
                    </Col>
                </Row>
            </Col>
        </main>
    );
};

export default CategoryPerformance;
