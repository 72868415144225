import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment";

import { AuthContext } from "../../contexts/AuthContext";
import { useError } from "../../contexts/ErrorContext";
import { BrandContext } from "../../contexts/BrandContext";
import { HeaderContext } from "../../contexts/HeaderContext";
import { ToastContext } from "../../contexts/ToastContext";

import Loading from "../../components/helper/Loading";
import useIsMountedRef from "../../components/helper/isMountedRef";
import ChartDescription from "../../components/helper/ChartDescription";
import ButtonGroupOptions from "../../components/helper/ButtonGroupOptions";
import { PercentFormatter, NumberFormatter } from "../../components/helper/IntlNumberFormatter";

import BootstrapTablesWrapper from "../../components/dataRender/BootstrapTablesWrapper";
import TemplateMetricsCard from "../../components/dataRender/dashboardCards/TemplateMetricsCard";

import SalesChannel from "../BrandReport/widgets/SalesChannel";
import SalesChannelBar from "../BrandReport/widgets/SalesChannelBarChart";
import TopProducts from "../BrandReport/widgets/TopProducts";

import { timelineOverview,fetchChartData } from "../../api/brandData";
import { summaryMetrics, summarySNS } from "../../api/brandData";
import Select from "react-select";
import {isVisibleTooltipState} from "@nivo/tooltip";
import ApexCharts from "apexcharts";

const BrandSummary = (props) => {
    // console.log("BrandSummary", props);

    const { token, visibleMetrics } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);
    const { setPageTitle, pageTitle, setPageTimeframe, selectedDefaultDates, setDates,selectedPreviousCustomDates, currency, marketplace,setComparativeOptions,comparativeValue } = useContext(HeaderContext);
    const reportError = useError();

    const [pageDataLoading, setPageDataLoading] = useState(true);
    const [graphDataLoading, setGraphDataLoading] = useState(true);
    const isMountedRef = useIsMountedRef();

    const [vsPrevious, setVsPrevious] = useState("py");

    const [summaryMetricsData, setSummaryMetricsData] = useState();
    const [summaryMetricsInfo, setSummaryMetricsInfo] = useState();
    const [summarySNSData, setSummarySNSData] = useState();
    const [timelineData, setTimelineData] = useState();
    const [chartData, setChartData] = useState();
    const [chartDataAllIntervals, setChartDataAllIntervals] = useState();
    const [dailyTimelineData, setDailyTimelineData] = useState();
    const [chartIntervalValue, setChartIntervalValue] = useState({value: "yearly", label: "Yearly",isDisabled: false});
    const [chartIntervalValueOptions, setChartIntervalOptions] = useState([
        {value: "monthly", label:"Monthly"},
        {value: "quarterly", label:"Quarterly"},
        {value: "yearly", label:"Yearly"},
    ]);

    const [disableYear, setDisableYear] = useState(true);
    const [chartYearValue, setChartYearValue] = useState([
        {value: "2024", label:"2024" },
        {value: "2023", label:"2023"},
        {value: "2022", label:"2022"},
    ]);
    const [chartYearOptions, setChartYearOptions] = useState([
        {value: "2024", label:"2024" },
        {value: "2023", label:"2023"},
        {value: "2022", label:"2022"},
        {value: "2021", label:"2021"},
        {value: "2020", label:"2020"},
        {value: "2019", label:"2019"},
    ]);

    const [chartSalesValue, setChartSalesValue] = useState({value: "total",label:"Total Sales"});


    useEffect(() => {
        setPageTitle("Brand Summary");
        setPageTimeframe("timeframe");
        setComparativeOptions([
            { label: 'Previous Year', value: 'py' },
            { label: 'Previous Month', value: 'pmsd' },
            { label: 'Previous Days', value: 'pd' },
            { label: 'Custom Date', value: 'pc' },
        ])
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    },[pageTitle]);

    useEffect(() => {
        const getData = async () => {
            try {
                // Use snapshot / time period data
                const res = await summaryMetrics(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                    pc_s: selectedPreviousCustomDates.pc_s,
                    pc_e: selectedPreviousCustomDates.pc_e,
                    wow: selectedDefaultDates.timeframe, // For proper py comparsion -365 days
                    marketplace: marketplace,
                    currency: currency,
                });

                if (isMountedRef.current) {
                    setPageDataLoading(false);
                    setSummaryMetricsData(res?.data?.data);
                    setSummaryMetricsInfo(res?.data?.info);
                }

                // console.log(res?.data?.data);
            } catch (err) {
                toast(err.message);
                reportError(err);
            }

        };

        const getSNSData = async () => {
            try {
                // Use Only BrandId, get latest
                const res = await summarySNS(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                    wow: "daily", // For proper py comparsion -365 days
                    marketplace: marketplace,
                    currency: currency,
                });

                if (isMountedRef.current) {
                    // setPageDataLoading(false);
                    setSummarySNSData(res?.data);
                }


                // console.log(res?.data);
            } catch (err) {
                toast(err.message);
                reportError(err);
            }
        };

        const getTimelineData = async () => {
            try {
                const res = await timelineOverview(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                    wow: selectedDefaultDates.timeframe,
                    currency: currency,
                    marketplace: marketplace,
                });

                if (isMountedRef.current) {
                    setPageDataLoading(false);
                    setTimelineData(res.data);
                    // if(selectedDefaultDates.timeframe === "daily"){
                    //     setDailyTimelineData(res.data);
                    // }
                }

                // console.log(res?.data);
            } catch (err) {
                toast(err.message);
                reportError(err);
            }
        };

        const getDailyTimelineData = async () => {
            try {
                const res = await timelineOverview(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                    wow: "daily",
                    currency: currency,
                    marketplace: marketplace,
                });

                if (isMountedRef.current) {
                    setDailyTimelineData(res.data);
                }

                // console.log(res?.data);
            } catch (err) {
                toast(err.message);
                reportError(err);
            }
        };

        if (
            brand.brandId !== undefined &&
            selectedDefaultDates.tf_s !== undefined &&
            selectedDefaultDates.tf_e !== undefined &&
            selectedPreviousCustomDates !== undefined
        ) {
            setPageDataLoading(true);

            setSummaryMetricsData();
            setSummarySNSData();
            setTimelineData();

            getData();
            getSNSData();
            getTimelineData();

            //for datatable defaulted to daily
            // if(selectedDefaultDates.timeframe !== 'daily'){
            //     getDailyTimelineData();
            // }

            // let dayLength = moment(selectedDefaultDates.tf_e).diff(moment(selectedDefaultDates.tf_s),'days');
            //
            // setChartIntervalOptions([
            //     {value: "daily", label:"Daily", isDisabled: dayLength>60 },
            //     {value: "wow", label:"Weekly", isDisabled: dayLength<14 || dayLength>365},
            //     {value: "mom", label:"Monthly", isDisabled: dayLength<60},
            //     {value: "quarter", label:"Quarterly", isDisabled: dayLength<100},
            //     {value: "annual", label:"Yearly", isDisabled: dayLength<400},
            // ]);

            // let options = {
            //     daily: "Daily",
            //     wow: "Weekly",
            //     mom: "Monthly",
            //     quarter: "Quarterly",
            //     annual: "Yearly",
            // }
            //
            // setChartIntervalValue({value: selectedDefaultDates.timeframe,label: options[selectedDefaultDates.timeframe],isDisabled: false});
        }
    }, [token, brand, marketplace, selectedDefaultDates, selectedPreviousCustomDates, currency, isMountedRef, toast, reportError]);

    // useEffect(() => {
    //     const getChartData = async () => {
    //         try {
    //             const res = await fetchChartData(token, {
    //                 brandid: brand.brandId,
    //                 currency: currency,
    //                 marketplace: marketplace,
    //             });
    //
    //             if (isMountedRef.current) {
    //                 setPageDataLoading(false);
    //                 setChartDataAllIntervals(res.data);
    //                 setChartData(res.data.yearly);
    //             }
    //
    //             // console.log(res?.data);
    //         } catch (err) {
    //             toast(err.message);
    //             reportError(err);
    //         }
    //     };
    //
    //     if (brand.brandId !== undefined) {
    //         setPageDataLoading(true);
    //         getChartData();
    //     }
    // }, [token, brand, marketplace, currency, isMountedRef, toast, reportError]);

    // useEffect(() => {
    //     const getTimelineData = async () => {
    //         try {
    //             const res = await timelineOverview(token, {
    //                 brandid: brand.brandId,
    //                 tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
    //                 tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
    //                 wow: selectedDefaultDates.timeframe,
    //                 currency: currency,
    //                 marketplace: marketplace,
    //             });
    //
    //             if (isMountedRef.current) {
    //                  setGraphDataLoading(false);
    //
    //                 setTimelineData(res.data);
    //             }
    //
    //             // console.log(res?.data);
    //         } catch (err) {
    //             toast(err.message);
    //             reportError(err);
    //         }
    //     };
    //
    //     if (
    //        true
    //     ) {
    //         setGraphDataLoading(true);
    //
    //         // setTimelineData();
    //         // getTimelineData();
    //
    //     }
    // },[])

    // useEffect(() =>{
    //
    //     if(chartDataAllIntervals !== undefined){
    //         let years = chartYearValue.map((val) =>{
    //             return parseInt(val.value);
    //         });
    //         let split = chartSalesValue.value === 'split';
    //         let newChartData = [];
    //
    //         if(chartIntervalValue.value !== "yearly"){
    //             chartDataAllIntervals[chartIntervalValue.value].data.forEach((val) => {
    //                 if(years.includes(val.group)){
    //                     if(split){
    //                         if(!val.label.includes("Total")){
    //                             newChartData.push(val);
    //                         }
    //                     }
    //                     else{
    //                         if(val.label.includes("Total")){
    //                             newChartData.push(val);
    //                         }
    //                     }
    //                 }
    //             });
    //         }
    //         else{
    //             chartDataAllIntervals[chartIntervalValue.value].data.forEach((val) => {
    //                 if(split){
    //                     if(!val.label.includes("Total")){
    //                         newChartData.push(val);
    //                     }
    //                 }
    //                 else{
    //                     if(val.label.includes("Total")){
    //                         newChartData.push(val);
    //                     }
    //                 }
    //             });
    //         }
    //
    //         let chartData = {
    //             data: newChartData,
    //             labels: chartDataAllIntervals[chartIntervalValue.value].labels,
    //         }
    //
    //         setChartData(chartData);
    //     }
    //
    // },[chartIntervalValue,chartYearValue,chartDataAllIntervals,chartSalesValue]);

    const getPreviousDates = (suffix = ': ') => {
        if (comparativeValue.value === "py") {
            return `${summaryMetricsInfo?.py?.py_s} - ${summaryMetricsInfo?.py?.py_e}${suffix}`;
        } else if (comparativeValue.value === "pmsd") {
            return `${summaryMetricsInfo?.pmsd?.pmsd_s} - ${summaryMetricsInfo?.pmsd?.pmsd_e}${suffix}`;
        } else if (comparativeValue.value === "pc"){
            return `${summaryMetricsInfo?.previous_custom?.pc_s} - ${summaryMetricsInfo?.previous_custom?.pc_e}${suffix}`;
        }

        else {
            return `${summaryMetricsInfo?.pt?.pt_s} - ${summaryMetricsInfo?.pt?.pt_e}${suffix}`;
        }
    };

    const templateMetricsCardDefault = {
        data: summaryMetricsData,
        dataMeta: summaryMetricsInfo,
        vsPrevious: comparativeValue.value,
        pageDataLoading: (isMountedRef.current && pageDataLoading),
    }

    const updateChartInterval = (option) => {
        setChartIntervalValue(option);
        // setChartData(chartDataAllIntervals[option.value]);

        if(option.value === 'yearly'){
            setDisableYear(true);
            setChartYearValue([]);

        }
        else{
            setDisableYear(false);
            if(chartYearValue.length < 1){
                setChartYearValue([
                    {value: "2024", label:"2024"},
                    {value: "2023", label:"2023"},
                    {value: "2022", label:"2022"},
                ]);
            }
        }
    }

    const updateChartYear = (option) => {
        if(option === null){
            option = [];
        }

        setChartYearValue(option);
    }

    try {
        return pageDataLoading === true ? (
            <Loading></Loading>
        ) : (
            <main className="d-flex justify-content-start align-items-top">
                <Col>
                    {/*<Row className="d-flex align-items-center py-4">*/}
                    {/*    <Col>*/}
                    {/*        <Row>*/}
                    {/*            <Col sm={6} className="">*/}
                    {/*                <Row className="pl-3">*/}
                    {/*                    <Col>*/}
                    {/*                        <p className="info-text pl-1">Select a time period to compare</p>*/}
                    {/*                    </Col>*/}
                    {/*                    */}
                    {/*                </Row>*/}
                    {/*            </Col>*/}
                    {/*        </Row>*/}
                    {/*        <Row className="">*/}
                    {/*            <Col sm={6} className="">*/}
                    {/*                <ButtonGroupOptions*/}
                    {/*                    value={vsPrevious}*/}
                    {/*                    options={[*/}
                    {/*                        { name: 'Previous Year', value: 'py' },*/}
                    {/*                        { name: 'Previous Month', value: 'pmsd' },*/}
                    {/*                        { name: 'Previous Days', value: 'pd' },*/}
                    {/*                    ]}*/}
                    {/*                    onChange={(value) => setVsPrevious(value)}*/}
                    {/*                />*/}
                    {/*            </Col>*/}
                    {/*            <Col className="d-flex align-items-center">*/}
                    {/*                <p className="info-text pr-3 text-left">Compared period: {getPreviousDates('')}</p>*/}
                    {/*            </Col>*/}
                    {/*        </Row>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
    
                    <Row className="mt-5">
                        <Col lg={3}>
                            <Row>
                                <TemplateMetricsCard
                                    {...templateMetricsCardDefault}
                                    textCardProps={{
                                        headerTitle: "Sales Overview",
                                        direction: 'vertical',
                                        variant: 'info',
                                        minHeight: "auto",
                                    }}
                                    metricsTemplate={[
                                        {
                                            name: 'Total Sales',
                                            metric: 'total_sales',
                                            type: 'currency',
                                            change_metric: 'change_pct',
                                        },
                                        {
                                            name: 'Paid (PPC)',
                                            metric: 'paid_sales',
                                            change_metric: 'paid_change_pct',
                                            type: 'currency',
                                            tooltip: 'Sales from PPC (Pay Per Click) Ad Campaigns',
                                            visible: visibleMetrics.paid,
                                        },
                                        {
                                            name: 'SNS',
                                            metric: 'sns_sales',
                                            type: 'currency',
                                            tooltip: 'Sales from Subscribe & Save',
                                            visible: visibleMetrics.sns,
                                        },
                                        {
                                            name: 'Organic',
                                            metric: 'organic_sales',
                                            change_metric: 'organic_change_pct',
                                            type: 'currency',
                                            tooltip: 'Sales from organic search rankings',
                                            visible: visibleMetrics.paid,
                                        },
                                    ]}
                                />
                            </Row>
                        </Col>
    
                        <Col lg={9}>
                            <div style={{ height: "34em" }}>
                                <div className="position-relative">
                                    <div className="chart-interval-select d-flex">
                                        {/*<div className="w-100 mr-2 ">*/}

                                        {/*    <Select*/}
                                        {/*        className="text-dark w-100"*/}
                                        {/*        placeholder="Select Sales"*/}
                                        {/*        value={chartSalesValue}*/}
                                        {/*        options={[{value: "split",label:"Paid vs Organic vs SNS"},{value: "total",label:"Total Sales"}]}*/}
                                        {/*        name="SalesSelect"*/}
                                        {/*        getOptionLabel={(option) => `${option.label}`}*/}
                                        {/*        getOptionValue={(option) => option.value}*/}
                                        {/*        onChange={(option) => {setChartSalesValue(option)}}*/}
                                        {/*        isMulti={false}*/}
                                        {/*        allowSelectAll={false}*/}
                                        {/*        isSearchable={false}*/}
                                        {/*        isLoading={false}*/}
                                        {/*        noOptionsMessage={(inputValue) => "No Sales Available"}*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                        {/*<div className="w-100 mr-2 ">*/}
                                        {/*    <Select*/}
                                        {/*        className="text-dark w-100"*/}
                                        {/*        placeholder="Select Year"*/}
                                        {/*        value={chartYearValue}*/}
                                        {/*        options={chartYearOptions}*/}
                                        {/*        name="IntervalSelect"*/}
                                        {/*        getOptionLabel={(option) => `${option.label}`}*/}
                                        {/*        getOptionValue={(option) => option.value}*/}
                                        {/*        onChange={(option) => {*/}
                                        {/*            updateChartYear(option)*/}
                                        {/*        }}*/}
                                        {/*        isMulti={true}*/}
                                        {/*        allowSelectAll={false}*/}
                                        {/*        isSearchable={false}*/}
                                        {/*        isDisabled={disableYear}*/}
                                        {/*        isLoading={false}*/}
                                        {/*        noOptionsMessage={(inputValue) => "No Years Available"}*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                        {/*<div className="w-100">*/}
                                        {/*    <Select*/}
                                        {/*        className="text-dark w-100"*/}
                                        {/*        placeholder="Select Interval"*/}
                                        {/*        value={chartIntervalValue}*/}
                                        {/*        options={chartIntervalValueOptions}*/}
                                        {/*        name="IntervalSelect"*/}
                                        {/*        getOptionLabel={(option) => `${option.label}`}*/}
                                        {/*        getOptionValue={(option) => option.value}*/}
                                        {/*        onChange={(option) => {*/}
                                        {/*            updateChartInterval(option)*/}
                                        {/*        }}*/}
                                        {/*        isMulti={false}*/}
                                        {/*        allowSelectAll={false}*/}
                                        {/*        isSearchable={false}*/}
                                        {/*        isLoading={false}*/}
                                        {/*        noOptionsMessage={(inputValue) => "No Intervals Available"}*/}
                                        {/*    />*/}
                                        {/*</div>*/}

                                    </div>
                                </div>
                                <SalesChannel
                                    showConfigButton={false}
                                    headerTitle="Sell-through Over Time by Sales Channel"
                                    widgetData={timelineData}
                                    widgetDataLoading={timelineData && isMountedRef.current && pageDataLoading}
                                    widgetOptions={[
                                        "Total Sales",
                                        "Organic Sales",
                                        "Paid Sales",
                                        "Previous Year Total Sales",
                                    ]}
                                />
                                {/*{chartData &&*/}
                                {/*    <SalesChannelBar*/}
                                {/*        showConfigButton={false}*/}
                                {/*        headerTitle="Year Over Year Sales Growth"*/}
                                {/*        // headerTitle="Sell-through Over Time by Sales Channel"*/}
                                {/*        widgetData={chartData}*/}
                                {/*        widgetDataLoading={chartData && isMountedRef.current && pageDataLoading}*/}
                                {/*    />*/}

                                {/*}*/}
                            </div>
                        </Col>
                    </Row>

                    <Row className="d-flex flex-row">
                        <TemplateMetricsCard
                            visible={false}
                            {...templateMetricsCardDefault}
                            textCardProps={{
                                headerTitle: "PPC Sponsored Summary",
                                direction: 'horizontal',
                                minHeight: "auto",
                            }}
                            metricsTemplate={[
                                {
                                    name: 'Sales',
                                    metric: 'paid_sales',
                                    change_metric: 'paid_change_pct',
                                    type: 'currency',
                                    tooltip: 'Sales attributed to PPC advertising for the time frame',
                                    visible: visibleMetrics.paid,
                                },
                                {
                                    name: 'Spend',
                                    metric: 'paid_spend',
                                    type: 'currency',
                                    tooltip: 'Paid PPC spend for the time frame',
                                    visible: visibleMetrics.paid,
                                },
                                {
                                    name: 'ROAS',
                                    metric: 'roas',
                                    type: 'ratio',
                                    tooltip: 'PPC Return on Ad Spend = Paid Sales / Spend',
                                    visible: visibleMetrics.paid,
                                },
                                {
                                    name: 'TACOS',
                                    metric: 'tacos',
                                    type: 'number_percent',
                                    tooltip: 'PPC Total Advertising Cost of Sale = Paid Spend / Total Sales',
                                    visible: visibleMetrics.paid,
                                },
                            ]}
                        />
                        
                        <TemplateMetricsCard
                            {...templateMetricsCardDefault}
                            width={'50%'}
                            textCardProps={{
                                headerTitle: "Order Details",
                                minHeight: "15em",
                            }}
                            metricsTemplate={[
                                {
                                    name: 'Orders',
                                    metric: 'total_orders',
                                    change_metric: 'orders_change_pct',
                                    type: 'number',
                                    tooltip: 'Total Orders',
                                },
                                {
                                    name: 'New to Brand',
                                    metric: 'ntb',
                                    type: 'number',
                                    tooltip: 'FBA New to Brand Orders',
                                    visible: visibleMetrics.ntb,
                                },
                                {
                                    name: 'Units',
                                    metric: 'total_units',
                                    change_metric: 'units_change_pct',
                                    type: 'number',
                                    tooltip: 'Total Units Sold',
                                },
                                {
                                    name: 'Average Order Value',
                                    metric: 'aov',
                                    type: 'currency',
                                    format: {
                                        decimal: true,
                                        previous: {
                                            decimal: true,
                                        }
                                    },
                                    tooltip: 'Average Order Value = Total Sales / Total Orders',
                                },
                            ]}
                        />
    
                        <TemplateMetricsCard
                            {...templateMetricsCardDefault}
                            width={'50%'}
                            textCardProps={{
                                headerTitle: "Traffic",
                                minHeight: "15em",
                            }}
                            metricsTemplate={[
                                {
                                    name: 'Page Views',
                                    metric: 'page_views',
                                    type: 'number',
                                    tooltip: `
                                        "Hits in your Amazon.com presence for the selected time period as long
                                        as you had a valid offer" (whether you had the Buy Box or not);
                                        May be calculated as run rate: 
                                        ${summaryMetricsInfo?.bbx?.diff} of ${summaryMetricsInfo?.pt?.diff} days used.
                                    `,
                                    visible: visibleMetrics.traffic,
                                },
                                {
                                    name: 'Sessions',
                                    metric: 'sessions',
                                    type: 'number',
                                    tooltip: `
                                        "Sessions are visits to your Amazon.com pages by a user. All activity within
                                        a 24-hour period is considered a session.";
                                        May be calculated as run rate:
                                        ${summaryMetricsInfo?.bbx?.diff} of ${summaryMetricsInfo?.pt?.diff} days used.
                                    `,
                                    visible: visibleMetrics.traffic,
                                },
                                {
                                    name: 'BBX %',
                                    metric: 'bbx',
                                    change_metric: 'bbx_change',
                                    type: 'percent',
                                    format: {
                                        previous: {
                                            decimal: true,
                                        }
                                    },
                                    tooltip: `
                                        "This is the percentage of page views where the buy box (the add to shopping
                                        cart link) appeared on the page for customers to add your product to their
                                        cart";
                                        Recent days may have limited data:
                                        ${summaryMetricsInfo?.bbx?.min} to ${summaryMetricsInfo?.bbx?.max} shown.
                                    `,
                                    visible: visibleMetrics.traffic,
                                },
                                {
                                    name: 'CNV %',
                                    metric: 'cnv',
                                    change_metric: 'cnv_change',
                                    type: 'percent',
                                    format: {
                                        previous: {
                                            decimal: true,
                                        }
                                    },
                                    tooltip: 'Orders / Sessions',
                                    visible: visibleMetrics.traffic,
                                },
                            ]}
                        />
    
                        <TemplateMetricsCard
                            {...templateMetricsCardDefault}
                            width={'50%'}
                            textCardProps={{
                                headerTitle: "PPC Sponsored Traffic",
                                minHeight: "15em",
                            }}
                            metricsTemplate={[
                                {
                                    name: 'Impressions',
                                    metric: 'paid_impr',
                                    type: 'number',
                                    tooltip: 'PPC Advertising impressions for the time frame',
                                    visible: visibleMetrics.paid,
                                },
                                {
                                    name: 'Clicks',
                                    metric: 'paid_clicks',
                                    type: 'number',
                                    format: {
                                        previous: {
                                            show_change: false,
                                            display_arrow: false,
                                            extra: ' CTR',
                                            decimal: true,
                                            type: 'percent',
                                            value: summaryMetricsData?.paid_ctr
                                        }
                                    },
                                    tooltip: (
                                        <div>
                                            <p>Clicks on ads during the time frame</p>
                                            <p className="mb-0">Clickthrough Rate (Clicks / Impressions)</p>
                                        </div>
                                    ),
                                    visible: visibleMetrics.paid,
                                },
                                {
                                    name: 'Orders',
                                    metric: 'paid_orders',
                                    format: {
                                        previous: {
                                            show_change: false,
                                            display_arrow: false,
                                            extra: ' CNV',
                                            decimal: true,
                                            type: 'percent',
                                            value: summaryMetricsData?.paid_cnv
                                        }
                                    },
                                    type: 'number',
                                    tooltip: (
                                        <div>
                                            <p>Orders attributed to advertising during the time frame</p>
                                            <p className="mb-0">Conversion Rate (Orders / Clicks)</p>
                                        </div>
                                    ),
                                    visible: visibleMetrics.paid,
                                },
                                {
                                    name: 'Units',
                                    metric: 'paid_units',
                                    type: 'number',
                                    tooltip: 'Units sold attributed to advertising during the time frame',
                                    visible: visibleMetrics.paid,
                                },
                            ]}
                        />
    
                        <TemplateMetricsCard
                            visible={visibleMetrics.sns}
                            {...templateMetricsCardDefault}
                            width={'50%'}
                            textCardProps={{
                                headerTitle: "Subscribe & Save",
                                minHeight: "15em",
                            }}
                            metricsTemplate={[
                                {
                                    name: 'Sales',
                                    metric: 'sns_sales',
                                    type: 'currency',
                                    tooltip: 'Subscribe & Save revenue for the time frame',
                                    visible: visibleMetrics.sns,
                                },
                                {
                                    name: 'Units',
                                    metric: 'sns_units',
                                    type: 'number',
                                    tooltip: 'Subscribe & Save units shipped for the time frame',
                                    visible: visibleMetrics.sns,
                                },
                                {
                                    row_data: summarySNSData,
                                    name: '+8 Week Estimates',
                                    metric: 'wk8_units',
                                    change_metric: (<div></div>),
                                    type: 'number',
                                    tooltip: (
                                        <div>
                                            <p>
                                                Estimated units to ship in the next 8 weeks, as of {summarySNSData?.latest_date ?? "N/A"}
                                            </p>
                                        </div>
                                    ),
                                    visible: visibleMetrics.sns,
                                },
                                {
                                    row_data: summarySNSData,
                                    name: 'Total Subscriptions',
                                    metric: 'subscriptions',
                                    type: 'number',
                                    format: {
                                        previous: {
                                            show_change: true,
                                            display_arrow: false,
                                            decimal: false,
                                            type: 'number',
                                            value: summarySNSData?.previous_subscriptions_change
                                        }
                                    },
                                    tooltip: (
                                        // +x (# of new Subscriptions since the most recent report BEFORE the time frame)
                                        // (mouseover: (New Subscriptions since $date)
                                        <div>
                                            <p>Total Subscriptions as of {summarySNSData?.latest_date ?? "N/A"}</p>
                                            <p>{`${summarySNSData?.previous_subscriptions_change} New Subscriptions since ${
                                                summarySNSData?.previous_date ?? "N/A"
                                            }`}</p>
                                            <p className="mb-0">
                                                # of new Subscriptions since the most recent report BEFORE the time frame
                                            </p>
                                        </div>
                                    ),
                                    visible: visibleMetrics.sns,
                                },
                            ]}
                        />
                    </Row>
                    
                    <Row className="mt-4">
                        <Col sm={12}>
                            <div style={{ height: "34em" }}>
                                <TopProducts showConfigButton={false} />
                            </div>
                        </Col>
                    </Row>
    
                    <Row className="mt-4">
                        <Col>
                            {timelineData?.dataTable !== undefined && (
                                <BootstrapTablesWrapper
                                    data={timelineData?.dataTable}
                                    keyField="date"
                                    description={
                                        <ChartDescription
                                            title={"Sell Through Over Time by Sales Channel"}
                                            description={"Raw data of daily sales in dollar over time broken out by sales type"}
                                        />
                                    }
                                    hasPagination={true}
                                    hasFilter={true}
                                    defaultSorted={[
                                        {
                                            dataField: "date",
                                            order: "desc",
                                        },
                                    ]}
                                    columns={[
                                        {
                                            dataField: "date",
                                            text: "Date",
                                            sort: true,
                                            type: "date",
                                            insertFilter: "textFilter",
                                        },
                                        {
                                            dataField: "total_sales",
                                            text: "Total Sales",
                                            type: "number",
                                            sort: true,
                                            formatter: "dollarFormatter",
                                        },
                                        {
                                            dataField: "py_date",
                                            text: "PY Date",
                                            sort: true,
                                            type: "date",
                                            insertFilter: "textFilter",
                                            hidden: true,
                                        },
                                        {
                                            dataField: "py_total_sales",
                                            text: "Previous Year",
                                            type: "number",
                                            sort: true,
                                            formatter: "dollarFormatter",
                                            hidden: true,
                                        },
                                        {
                                            dataField: "previous_year_change",
                                            text: "PY Change ($)",
                                            type: "number",
                                            sort: true,
                                            formatter: "tableColorDollarFormatter",
                                            hidden: true,
                                        },
                                        {
                                            dataField: "previous_year_change_pct",
                                            text: "PY Change (%)",
                                            type: "number",
                                            sort: true,
                                            formatter: "percentColorFormatter",
                                            // hidden: false,
                                        },
                                        {
                                            dataField: "sns_sales",
                                            text: "SNS Sales",
                                            type: "number",
                                            sort: true,
                                            formatter: "dollarFormatter",
                                        },
                                        {
                                            dataField: "py_sns_sales",
                                            text: "PY SNS Sales",
                                            type: "number",
                                            sort: true,
                                            formatter: "dollarFormatter",
                                            hidden: true,
                                        },
                                        {
                                            dataField: "previous_year_sns_change",
                                            text: "PY SNS Change ($)",
                                            type: "number",
                                            sort: true,
                                            formatter: "tableColorDollarFormatter",
                                            hidden: true,
                                        },
                                        {
                                            dataField: "previous_year_sns_change_pct",
                                            text: "PY SNS Change (%)",
                                            type: "number",
                                            sort: true,
                                            formatter: "percentColorFormatter",
                                        },
                                        {
                                            dataField: "organic_sales",
                                            text: "Organic Sales",
                                            type: "number",
                                            sort: true,
                                            formatter: "dollarFormatter",
                                        },
                                        {
                                            dataField: "py_organic_sales",
                                            text: "PY Organic Sales",
                                            type: "number",
                                            sort: true,
                                            formatter: "dollarFormatter",
                                            hidden: true,
                                        },
                                        {
                                            dataField: "previous_year_organic_change",
                                            text: "PY Organic Change ($)",
                                            type: "number",
                                            sort: true,
                                            formatter: "tableColorDollarFormatter",
                                            hidden: true,
                                        },
                                        {
                                            dataField: "previous_year_organic_change_pct",
                                            text: "PY Organic Change (%)",
                                            type: "number",
                                            sort: true,
                                            formatter: "percentColorFormatter",
                                        },
                                        {
                                            dataField: "paid_sales",
                                            text: "Paid Sales",
                                            type: "number",
                                            sort: true,
                                            formatter: "intlCurrencyFormatter",
                                        },
                                        {
                                            dataField: "py_paid_sales",
                                            text: "PY Paid Sales",
                                            type: "number",
                                            sort: true,
                                            formatter: "intlCurrencyFormatter",
                                            hidden: true,
                                        },
                                        {
                                            dataField: "previous_year_paid_change",
                                            text: "PY Paid Change ($)",
                                            type: "number",
                                            sort: true,
                                            formatter: "intlColorCurrencyFormatter",
                                            hidden: true,
                                        },
                                        {
                                            dataField: "previous_year_paid_change_pct",
                                            text: "PY Paid Change (%)",
                                            type: "number",
                                            sort: true,
                                            formatter: "percentColorFormatter",
                                        },
                                    ]}
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
            </main>
        );
    } catch (error) {
        reportError(error);
    }
};

export default BrandSummary;
