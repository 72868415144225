import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";

import { AuthContext } from "../../../contexts/AuthContext";
import { BrandContext } from "../../../contexts/BrandContext";
import { HeaderContext } from "../../../contexts/HeaderContext";

import DataError from "../../../components/helper/DataError";
import WidgetSpinner from "../../../components/helper/WidgetSpinner";
import useIsMountedRef from "../../../components/helper/isMountedRef";
import BootstrapTablesWrapper from "../../../components/dataRender/BootstrapTablesWrapper";

import { getNumberFormatter } from "../../../components/helper/NumberFormatters";
import { formatMonthDateStr, formatDateStr } from "../../../components/helper/DateVars";
import { comparativeBrand } from "../../../api/brandData";

const BrandComparative = (props) => {
    // console.log('BrandComparative', props);

    const { token } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { currency, marketplace, timezone, weekstart } = useContext(HeaderContext);

    const [[dataError, errorStatus, errorMessage, errorResponse], setDataError] = useState([false, "", "", ""]);
    const isMountedRef = useIsMountedRef();

    const [brandData, setBrandData] = useState();
    const [metadata, setMetadata] = useState();
    const [dataLoading, setDataLoading] = useState(true);

    useEffect(() => {
        const getBrandData = async () => {
            try {
                // Use snapshot / time period data
                const res = await comparativeBrand(token, {
                    brandid: brand.brandId,
                    weekstart: weekstart,
                    currency: currency,
                    marketplace: marketplace,
                    timezone: timezone,
                });

                // console.log(isMountedRef, res);
                
                if (isMountedRef.current) {
                    setDataLoading(false);
                    setBrandData(res.data?.brand);
                    setMetadata(res.data?.params);
                }
                
            } catch (err) {
                setDataLoading(false);
                setDataError([true, err.response?.status, err.response?.statusText, err.response?.data]);
            }

            setDataLoading(false);
        };

        if (
            brand.brandId !== undefined
        ) {
            setDataLoading(true);

            getBrandData();
        }
    }, [token, brand, weekstart, currency, marketplace, isMountedRef]);

    const displayBrandComparativeTableRow = (cell, row, rowIndex) => {
        let formatter = getNumberFormatter(row.metric.format, brand, currency);
        let pct_formatter = getNumberFormatter('percent_whole', brand, currency);
        let color = (cell.change_pct >= 0 ? 'text-success' : 'text-danger');
        
        return (
            <div className="d-flex px-3 flex-nowrap w-100">
                <div className="d-flex flex-row justify-content-around w-100">
                    <div className="">{formatter.format(cell.tf)}</div>
                    <div className={`text-center ${color}`}>{pct_formatter.format(cell.change_pct)}</div>
                    <div className="text-gray-dark">{formatter.format(cell.pt)}</div>
                </div>
            </div>
        );
    }

    const renderBrandTable = (data) => {
        // console.log(data);

        let columns = [
            /*0*/ {
                dataField: "index",
                text: "",
                type: "number",
                sort: false,
                hidden: true,
            },
            /*1*/ {
                dataField: "metric",
                text: "Traffic and Conversion Metrics",
                type: "string",
                sort: false,
                hidden: false,
                headerStyle: (colum, colIndex) => {
                    return { minWidth: "100px" };
                },
                formatter: (cell, row, rowIndex) => {
                    return cell.name;
                },
                csvFormatter: (cell, row, rowIndex) => {
                    return cell.name;
                },
            },
            /*2*/ {
                dataField: "mtd",
                text: "Month to Date",
                sort: false,
                type: "number",
                formatter: displayBrandComparativeTableRow,
                headerFormatter: (column, index, { sortElement, filterElement }) => {
                    return (
                        <div className="">
                            <Row className="flex-nowrap justify-content-around w-100 mx-0">
                                <Col className="px-1 text-center" style={{minWidth: "100px"}}>
                                    <div className="flex-nowrap">Current MTD</div>
                                    <div className="flex-nowrap text-gray-dark text-sm">{formatDateStr(metadata?.mtd.tf_s ?? '')} - </div>
                                    <div className="flex-nowrap text-gray-dark text-sm">{formatDateStr(metadata?.mtd.tf_e ?? '')}</div>
                                </Col>
                                <Col className="px-1 text-center" style={{minWidth: "100px"}}>
                                    <div className="flex-nowrap">Previous MTD</div>
                                    <div className="flex-nowrap text-gray-dark text-sm">{formatDateStr(metadata?.mtd.pt_s ?? '')} - </div>
                                    <div className="flex-nowrap text-gray-dark text-sm">{formatDateStr(metadata?.mtd.pt_e ?? '')}</div>
                                </Col>
                            </Row>
                        </div>
                    );
                },
                csvFormatter: (cell, row, rowIndex) => {
                    return cell.tf;
                },
            },
            /*3*/ {
                dataField: "mtd_previous",
                text: "Month to Date Previous",
                sort: false,
                hidden: true,
                type: "number",
                formatter: (cell, row, rowIndex) => {
                    let formatter = getNumberFormatter(row.metric.format, brand, currency);
        
                    return (
                        <div className="d-flex px-3 flex-nowrap w-100">
                            <div className="d-flex flex-row justify-content-between w-100">
                                <div className="">{formatter.format(row['mtd'].pt)}</div>
                            </div>
                        </div>
                    );
                },
                csvFormatter: (cell, row, rowIndex) => {
                    return row['mtd'].pt;
                },
            },
            /*4*/ {
                dataField: "lm",
                text: "Last Month",
                sort: false,
                type: "number",
                headerStyle: (colum, colIndex) => {
                    return { minWidth: "300px" };
                },
                formatter: displayBrandComparativeTableRow,
                headerFormatter: (column, index, { sortElement, filterElement }) => {
                    return (
                        <div className="">
                        <Row className="flex-nowrap justify-content-around w-100 mx-0">
                            <Col className="px-1 text-center" style={{minWidth: "100px"}}>
                                <div className="flex-nowrap">Last Full Month</div>
                                <div className="flex-nowrap text-gray-dark text-sm">{formatMonthDateStr(metadata?.lm?.tf_s ?? '')}</div>
                            </Col>
                            <Col className="px-1 text-center" style={{minWidth: "100px"}}>
                                <div className="flex-nowrap">Previous Full Month</div>
                                <div className="flex-nowrap text-gray-dark text-sm">{formatMonthDateStr(metadata?.lm?.pt_s ?? '')}</div>
                            </Col>
                        </Row>
                        </div>
                    );
                },
                csvFormatter: (cell, row, rowIndex) => {
                    return cell.tf;
                },
            },
            /*5*/ {
                dataField: "lm_previous",
                text: "Last Month Previous",
                sort: false,
                hidden: true,
                type: "number",
                formatter: (cell, row, rowIndex) => {
                    let formatter = getNumberFormatter(row.metric.format, brand, currency);
        
                    return (
                        <div className="d-flex px-3 flex-nowrap w-100">
                            <div className="d-flex flex-row justify-content-between w-100">
                                <div className="">{formatter.format(row['lm'].pt)}</div>
                            </div>
                        </div>
                    );
                },
                csvFormatter: (cell, row, rowIndex) => {
                    return row['lm'].pt;
                },
            },
            /*6*/ {
                dataField: "ytd",
                text: "Year to Date",
                sort: false,
                type: "number",
                formatter: displayBrandComparativeTableRow,
                headerFormatter: (column, index, { sortElement, filterElement }) => {
                    return (
                        <div className="">
                            <Row className="flex-nowrap justify-content-around w-100 mx-0">
                                <Col className="px-1 text-center" style={{minWidth: "100px"}}>
                                    <div className="flex-nowrap">Current YTD</div>
                                    <div className="flex-nowrap text-gray-dark text-sm">{formatDateStr(metadata?.ytd?.tf_s ?? '')} - </div>
                                    <div className="flex-nowrap text-gray-dark text-sm">{formatDateStr(metadata?.ytd?.tf_e ?? '')}</div>
                                </Col>
                                <Col className="px-1 text-center" style={{minWidth: "100px"}}>
                                    <div className="flex-nowrap">Previous YTD</div>
                                    <div className="flex-nowrap text-gray-dark text-sm">{formatDateStr(metadata?.ytd?.pt_s ?? '')} - </div>
                                    <div className="flex-nowrap text-gray-dark text-sm">{formatDateStr(metadata?.ytd?.pt_e ?? '')}</div>
                                </Col>
                            </Row>
                        </div>
                    );
                },
                csvFormatter: (cell, row, rowIndex) => {
                    return cell.tf;
                },
            },
            /*7*/ {
                dataField: "ytd_previous",
                text: "Year to Date Previous",
                sort: false,
                hidden: true,
                type: "number",
                formatter: (cell, row, rowIndex) => {
                    let formatter = getNumberFormatter(row.metric.format, brand, currency);
        
                    return (
                        <div className="d-flex px-3 flex-nowrap w-100">
                            <div className="d-flex flex-row justify-content-between w-100">
                                <div className="">{formatter.format(row['ytd'].pt)}</div>
                            </div>
                        </div>
                    );
                },
                csvFormatter: (cell, row, rowIndex) => {
                    return row['ytd'].pt;
                },
            },
        ]

        return (
            <BootstrapTablesWrapper
                data={data ?? []}
                keyField="index"
                description={''}
                tableOnly={true}
                headerClasses={'bg-gray header-align-top'}
                hasPagination={false}
                hasFilter={true}
                hasSearch={false}
                columnModal={'none'}
                defaultSorted={[]}
                columns={columns}
            />
        )
    }

    return (
        <div>
            {dataLoading ? (
                <WidgetSpinner />
            ) : dataError === true ? (
                <DataError showFull="true" status={errorStatus} message={errorMessage} response={errorResponse}></DataError>
            ) : (brandData && renderBrandTable(brandData))}
        </div>
    )
};

export default BrandComparative;
