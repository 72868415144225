import React, { useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";

import { HeaderContext } from "../../contexts/HeaderContext";

import BuyboxTimeline from "../BrandReport/widgets/BuyboxTimeline";
import BuyboxAggregate from "../BrandReport/widgets/BuyboxAggregate";
import BuyboxTable from "../BrandReport/widgets/BuyboxTable";

const BuyboxOverview = (props) => {
    //Grabs user data from AuthContext.js

    const { setPageTitle, setPageTimeframe, setPageMarketplace } = useContext(HeaderContext);

    /**
     * useEffect with no second arguments = Run on every render
     *
     * Return statement is the cleanup function, runs when the component unmounts (Usually used to cleanup. Here it is used to clean up the page title)
     */
    useEffect(() => {
        setPageTitle("Buy Box Central");
        setPageMarketplace('Amazon');
        setPageTimeframe("timeframe");
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    });

    return (
        <main>
            <div className="container-fluid">
                <Row className="mt-4">
                    <Col xl="4">
                        <div style={{ height: "400px" }}>
                            <BuyboxAggregate
                                showConfigButton={false}
                                headerTitle="Buybox Aggregate"
                                headerSubtitle="Cumulative Buybox win percentage"
                                snapshotTime={false}
                            />
                        </div>
                    </Col>

                    <Col xl="8">
                        <div style={{ height: "400px" }}>
                            <BuyboxTimeline
                                showConfigButton={false}
                                headerTitle="Average BBX Over Time"
                                headerSubtitle="Buybox win percentages over time"
                                snapshotTime={false}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <BuyboxTable
                            showConfigButton={false}
                            headerTitle="Buybox Performance by Product"
                            headerSubtitle=""
                            snapshotTime={false}
                        />
                    </Col>
                </Row>
            </div>
        </main>
    );
};

export default BuyboxOverview;
