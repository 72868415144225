import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import {HeaderContext} from "../../contexts/HeaderContext";
import Select from "react-select";
import BarChart from "./components/BarChart.js";
import BootstrapTablesWrapper from "../../components/dataRender/BootstrapTablesWrapper";
import fortress from "../../api/fortress";
import {AuthContext} from "../../contexts/AuthContext";
import {BrandContext} from "../../contexts/BrandContext";
import WidgetSpinner from "../../components/helper/WidgetSpinner";

const PromotionalReporting = (props) =>{
    const { token } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);

    //state
    const { setPageTitle, setPageTimeframe, setPageMarketplace,currency } = useContext(HeaderContext);

    //data
    const [primeDayEvent, setPrimeEventDay] = useState([
        {value: "Summer", label:"Summer Prime Day" },
        {value: "Fall", label:"Fall Prime Day" },
    ]);
    const [primeDayEventOptions, setPrimeDayEventOptions] = useState([
        {value: "Summer", label:"Summer Prime Day" },
        {value: "Fall", label:"Fall Prime Day" },
    ]);
    const [primeDay, setPrimeDay] = useState([
        {value: "1", label:"Day 1"},
        {value: "2", label:"Day 2"},
    ]);
    const [primeDayOptions, setPrimeDayOptions] = useState([
        {value: "1", label:"Day 1"},
        {value: "2", label:"Day 2"},
    ]);
    const [year, setYear] = useState([
        {value: "2022", label:"2022"},
        {value: "2023", label:"2023"},
        {value: "2024", label:"2024"},
    ]);
    const [promoDayEvent, setPromoEventDay] = useState([
        {value: "Summer", label:"Summer Prime Day" },
        {value: "Fall", label:"Fall Prime Day" },
    ]);
    const [promoDay, setPromoDay] = useState([
        {value: "1", label:"Day 1"},
        {value: "2", label:"Day 2"},
    ]);
    const [promoYear, setPromoYear] = useState([
        {value: "2022", label:"2022"},
        {value: "2023", label:"2023"},
        {value: "2024", label:"2024"},
    ]);
    const [yearOptions, setYearOptions] = useState([
        {value: "2022", label:"2022"},
        {value: "2023", label:"2023"},
        {value: "2024", label:"2024"},
    ]);

    const [asinEvent,setAsinEvent] = useState([
        {value: "Fall_01_2024", label: "Prime Day Fall 2024 Day 1"},
        {value: "Fall_02_2024", label: "Prime Day Fall 2024 Day 2"},
    ]);
    const [asinSelectOptions,setAsinSelectOptions] = useState([
        {value: "Summer_01_2022", label: "Prime Day Summer 2022 Day 1"},
        {value: "Summer_02_2022", label: "Prime Day Summer 2022 Day 2"},
        {value: "Fall_01_2022", label: "Prime Day Fall 2022 Day 1"},
        {value: "Fall_02_2022", label: "Prime Day Fall 2022 Day 2"},
        {value: "Summer_01_2023", label: "Prime Day Summer 2023 Day 1"},
        {value: "Summer_02_2023", label: "Prime Day Summer 2023 Day 2"},
        {value: "Fall_01_2023", label: "Prime Day Fall 2023 Day 1"},
        {value: "Fall_02_2023", label: "Prime Day Fall 2023 Day 2"},
        {value: "Summer_01_2024", label: "Prime Day Summer 2024 Day 1"},
        {value: "Summer_02_2024", label: "Prime Day Summer 2024 Day 2"},
        {value: "Fall_01_2024", label: "Prime Day Fall 2024 Day 1"},
        {value: "Fall_02_2024", label: "Prime Day Fall 2024 Day 2"},
    ]);

    const [primeDayChartData, setPrimeDayChartData] = useState({
        data: [],
        labels: ["Summer 2022","Fall 2022","Summer 2023"],
    });

    const [promoChartData, setPromoChartData] = useState({
        data: [],
        labels: ["Summer 2022","Fall 2022","Summer 2023"],
    });



    const primeDayTableColumnData = [
            {
                dataField: "event",
                text: "Event",
                sort: false,
                type: "string",
                insertFilter: "textFilter",
            },
            {
                dataField: "day_1",
                text: "Day 1 Sales",
                type: "number",
                sort: false,
                formatter: "dollarFormatter",
            },
            {
                dataField: "day_2",
                text: "Day 2 Sales",
                type: "number",
                sort: false,
                formatter: "dollarFormatter",
            },
            {
                dataField: "total_sales",
                text: "Total Sales",
                type: "number",
                sort: false,
                formatter: "dollarFormatter",
            },
            {
                dataField: "units_day_1",
                text: "Day 1 Units",
                type: "number",
                sort: true,
            },
            {
                dataField: "units_day_2",
                text: "Day 2 Units",
                type: "number",
                sort: true,
            },
            {
                dataField: "total_units",
                text: "Total Units",
                type: "number",
                sort: true,
            }
        ];

    const promoTableColumnData = [
        {
            dataField: "promo",
            text: "Promotion",
            sort: false,
            type: "string",
            insertFilter: "textFilter",
        },
        {
            dataField: "promo_sales_percent",
            text: "% Promo Sales",
            type: "number",
            sort: false,
            formatter: "percentColorFormatter",
        },
        {
            dataField: "non_promo_sales_percent",
            text: "% Non Promo Sales",
            type: "number",
            sort: false,
            formatter: "percentColorFormatter",
        },
        {
            dataField: "promo_sales",
            text: "$ Promo Sales",
            type: "number",
            sort: false,
            formatter: "dollarFormatter",
        },
        {
            dataField: "non_promo_sales",
            text: "$ Non Promo Sales",
            type: "number",
            sort: false,
            formatter: "dollarFormatter",
        },
        {
            dataField: "promo_units",
            text: "Promo Units",
            type: "number",
            sort: false,
        },
        {
            dataField: "non_promo_units",
            text: "Non Promo Units",
            type: "number",
            sort: false,
        }
    ];
    const asinPerformanceColumnData = [
        {
            dataField: "vpart",
            text: "Vpart",
            sort: true,
            type: "string",
            insertFilter: "textFilter",
        },
        {
            dataField: "title",
            text: "Title",
            sort: true,
            type: "string",
            insertFilter: "textFilter",
        },
        {
            dataField: "event",
            text: "Event",
            sort: true,
            type: "string",
            insertFilter: "textFilter",
        },
        // {
        //     dataField: "date",
        //     text: "Date",
        //     sort: true,
        //     type: "string",
        //     insertFilter: "textFilter",
        // },
        // {
        //     dataField: "upc",
        //     text: "UPC",
        //     sort: true,
        //     type: "string",
        //     insertFilter: "textFilter",
        // },
        {
            dataField: "asin",
            text: "ASIN",
            sort: true,
            type: "string",
            insertFilter: "textFilter",
        },
        {
            dataField: "asin_count",
            text: "ASIN Count",
            sort: true,
            type: "string",
            insertFilter: "textFilter",
        },
        {
            dataField: "total_units",
            text: "Total Units",
            sort: true,
            type: "string",
            insertFilter: "textFilter",
        },
        {
            dataField: "total_sales",
            text: "Total Sales",
            sort: true,
            type: "number",
            formatter: "dollarFormatter",
        },
        {
            dataField: "paid_sales",
            text: "Paid Sales",
            sort: true,
            type: "number",
            formatter: "dollarFormatter",
        },
        {
            dataField: "paid_spend",
            text: "Paid Spend",
            sort: true,
            type: "number",
            formatter: "dollarFormatter",
        },
        {
            dataField: "organic_sales",
            text: "Organic Sales",
            sort: true,
            type: "number",
            formatter: "dollarFormatter",
        },
        {
            dataField: "previous_sales",
            text: "Total Sales (Previous 2 weeks Daily Avg)",
            sort: true,
            type: "number",
            formatter: "dollarFormatter",
        },
        {
            dataField: "previous_units",
            text: "Total Units (Previous 2 weeks Daily Avg)",
            sort: true,
            type: "string",
            insertFilter: "textFilter",
        },
        {
            dataField: "sales_change_percent",
            text: "Sales Change Percent",
            type: "number",
            sort: true,
            formatter: "percentColorFormatter",
        },
        {
            dataField: "paid_spend_change_percent",
            text: "Paid Spend Change Percent",
            type: "number",
            sort: true,
            formatter: "percentColorFormatter",
        },
        {
            dataField: "previous_paid_spend",
            text: "Previous Paid Spend (Previous 2 weeks Daily Avg)",
            sort: true,
            type: "number",
            formatter: "dollarFormatter",
        },
        {
            dataField: "paid_sales_change_percent",
            text: "Paid Sales Change Percent",
            type: "number",
            sort: true,
            formatter: "percentColorFormatter",
        },
        {
            dataField: "previous_paid_sales",
            text: "Previous Paid Sales (Previous 2 weeks Daily Avg)",
            sort: true,
            type: "number",
            formatter: "dollarFormatter",
        },
    ];

    const [primeDayTableData, setPrimeDayTableData] = useState([]);
    const [promoTableData, setPromoTableData] = useState([]);
    const [asinPerformanceData, setAsinPerformanceData] = useState([]);
    const [primeDayLoading, setPrimeDayLoading] = useState(false);
    const [promosLoading, setPromosLoading] = useState(false);
    const [asinPerformanceLoading, setAsinPerformanceLoading] = useState(false);



    useEffect(() => {
        setPageTitle("Promotional Reporting");
        setPageMarketplace('Amazon');
        setPageTimeframe("currency");
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    });

    useEffect( () =>{
        console.log(primeDay);
        if(primeDayEvent.length !== null && primeDay!== null && year !== null){
            setPrimeDayLoading(true);
            fortress.get("promotions/fetch-prime-days", {
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    brand_id: brand.brandId,
                    currency: currency,
                    season: primeDayEvent.map(row=> row.value),
                    day: primeDay.map(row=> row.value),
                    year: year.map(row => row.value),
                },
            })
                .then(res =>{
                    setPrimeDayChartData(res.data.prime_chart);
                    if(res.data.data_table.length !== 0){
                        setPrimeDayTableData(res.data.data_table);
                    }
                    else{
                        setPrimeDayTableData([{}]);
                    }
                    setPrimeDayLoading(false);
                });
        }
    },[brand,primeDayEvent,primeDay,year,currency]);

    useEffect( () =>{
        if(promoDayEvent.length !== null && promoDay !== null && promoYear !== null){
            setPromosLoading(true);
            fortress.get("promotions/fetch-promo-reporting", {
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    brand_id: brand.brandId,
                    currency: currency,
                    season: promoDayEvent.map(row=> row.value),
                    day: promoDay.map(row=> row.value),
                    year: promoYear.map(row => row.value),
                },
            })
                .then(res =>{
                    if(res.data.promo_table.length !== 0){
                        setPromoTableData(res.data.promo_table);
                    }
                    else{
                        setPromoTableData([{}]);
                    }
                    setPromoChartData(res.data.promo_chart);

                    setPromosLoading(false);
                });
        }
    },[brand,promoYear,promoDay,promoDayEvent,currency]);

    useEffect( () =>{
        if(asinEvent !== null){
            setAsinPerformanceLoading(true);
            fortress.get("promotions/fetch-prime-asin-performance", {
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    brand_id: brand.brandId,
                    currency: currency,
                    prime_events: asinEvent.map(row=> row.value),
                },
            })
                .then(res =>{
                    if(res.data.asin_table.length !== 0){
                        setAsinPerformanceData(res.data.asin_table);
                    }
                    else{
                        setAsinPerformanceData([{}]);
                    }

                    setAsinPerformanceLoading(false);
                });
        }
    },[brand,asinEvent,currency]);

    return (
        <main className="p-3">
            <Row className="mt-4">
                <Col>
                    <h3>Prime Day Reporting</h3>
                    <h5 className="mt-5">Event Over Event Report</h5>
                    <hr/>
                    <Row>
                        <Col className="d-flex">
                            <Select
                                className="text-dark w-100 rounded-0 mr-3"
                                placeholder="Select Prime Day Event"
                                value={primeDayEvent}
                                options={primeDayEventOptions}
                                onChange={(option) => setPrimeEventDay(option)}
                                isMulti={true}
                                allowSelectAll={false}
                                isSearchable={false}
                                isLoading={false}
                            />
                            {/*<Select*/}
                            {/*    className="text-dark w-100 rounded-0 mr-3"*/}
                            {/*    placeholder="Select Day"*/}
                            {/*    value={primeDay}*/}
                            {/*    options={primeDayOptions}*/}
                            {/*    onChange={(option) => setPrimeDay(option)}*/}
                            {/*    isMulti={true}*/}
                            {/*    allowSelectAll={false}*/}
                            {/*    isSearchable={false}*/}
                            {/*    isLoading={false}*/}
                            {/*/>*/}
                            <Select
                                className="text-dark w-100 rounded-0"
                                placeholder="Select Year"
                                value={year}
                                options={yearOptions}
                                onChange={(option) => setYear(option)}
                                isMulti={true}
                                allowSelectAll={true}
                                isSearchable={false}
                                isLoading={false}
                            />
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col className="text-center">
                            {primeDayLoading ? <WidgetSpinner/> : (
                                <BootstrapTablesWrapper
                                    data={primeDayTableData}
                                    columns={primeDayTableColumnData}
                                    columnModal="none"
                                    keyField="event"
                                    hasSearch={false}
                                    hasPagination={false}
                                    hasFilter={false}
                                />
                            )}

                        </Col>
                        <Col className="text-center">
                            <div style={{height: "34em"}}>
                                {primeDayLoading ? <WidgetSpinner/> : primeDayChartData.data.length !== 0 && (
                                    <BarChart showConfigButton={false}
                                              headerTitle=" "
                                              widgetData={primeDayChartData}
                                              stacked={true}
                                    />
                                )}
                            </div>
                        </Col>
                    </Row>

                </Col>
            </Row>


            <Row className="mt-4">
                <Col>
                    <h5>Promo vs Non-Promo Report</h5>
                    <hr/>
                    <Row>
                        <Col className="d-flex">
                            <Select
                                className="text-dark w-100 rounded-0 mr-3"
                                placeholder="Select Prime Day Event"
                                value={promoDayEvent}
                                options={primeDayEventOptions}
                                onChange={(option) => setPromoEventDay(option)}
                                isMulti={true}
                                allowSelectAll={false}
                                isSearchable={false}
                                isLoading={false}
                            />
                            {/*<Select*/}
                            {/*    className="text-dark w-100 rounded-0 mr-3"*/}
                            {/*    placeholder="Select Day"*/}
                            {/*    value={promoDay}*/}
                            {/*    options={primeDayOptions}*/}
                            {/*    onChange={(option) => setPromoDay(option)}*/}
                            {/*    isMulti={true}*/}
                            {/*    allowSelectAll={false}*/}
                            {/*    isSearchable={false}*/}
                            {/*    isLoading={false}*/}
                            {/*/>*/}
                            <Select
                                className="text-dark w-100 rounded-0"
                                placeholder="Select Year"
                                value={promoYear}
                                options={yearOptions}
                                onChange={(option) => setPromoYear(option)}
                                isMulti={true}
                                allowSelectAll={true}
                                isSearchable={false}
                                isLoading={false}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="text-center">
                            {promosLoading ? <WidgetSpinner /> : (
                                <BootstrapTablesWrapper
                                    data={promoTableData}
                                    columns={promoTableColumnData}
                                    columnModal="none"
                                    keyField="promo"
                                    hasSearch={false}
                                    hasPagination={false}
                                    hasFilter={false}
                                />
                            ) }
                        </Col>
                        <Col className="text-center">
                            <div style={{height: "34em"}}>
                                {promosLoading ? <WidgetSpinner /> : promoChartData.data.length !== 0 && (
                                    <BarChart showConfigButton={false}
                                              headerTitle=""
                                              widgetData={promoChartData}
                                              stacked={false}
                                    />
                                )}
                            </div>
                        </Col>
                    </Row>

                </Col>
            </Row>

            <Row className="mt-4">
                <Col>
                    <h5>ASINs Performance by Sales and Units</h5>
                    <hr/>
                    <Row>
                        <Col className="d-flex">
                            <Select
                                className="text-dark w-50 rounded-0 mr-3"
                                placeholder="Select Prime Day Event"
                                value={asinEvent}
                                options={asinSelectOptions}
                                onChange={(option) => setAsinEvent(option)}
                                isMulti={true}
                                allowSelectAll={false}
                                isSearchable={false}
                                isLoading={false}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="text-center">
                            {asinPerformanceLoading ? <WidgetSpinner /> : (
                                <BootstrapTablesWrapper
                                    data={asinPerformanceData}
                                    columns={asinPerformanceColumnData}
                                    columnModal="none"
                                    keyField="promo_product"
                                    hasPagination={true}
                                    hasFilter={false}
                                />
                            )}

                        </Col>
                    </Row>

                </Col>
            </Row>
        </main>

    );
}

export default PromotionalReporting;