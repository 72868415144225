import React from "react";
import { Spinner } from "react-bootstrap";

const Loading = (props) => {
    return (
        <div className="w-100">
            <div style={{ height: "calc(100vh - 70px)" }} className="d-flex justify-content-center align-items-center">
                <div style={{ width: 300 }} className="d-flex justify-content-center align-items-center">
                    <Spinner
                        as="span"
                        animation="grow"
                        role="status"
                        aria-hidden="true"
                        // animation="border"
                    ></Spinner>
                </div>
            </div>
        </div>
    );
};

export default Loading;
