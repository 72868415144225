import React from "react";

const DataErrorDetail = (props) => {
    // console.log('DataErrorDetail', props);

    let output = [];
    Object.keys(props.errors).forEach((key, index) => { 
        output.push(<p key={index}>{props.errors[key]}</p>);
    });

    return (<div>{output}</div>);
};

const DataError = (props) => {
    // console.log('DataError', props);

    const {
        status = props.errorStatus ?? props.response?.status ?? '',
        message = props.errorMessage ?? props.response?.statusText ?? '',
        response = props.errorResponse ?? props.response?.data ?? '',
        fullHeight = props.displayFull === false ? {} : { height: 'calc(100vh - 70px)' },
        showFull = props.showFull ?? true,
        errorStyle = props.errorStyle ?? {}
    } = props;

    const style = {
        ...fullHeight,
        ...errorStyle,
    };

    return (
        <div>
            <div style={style} className="d-flex justify-content-center align-items-center">
                <div>
                    <h2>Error: {status} {message}</h2>
                    { ((showFull === "true" || showFull === true) && response !== undefined) &&
                        <div>
                            <h4>{response.message}</h4>
                            { response.errors !== undefined &&
                                <DataErrorDetail errors={response.errors} />
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default DataError;
