import React, { useContext, useState, useEffect } from "react";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";
import moment from "moment";

import { buyboxOverview } from "../../../api/reseller";

import { AuthContext } from "../../../contexts/AuthContext";
import { BrandContext } from "../../../contexts/BrandContext";
import { HeaderContext } from "../../../contexts/HeaderContext";
import { ToastContext } from "../../../contexts/ToastContext";

import {
    graph_blue_light,
    defaultGraphLabelStyle,
} from "../../../components/helper/ColorFormatter";
import WidgetSpinner from "../../../components/helper/WidgetSpinner";

function BuyboxTimeline(props) {
    const { token } = useContext(AuthContext);
    const { selectedDefaultDates, dataLoading, marketplace } = useContext(HeaderContext);

    const [timelineData, setTimelineData] = useState([]);
    const [widgetLoading, setWidgetLoading] = useState(true);
    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);

    const percentFormatter = new Intl.NumberFormat(brand.currencyLocale ?? "en-US", {
        style: "percent",
        minimumFractionDigits: 2,
    });

    const { showConfigButton = true, headerTitle = "Buybox Timeline", headerSubtitle = "", snapshotTime = false } = props;

    /**
     * useEffect with empty array as second argument = Run only once (on the first render)
     */
    useEffect(() => {
        const getData = async () => {
            setWidgetLoading(true);
            try {
                // Always daily
                const res = await buyboxOverview(token, {
                    brandid: brand.brandId,
                    tf_s: snapshotTime ? moment(selectedDefaultDates.tp_s).format('YYYY-MM-DD') : moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                    tf_e: snapshotTime ? moment(selectedDefaultDates.tp_e).format('YYYY-MM-DD') : moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                    timeframe: selectedDefaultDates.timeframe,
                    marketplace: marketplace,
                });
                
                const bbxdata = res?.data?.timeline_columned ?? [];

                setTimelineData(bbxdata);
                // console.log(bbxdata);

                if ((bbxdata?.expression ?? []).length > 0) {
                    setWidgetLoading(false);
                }
                else {
                    setWidgetLoading(false);
                    toast("No data for Buybox Timeline");
                }
                
            } catch (err) {
                setWidgetLoading(false);
                toast(err.message);
            }
        };

        if (!dataLoading) {
            getData();
        }
    }, [token, brand.brandId, toast, dataLoading, selectedDefaultDates, snapshotTime, marketplace]);

    const renderChart = () => {
        let charttype = "area";

        let xaxisType = "category";
        let dt = false;

        if (
            (snapshotTime ? selectedDefaultDates.tp_s : selectedDefaultDates.tf_s) ===
            (snapshotTime ? selectedDefaultDates.tp_e : selectedDefaultDates.tf_e)
        ) {
            charttype = "bar";

            dt = true;
        }
        if (timelineData?.expression !== undefined && Object.keys(timelineData?.expression)?.length <= 1) {
            charttype = "bar";

            dt = true;
        }

        if (
            selectedDefaultDates.timeframe !== "mom" &&
            selectedDefaultDates.timeframe !== "quarter" &&
            selectedDefaultDates.timeframe !== "annual"
        ) {
            xaxisType = "datetime";
        }

        var options = {
            chart: {
                type: charttype,
                height: "100%",
                toolbar: { show: false },
                animations: { enabled: false },
                dropShadow: { enabled: false },
                selection: { enabled: false },
                zoom: { enabled: false },
            },
            dataLabels: {
                enabled: dt,
                formatter: function (val, opts) {
                    return percentFormatter.format(val);
                },
            },

            markers: {
                size: 0,
                style: "hollow",
            },
            colors: [graph_blue_light],
            title: {
                text: undefined,
            },
            grid: {
                show: true,
                borderColor: "#eaecec",
                xaxis: { lines: { show: true } },
                yaxis: { lines: { show: true } },
                strokeDashArray: [5, 0],
            },
            xaxis: {
                type: xaxisType,
                categories: timelineData?.timeframe_date ?? [],
                labels: {
                    formatter: (value, timestamp, index) => {
                        if (selectedDefaultDates.timeframe === "mom" || selectedDefaultDates.timeframe === "monthly") {
                            return moment(value).format("MMM YYYY");
                        }
                        if (selectedDefaultDates.timeframe === "wow" || selectedDefaultDates.timeframe === "weekly") {
                            return moment(value).format("MMM DD, YYYY");
                        }
                        if (selectedDefaultDates.timeframe === "daily") {
                            return moment(value).format("MMM DD, YYYY");
                        }
                        return moment(value).format("MMM DD, YYYY");
                    },
                    style: defaultGraphLabelStyle,
                },
                tickPlacement: "between",
            },
            yaxis: {
                title: {
                    text: "Buybox %",
                    style: {
                        fontFamily: "sans-serif",
                        fontWeight: 400,
                        fontSize: 14,
                    },
                },
                labels: {
                    formatter: function (value, timestamp, index) {
                        return (parseFloat(value) * 100).toFixed(0);
                    },
                    style: defaultGraphLabelStyle,
                },
                min: 0,
                max: 1,
                tickAmount: 5,
            },
            stroke: {
                curve: "smooth",
                lineCap: "butt",
                width: 3,
                colors: [graph_blue_light],
            },
            tooltip: {
                followCursor: true,
                x: {
                    show: true,
                },
                y: {
                    formatter: function (value, timestamp, index) {
                        if (value !== undefined && value !== null) return percentFormatter.format(parseFloat(value));
                    },
                },
            },
            fill: {
                type: "solid",
                opacity: 0.2,
            },
        };

        let series = [
            {
                name: "Buybox",
                data: timelineData?.expression ?? [],
            },
        ];

        return (
            <ResponsiveContainer>
                <Chart options={options} series={series} type={charttype} />
            </ResponsiveContainer>
        );
    };

    return (
        <div className="card h-100" /*style={{ overflow: "auto" }}*/>
            <div className="card-header bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <h5>{props.headerTitle}</h5>
                        {props.headerSubtitle !== "" ? (
                            <p className="body-1 text-secondary mb-0">{props.headerSubtitle}</p>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="ml-auto ">
                        {showConfigButton === true ? (
                            <button
                                type="button"
                                className="btn btn-primary btn-sm "
                                onClick={() => {
                                    //TODO: Add Configuration Modal
                                }}
                            >
                                Config
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>

            <div className="card-body w-100 pt-0 pb-0">{widgetLoading ? <WidgetSpinner /> : renderChart()}</div>
        </div>
    );
}

export default BuyboxTimeline;
