import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

const dynamicBiddingOptions = [
    { value: "Up & Down", label: "Up & Down" },
    { value: "Fixed", label: "Fixed" },
    { value: "Down Only", label: "Down Only" },
];

function AutoAcos(props) {
    const { headerTitle = "Auto ACOS Test Widget", headerSubtitle = "" } = props;

    const [keywordBid, setKeywordBid] = useState(1.0);
    const [tosba, setTosba] = useState(2.0);
    const [dynamicBidding, setDynamicBidding] = useState("Up & Down");

    const getDynamicBidPercent = () => (dynamicBidding === "Up & Down" ? 1.0 : 0);

    const [targetAcos, setTargetAcos] = useState(keywordBid);

    useEffect(() => {
        let tosbaAddition = parseFloat(keywordBid * tosba);

        let dynamicBiddingAddition = parseFloat(
            getDynamicBidPercent() * (parseFloat(keywordBid) + parseFloat(keywordBid * tosba))
        );

        let newTargetAcos = parseFloat(keywordBid) + tosbaAddition + dynamicBiddingAddition;

        setTargetAcos(newTargetAcos);
    }, [keywordBid, tosba, dynamicBidding, getDynamicBidPercent]);

    const onKeywordBidChange = (newV) => {
        setKeywordBid(newV.target.value);
    };
    const onTOSBAChange = (event) => {
        setTosba(event.target.value / 100);
    };
    const onDynamicBiddingChange = (event) => {
        setDynamicBidding(event.value);
    };

    const onTargetAcosChange = (event) => {
        let newTACOS = event.target.value;
        let ratio = newTACOS / targetAcos;
        setKeywordBid(keywordBid * ratio);
    };

    const renderNumbers = () => {
        return (
            <table className="table">
                <tbody>
                    <tr>
                        <th style={{ width: "14em" }}>TACOS</th>
                        <td style={{ width: "14em" }}>
                            <Form.Control
                                type="number"
                                placeholder="Enter TACOS"
                                value={targetAcos}
                                onChange={(value) => onTargetAcosChange(value)}
                            />
                        </td>
                        <td>
                            <div
                                className="progress-bar"
                                fon=""
                                role="progressbar"
                                aria-valuenow={targetAcos}
                                aria-valuemin={0}
                                aria-valuemax={targetAcos}
                                style={{ fontSize: "14px", width: "100%" }}
                            >
                                {targetAcos}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th style={{ width: "14em" }}>keywordBid</th>
                        <td style={{ width: "14em" }}>
                            <Form.Control
                                type="number"
                                placeholder="Enter KeywordBid"
                                value={keywordBid}
                                onChange={(value) => onKeywordBidChange(value)}
                            />
                        </td>
                        <td>
                            <div
                                className="progress-bar"
                                fon=""
                                role="progressbar"
                                aria-valuenow={keywordBid}
                                aria-valuemin="0"
                                aria-valuemax={targetAcos}
                                style={{ fontSize: "14px", width: (keywordBid / targetAcos) * 100 + "%" }}
                            >
                                {keywordBid}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th style={{ width: "14em" }}>TOSBA</th>
                        <td style={{ width: "14em" }}>
                            <Form.Control
                                type="number"
                                placeholder="Enter TOSBA"
                                value={tosba * 100}
                                onChange={(value) => onTOSBAChange(value)}
                            />
                        </td>
                        <td>
                            <div className="d-flex flex-row m-0 p-0">
                                <div
                                    className="progress-bar"
                                    fon=""
                                    role="progressbar"
                                    aria-valuenow={keywordBid}
                                    aria-valuemin="0"
                                    aria-valuemax={targetAcos}
                                    style={{ fontSize: "14px", width: (keywordBid / targetAcos) * 100 + "%" }}
                                >
                                    {keywordBid}
                                </div>
                                <div
                                    className="progress-bar"
                                    fon=""
                                    role="progressbar"
                                    aria-valuenow={keywordBid}
                                    aria-valuemin="0"
                                    aria-valuemax={targetAcos}
                                    style={{
                                        fontSize: "14px",
                                        backgroundColor: "green",
                                        width: (parseFloat(keywordBid * tosba) / targetAcos) * 100 + "%",
                                    }}
                                >
                                    +{parseFloat(keywordBid * tosba)}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th style={{ width: "14em" }}>Dynamic Bidding</th>
                        <td style={{ width: "14em" }}>
                            <Select
                                onChange={onDynamicBiddingChange}
                                options={dynamicBiddingOptions}
                                defaultValue={dynamicBiddingOptions[0]}
                            />
                        </td>
                        <td>
                            <div className="d-flex flex-row m-0 p-0">
                                <div
                                    className="progress-bar"
                                    fon=""
                                    role="progressbar"
                                    aria-valuenow={keywordBid + parseFloat(keywordBid * tosba)}
                                    aria-valuemin="0"
                                    aria-valuemax={targetAcos}
                                    style={{
                                        fontSize: "14px",

                                        width:
                                            ((parseFloat(keywordBid) + parseFloat(keywordBid * tosba)) / targetAcos) * 100 +
                                            "%",
                                    }}
                                >
                                    {parseFloat(keywordBid) + parseFloat(keywordBid * tosba)}
                                </div>
                                <div
                                    className="progress-bar"
                                    fon=""
                                    role="progressbar"
                                    aria-valuenow={keywordBid}
                                    aria-valuemin="0"
                                    aria-valuemax={targetAcos}
                                    style={{
                                        fontSize: "14px",
                                        backgroundColor: "green",
                                        width:
                                            (parseFloat(
                                                getDynamicBidPercent() *
                                                    (parseFloat(keywordBid) + parseFloat(keywordBid * tosba))
                                            ) /
                                                targetAcos) *
                                                100 +
                                            "%",
                                    }}
                                >
                                    +
                                    {parseFloat(
                                        getDynamicBidPercent() * (parseFloat(keywordBid) + parseFloat(keywordBid * tosba))
                                    )}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };
    return (
        <div className="card h-100" /*style={{ overflow: "auto" }}*/>
            <div className="card-header white bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <p className="text-uppercase small mb-1" style={{ fontSize: "16px" }}>
                            <strong>{headerTitle}</strong>
                        </p>
                        <p className="mb-0">{headerSubtitle}</p>
                    </div>
                </div>
            </div>

            <div className="card-body w-100 pt-0 pb-0">{renderNumbers()}</div>
        </div>
    );
}

export default AutoAcos;
