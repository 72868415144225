import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Card } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import moment from "moment";

import { customerJourney } from "../../api/ppc";
import { AuthContext } from "../../contexts/AuthContext";
import { BrandContext } from "../../contexts/BrandContext";
import { HeaderContext } from "../../contexts/HeaderContext";
import { ToastContext } from "../../contexts/ToastContext";

import Loading from "../../components/helper/Loading";

const KeywordAnalysis = (props) => {
    //Grabs user data from AuthContext.js
    const { token } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);
    const { setPageTitle, setPageTimeframe, setPageMarketplace, selectedDefaultDates, currency, marketplace } = useContext(HeaderContext);

    const [data, setData] = useState([]);

    const [dataLoading, setDataLoading] = useState(true);

    const percentFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", { style: "percent" });
    const dollarFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
        style: "currency",
        currency: currency,
    });
    const numberFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US");

    /**
     * useEffect with no second arguments = Run on every render
     *
     * Return statement is the cleanup function, runs when the component unmounts (Usually used to cleanup. Here it is used to clean up the page title)
     */
    useEffect(() => {
        setPageTitle("Customer Journey");
        setPageMarketplace('Amazon');
        setPageTimeframe("snapshot");
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    });

    /**
     * useEffect with empty array as second argument = Run only once (on the first render)
     */
    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            try {
                const res = await customerJourney(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tp_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tp_e).format('YYYY-MM-DD'),
                    currency: currency,
                    marketplace: marketplace,
                });

                //Convert strings to numbers, otherwise recharts min max axis parses are not accurate
                res.data.ICUSS.map((val) => {
                    Object.keys(val).forEach((key) => {
                        val[key] = parseFloat(val[key]);
                    });
                    return val;
                });

                res.data.research.map((val) => {
                    Object.keys(val).forEach((key) => {
                        val[key] = parseFloat(val[key]);
                    });
                    return val;
                });
                setData(res.data);
                setDataLoading(false);
            } catch (err) {
                setDataLoading(false);
                toast(err.message);
            }
        };
        getData();
    }, [token, brand, selectedDefaultDates, setDataLoading, toast, currency, marketplace]);

    return dataLoading || typeof data.ICUSS == "undefined" ? (
        <Loading />
    ) : (
        <>
            <Col className="m-3 col-12">
                <h4>The customer journey page tracks the progress of different sponsored product campaigns.</h4>
            </Col>
            <Col className="m-3 col-12">
                <p className="mb-0">
                    <b>Defensive campaigns:</b> Branded keywords, focused on defending owned branded terms and ensuring
                    return customers do not purchase a different brand
                    <br></br>
                    <b>Performance campaigns:</b> Unbranded keywords, focused on capturing new to brand customers
                    <br></br>
                    <b>Research campaigns:</b> Mixed keywords, focused on discovering new converting keywords that later
                    funnel into the defensive or performance campaigns
                </p>
            </Col>

            <Card className="ml-3 mr-3">
                <Card.Header>
                    <h3>Conversion Metrics</h3>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col className="col-4">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="card w-100 mb-3 text-center border-0">
                                    <h3 className="card-header border-0">
                                        Defensive <br></br> Campaigns
                                    </h3>
                                    <div
                                        className="card-body text-center border-0 pt-4 pb-5"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                    >
                                        <span
                                            className="badge badge-pill bg-blue p-2"
                                            style={{
                                                position: "absolute",
                                                zIndex: 3,
                                                bottom: "-22%",
                                                left: "33%",
                                                right: "0%",
                                                width: "33%",
                                                // borderRadius: "1rem",
                                            }}
                                        >
                                            <h4 className="mb-1 text-light">CTR: </h4>
                                            <h3 className="mb-0 text-light">
                                                {percentFormatter.format(
                                                    data.ICUSS[0]["sp_defensive_clicks"] /
                                                        data.ICUSS[0]["sp_defensive_impressions"]
                                                )}
                                            </h3>
                                        </span>
                                        <h4 className="card-title" style={{ fontWeight: "normal" }}>
                                            Impressions:{" "}
                                        </h4>
                                        <h3 className="card-text">
                                            {numberFormatter.format(data.ICUSS[0]["sp_defensive_impressions"])}
                                        </h3>
                                    </div>
                                </div>

                                <div className="card mb-3" style={{ width: "90%" }}>
                                    <div
                                        className="card-body text-center border-0 pt-5 pb-5"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                    >
                                        <span
                                            className="badge badge-pill bg-blue-dark p-2"
                                            style={{
                                                position: "absolute",
                                                zIndex: 3,
                                                bottom: "-22%",
                                                left: "33%",
                                                right: "0%",
                                                width: "33%",
                                                // borderRadius: "1rem",
                                            }}
                                        >
                                            <h4 className="mb-1 text-light">CNV: </h4>
                                            <h3 className="mb-0 text-light">
                                                {percentFormatter.format(
                                                    data.ICUSS[0]["sp_defensive_orders"] /
                                                        data.ICUSS[0]["sp_defensive_clicks"]
                                                )}
                                            </h3>
                                        </span>
                                        <h4 className="card-title" style={{ fontWeight: "normal" }}>
                                            Clicks:{" "}
                                        </h4>
                                        <h3 className="card-text">
                                            {numberFormatter.format(data.ICUSS[0]["sp_defensive_clicks"])}
                                        </h3>
                                    </div>
                                </div>

                                <div className="card mb-3" style={{ width: "80%" }}>
                                    <div
                                        className="card-body text-center border-0 pt-5 pb-5"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                    >
                                        <h4 className="card-title" style={{ fontWeight: "normal" }}>
                                            Orders:{" "}
                                        </h4>
                                        <h3 className="card-text">
                                            {numberFormatter.format(data.ICUSS[0]["sp_defensive_orders"])}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className="col-4">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="card w-100 mb-3 text-center border-0">
                                    <h3 className="card-header border-0">
                                        Performance <br></br> Campaigns
                                    </h3>
                                    <div
                                        className="card-body text-center border-0 pt-4 pb-5"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                    >
                                        <span
                                            className="badge badge-pill bg-blue p-2"
                                            style={{
                                                position: "absolute",
                                                zIndex: 3,
                                                bottom: "-22%",
                                                left: "33%",
                                                right: "0%",
                                                width: "33%",
                                                // borderRadius: "1rem",
                                            }}
                                        >
                                            <h4 className="mb-1 text-light">CTR: </h4>
                                            <h3 className="mb-0 text-light">
                                                {percentFormatter.format(
                                                    data.ICUSS[0]["sp_performance_clicks"] /
                                                        data.ICUSS[0]["sp_performance_impressions"]
                                                )}
                                            </h3>
                                        </span>
                                        <h4 className="card-title" style={{ fontWeight: "normal" }}>
                                            Impressions:{" "}
                                        </h4>
                                        <h3 className="card-text">
                                            {numberFormatter.format(data.ICUSS[0]["sp_performance_impressions"])}
                                        </h3>
                                    </div>
                                </div>

                                <div className="card mb-3" style={{ width: "90%" }}>
                                    <div
                                        className="card-body text-center border-0 pt-5 pb-5"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                    >
                                        <span
                                            className="badge badge-pill bg-blue-dark p-2"
                                            style={{
                                                position: "absolute",
                                                zIndex: 3,
                                                bottom: "-22%",
                                                left: "33%",
                                                right: "0%",
                                                width: "33%",
                                                // borderRadius: "1rem",
                                            }}
                                        >
                                            <h4 className="mb-1 text-light">CNV: </h4>
                                            <h3 className="mb-0 text-light">
                                                {percentFormatter.format(
                                                    data.ICUSS[0]["sp_performance_orders"] /
                                                        data.ICUSS[0]["sp_performance_clicks"]
                                                )}
                                            </h3>
                                        </span>
                                        <h4 className="card-title" style={{ fontWeight: "normal" }}>
                                            Clicks:{" "}
                                        </h4>
                                        <h3 className="card-text">
                                            {numberFormatter.format(data.ICUSS[0]["sp_performance_clicks"])}
                                        </h3>
                                    </div>
                                </div>

                                <div className="card mb-3" style={{ width: "80%" }}>
                                    <div
                                        className="card-body text-center border-0 pt-5 pb-5"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                    >
                                        <h4 className="card-title" style={{ fontWeight: "normal" }}>
                                            Orders:{" "}
                                        </h4>
                                        <h3 className="card-text">
                                            {numberFormatter.format(data.ICUSS[0]["sp_performance_orders"])}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className="col-4 ">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="card w-100 mb-3 text-center border-0">
                                    <h3 className="card-header border-0">
                                        Research <br></br> Campaigns
                                    </h3>
                                    <div
                                        className="card-body text-center border-0 pt-4 pb-5"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                    >
                                        <h4 className="card-title" style={{ fontWeight: "normal" }}>
                                            Clicks:{" "}
                                        </h4>
                                        <h3 className="card-text">
                                            {numberFormatter.format(data.ICUSS[0]["sp_uncategorized_clicks"])}
                                        </h3>
                                    </div>
                                </div>

                                <div className="card mb-3" style={{ width: "90%" }}>
                                    <div
                                        className="card-body text-center border-0 pt-5 pb-5"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                    >
                                        <span
                                            className="badge badge-pill bg-blue-dark p-2"
                                            style={{
                                                position: "absolute",
                                                zIndex: 3,
                                                bottom: "-22%",
                                                left: "33%",
                                                right: "0%",
                                                width: "33%",
                                                // borderRadius: "1rem",
                                                backgroundColor: "#0878FF",
                                            }}
                                        >
                                            <h4 className="mb-1 text-light">CNV: </h4>
                                            <h3 className="mb-0 text-light">
                                                {percentFormatter.format(
                                                    data.ICUSS[0]["sp_uncategorized_orders"] /
                                                        data.ICUSS[0]["sp_uncategorized_clicks"]
                                                )}
                                            </h3>
                                        </span>
                                        <h4 className="card-title" style={{ fontWeight: "normal" }}
                                            title="Metric is across all Amazon marketplace"
                                        >
                                            New Keywords:{" "}
                                        </h4>
                                        <h3 className="card-text">
                                            {numberFormatter.format(data.research[0]["generated_terms"])}
                                        </h3>
                                    </div>
                                </div>

                                <div className="card mb-3" style={{ width: "80%" }}>
                                    <div
                                        className="card-body text-center border-0 pt-5 pb-5"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                    >
                                        <h4 className="card-title" style={{ fontWeight: "normal" }}
                                            title="Metric is across all Amazon marketplace"
                                        >
                                            Converted Keywords:{" "}
                                        </h4>
                                        <h3 className="card-text">
                                            {numberFormatter.format(data.research[0]["converted_terms"])}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Card className="ml-3 mr-3 mt-5">
                <Card.Header>
                    <h3>Spend Metrics</h3>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col className="col-4">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="card w-100 mb-3 text-center border-0">
                                    <h3 className="card-header border-0">
                                        Defensive <br></br> Campaigns
                                    </h3>
                                    <div
                                        className="card-body text-center border-0 pt-4 pb-5"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                    >
                                        <span
                                            className="badge badge-pill bg-blue p-2"
                                            style={{
                                                position: "absolute",
                                                zIndex: 3,
                                                bottom: "-22%",
                                                left: "33%",
                                                right: "0%",
                                                width: "33%",
                                                // borderRadius: "1rem",
                                            }}
                                        >
                                            <h4 className="mb-1 text-light">CPC: </h4>
                                            <h3 className="mb-0 text-light">
                                                {dollarFormatter.format(
                                                    data.ICUSS[0]["sp_defensive_spend"] /
                                                        data.ICUSS[0]["sp_defensive_clicks"]
                                                )}
                                            </h3>
                                        </span>
                                        <h4 className="card-title" style={{ fontWeight: "normal" }}>
                                            Spend:{" "}
                                        </h4>
                                        <h3 className="card-text">
                                            {dollarFormatter.format(data.ICUSS[0]["sp_defensive_spend"])}
                                        </h3>
                                    </div>
                                </div>

                                <div className="card mb-3" style={{ width: "90%" }}>
                                    <div
                                        className="card-body text-center border-0 pt-5 pb-5"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                    >
                                        <h4 className="card-title" style={{ fontWeight: "normal" }}>
                                            RoAS:{" "}
                                        </h4>
                                        <h3 className="card-text">
                                            {(
                                                data.ICUSS[0]["sp_defensive_sales"] / data.ICUSS[0]["sp_defensive_spend"]
                                            ).toFixed(2)}
                                            x
                                        </h3>
                                    </div>
                                </div>

                                <div className="card mb-3" style={{ width: "80%" }}>
                                    <div
                                        className="card-body text-center border-0 pt-5 pb-5"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                    >
                                        <h4 className="card-title" style={{ fontWeight: "normal" }}>
                                            Sales:{" "}
                                        </h4>
                                        <h3 className="card-text">
                                            {dollarFormatter.format(data.ICUSS[0]["sp_defensive_sales"])}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className="col-4">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="card w-100 mb-3 text-center border-0">
                                    <h3 className="card-header border-0">
                                        Performance <br></br> Campaigns
                                    </h3>
                                    <div
                                        className="card-body text-center border-0 pt-4 pb-5"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                    >
                                        <span
                                            className="badge badge-pill bg-blue p-2"
                                            style={{
                                                position: "absolute",
                                                zIndex: 3,
                                                bottom: "-22%",
                                                left: "33%",
                                                right: "0%",
                                                width: "33%",
                                                // borderRadius: "1rem",
                                                // backgroundColor: "#03488D",
                                            }}
                                        >
                                            <h4 className="mb-1 text-light">CPC: </h4>
                                            <h3 className="mb-0 text-light">
                                                {dollarFormatter.format(
                                                    data.ICUSS[0]["sp_performance_spend"] /
                                                        data.ICUSS[0]["sp_performance_clicks"]
                                                )}
                                            </h3>
                                        </span>
                                        <h4 className="card-title" style={{ fontWeight: "normal" }}>
                                            Spend:{" "}
                                        </h4>
                                        <h3 className="card-text">
                                            {dollarFormatter.format(data.ICUSS[0]["sp_performance_spend"])}
                                        </h3>
                                    </div>
                                </div>

                                <div className="card mb-3" style={{ width: "90%" }}>
                                    <div
                                        className="card-body text-center border-0 pt-5 pb-5"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                    >
                                        <h4 className="card-title" style={{ fontWeight: "normal" }}>
                                            RoAS:{" "}
                                        </h4>
                                        <h3 className="card-text">
                                            {(
                                                data.ICUSS[0]["sp_performance_sales"] / data.ICUSS[0]["sp_performance_spend"]
                                            ).toFixed(2)}
                                            x
                                        </h3>
                                    </div>
                                </div>

                                <div className="card mb-3" style={{ width: "80%" }}>
                                    <div
                                        className="card-body text-center border-0 pt-5 pb-5"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                    >
                                        <h4 className="card-title" style={{ fontWeight: "normal" }}>
                                            Sales:
                                        </h4>
                                        <h3 className="card-text">
                                            {dollarFormatter.format(data.ICUSS[0]["sp_performance_sales"])}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className="col-4 ">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="card w-100 mb-3 text-center border-0">
                                    <h3 className="card-header border-0">
                                        Research <br></br> Campaigns
                                    </h3>
                                    <div
                                        className="card-body text-center border-0 pt-3 pb-3"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                    >
                                        <h4 className="card-title" style={{ fontWeight: "normal" }}>
                                            Spend:{" "}
                                        </h4>
                                        <h3 className="card-text">
                                            {dollarFormatter.format(data.ICUSS[0]["sp_uncategorized_spend"])}
                                        </h3>
                                    </div>
                                </div>

                                <div className="card mb-3" style={{ width: "90%" }}>
                                    <div
                                        className="card-body text-center border-0 pt-4 pb-4"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                    >
                                        <h4 className="card-title" style={{ fontWeight: "normal" }}
                                            title="Metric is across all Amazon marketplace"
                                        >
                                            New Keywords:{" "}
                                        </h4>
                                        <h3 className="card-text">
                                            {numberFormatter.format(data.research[0]["generated_terms"])}
                                        </h3>
                                    </div>
                                </div>

                                <div className="card mb-3" style={{ width: "80%" }}>
                                    <div
                                        className="card-body text-center border-0 pt-3 pb-3"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                    >
                                        <h4 className="card-title" style={{ fontWeight: "normal" }}>
                                            Spend per Converting Keyword
                                        </h4>
                                        <h3 className="card-text">
                                            {dollarFormatter.format(
                                                data.ICUSS[0]["sp_uncategorized_spend"] / data.research[0]["converted_terms"]
                                            )}
                                        </h3>
                                    </div>
                                </div>
                                <div className="card mb-3" style={{ width: "70%" }}>
                                    <div
                                        className="card-body text-center border-0 pt-4 pb-4"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                    >
                                        <h4 className="card-title" style={{ fontWeight: "normal" }}
                                            title="Metric is across all Amazon marketplace"
                                        >
                                            Converting Keywords:{" "}
                                        </h4>
                                        <h3 className="card-text">
                                            {numberFormatter.format(data.research[0]["converted_terms"])}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default KeywordAnalysis;
