import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Container } from "react-bootstrap";

import { useAuth0 } from "@auth0/auth0-react";
import { Formik } from "formik";

import { AuthContext } from "../../contexts/AuthContext";
import { ToastContext } from "../../contexts/ToastContext";

import logo from "../../assets/logo.svg";
import "./signin.css";

import CopyrightNotice from "../../components/helper/CopyrightNotice";

const Login = ({ history }) => {
    const { 
        // API
        clearUserSessionCookies, 
        // Auth0
        user, isAuthenticated, isLoading
    } = useContext(AuthContext);
    const { toast } = useContext(ToastContext);
    const { loginWithRedirect } = useAuth0();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        clearUserSessionCookies();
    }, []);

    useEffect(() => {
        if (!isAuthenticated && !isLoading) {
            loginWithRedirect()
        }

    }, [user, isAuthenticated]);

    const renderForm = () => {

        return (
            <div className="d-flex align-items-center flex-column justify-content-center" style={{ height: "100vh", position: 'relative', top: '-60px' }}>
                <div className="text-center w-100">
                    <Formik>
                        {() => {
                            return (
                                <Form className="form-signin" noValidate>
                                    <img src={logo} alt="Catapult" width="520" height="133"></img>
                                    <hr />
                                    <h3 className="mb-3">Please sign in to continue</h3>

                                    <Button
                                        className="btn btn-lg btn-primary btn-block"
                                        variant="primary"
                                        type=""
                                        onClick={() => loginWithRedirect()}
                                    >
                                        Sign In With SSO
                                    </Button>
                                </Form>
                            );
                        }}
                    </Formik>
                    <Link to="forgot">Forgot Password?</Link>
                </div>
                
                <CopyrightNotice/>
            </div>
        );
    };

    return <Container fluid>{renderForm()}</Container>;
};

export default Login;
