import React, { useContext, useEffect, useState } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";

import { AuthContext } from "./../contexts/AuthContext";
import { ErrorBoundary } from "../contexts/ErrorContext";

import NoAuthRoute from "./NoAuthRoute";
import AuthRoute from "./AuthRoute";
import AuthBrandRoute from "./AuthBrandRoute";
import LoggedInRoute from "./LoggedInRoute";
import AuthUserInternalManagerRoute from "./AuthUserInternalManagerRoute";
import NotFound from "./../pages/NotFound";
import Unauthorized from "./../pages/Unauthorized";
import MaintenanceMode from "./../pages/MaintenanceMode";

import Loading from "./../components/helper/Loading";

// Public
import Help from "./../pages/Public/Help";

// Login
import Login from "./../pages/Login/Login";
import Register from "./../pages/Login/Register";
import Forgot from "./../pages/Login/Forgot";
import Reset from "./../pages/Login/Reset";
import Verify from "./../pages/Login/Verify";
import VerifySuccess from "./../pages/Login/VerifySuccess";
import VerifyAlreadySuccess from "./../pages/Login/VerifyAlreadySuccess";

// Logged In
import Welcome from "./../pages/Welcome";
import ClientWizard from "./../pages/User/ClientWizard";
import InviteClient from "./../pages/User/InviteClient";

// Dashboard Brand
import BrandSummary from "./../pages/BrandSummary/BrandSummary";
import BrandTimeline from "./../pages/BrandSummary/BrandTimeline";
import TrafficConversion from "./../pages/TrafficConversion/TrafficConversion";

// Dashboard Performance
import ProductPerformance from "./../pages/performance/productPerformance";
import CategoryPerformance from "./../pages/performance/CategoryPerformance";
import BundlePerformance from "./../pages/performance/BundlePerformance";

//Promotions
import PromotionalReporting from "./../pages/Promotions/PromotionalReporting";
import SNSPerformance from "./../pages/performance/SNSPerformance";

// Dashboard PPC
import Overview from "./../pages/PPC/Overview";
import Timeline from "./../pages/PPC/Timeline";
import ProductsxCampaign from "./../pages/PPC/ProductsxCampaign";
import KeywordAnalysis from "./../pages/PPC/KeywordAnalysis";
import CustomerJourney from "./../pages/PPC/CustomerJourney";
import AutoAcosPage from "./../pages/PPC/AutoAcos";

// Dashboard Reseller
import BuyboxOverview from "./../pages/Resellers/BuyboxOverview";

// Dashboard Customer
import CustomerGeography from "./../pages/customer/customerGeography";

// Dashboard Quantitative
import QuantitativeReport from "./../pages/Quantitative/QuantitativeReport";

import "simplebar/dist/simplebar.min.css";
import "../assets/css/fortress.css";

//Market Intelligence
import MarketShare from "./../pages/MarketIntelligence/MarketShare";
import ReportBuilder from "./../pages/MarketIntelligence/ReportBuilder";

const Routes = () => {
    const location = useLocation();

    //All routes will wait for AuthContext to finish initializing/loading before rendering.
    const { isLoading, loading: isAuthLoading, isLoggedIn } = useContext(AuthContext);

    const renderNoAuthRoutes = () => {
        return (
            <div className="main-display flex-grow-1">
                <Switch>
                    <NoAuthRoute path="/" exact component={Login} />
                    <NoAuthRoute path="/login" exact component={Login} />
                    <NoAuthRoute path="/register" exact component={Register} />
                    <NoAuthRoute path="/forgot" exact component={Forgot} />
                    <NoAuthRoute path="/verify-success" exact component={VerifySuccess} />
                    <NoAuthRoute path="/help" exact component={Help} />

                    <NoAuthRoute path="/401" exact component={Unauthorized} />

                    <NoAuthRoute path="/418" exact component={MaintenanceMode} />

                    <LoggedInRoute component={Unauthorized} />
                </Switch>
            </div>
        );
    }

    const renderRoutes = () => {

        return (
            <ErrorBoundary>
                <Switch>
                    <NoAuthRoute path="/login" exact component={Login} />
                    <NoAuthRoute path="/register" exact component={Register} />
                    {/* <AuthRoute path="/forgot" exact component={Forgot} /> */}
                    {/* <AuthRoute path="/reset" exact component={Reset} /> */}
                    <NoAuthRoute path="/verify-success" exact component={VerifySuccess} />
                    <AuthRoute path="/help" exact component={Help} />

                    <LoggedInRoute path="/verify" exact component={Verify} />

                    <AuthRoute path="/verify-already-success" exact component={VerifyAlreadySuccess} />

                    <AuthRoute path="/brand" exact component={ClientWizard} />

                    <AuthBrandRoute path="/" exact component={Welcome} />
                    
                    <AuthUserInternalManagerRoute path="/invite/client" exact>
                        <AuthBrandRoute path="/invite/client" exact component={InviteClient} />
                    </AuthUserInternalManagerRoute>

                    <AuthBrandRoute path="/brand/summary" exact component={BrandSummary} />
                    <AuthBrandRoute path="/brand/timeline" exact component={BrandTimeline} />
                    <AuthBrandRoute path="/brand/traffic" exact component={TrafficConversion} />

                    <AuthBrandRoute path="/performance/products" exact component={ProductPerformance} />
                    <AuthBrandRoute path="/performance/category" exact component={CategoryPerformance} />
                    <AuthBrandRoute path="/performance/bundles" exact component={BundlePerformance} />

                    <AuthBrandRoute path="/promotions/reports" exact component={PromotionalReporting} />
                    <AuthBrandRoute path="/performance/sns" exact component={SNSPerformance} />

                    <AuthBrandRoute path="/ppc/overview" exact component={Overview} />
                    <AuthBrandRoute path="/ppc/Timeline" exact component={Timeline} />
                    <AuthBrandRoute path="/ppc/products" exact component={ProductsxCampaign} />
                    <AuthBrandRoute path="/ppc/keywords/analysis" exact component={KeywordAnalysis} />
                    <AuthBrandRoute path="/ppc/journey" exact component={CustomerJourney} />
                    <AuthBrandRoute path="/ppc/resellers/buybox" exact component={BuyboxOverview} />
                    <AuthBrandRoute path="/ppc/autoacos" exact component={AutoAcosPage} />
                    <AuthBrandRoute path="/customer/geography" exact component={CustomerGeography} />
                    <AuthBrandRoute path="/brand/quantitative" exact component={QuantitativeReport} />

                    {/*<AuthBrandRoute path="/market-intelligence/share" exact component={MarketShare} />*/}
                    {/*<AuthUserInternalManagerRoute path="/market-share/report-builder" exact>*/}
                    {/*    <AuthBrandRoute path="/market-share/report-builder" exact component={ReportBuilder} />*/}
                    {/*</AuthUserInternalManagerRoute>*/}


                    <AuthRoute path="/404" exact component={NotFound} />

                    <NoAuthRoute path="/418" exact component={MaintenanceMode} />
                    
                    <Route component={NotFound} />
                </Switch>
            </ErrorBoundary>
        );
    };

    return (
        <Container
            fluid
            className="bg-white d-flex flex-row justify-content-between m-0 p-0"
            style={{ height: "100vh", overflow: "hidden" }}
        >
            {(isLoading || isAuthLoading) === true ? 
                <Loading></Loading> 
                : (isLoggedIn === false ? (
                    renderNoAuthRoutes()
                ) : renderRoutes()
            )}
        </Container>
    );
};

export default Routes;
