import React, { useContext, useState, useEffect } from "react";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";

import { ToastContext } from "../../../contexts/ToastContext";


import WidgetSpinner from "../../../components/helper/WidgetSpinner";
import useIsMountedRef from "../../../components/helper/isMountedRef";
import {HeaderContext} from "../../../contexts/HeaderContext";


const chartFont = {
    colors: "gray",
    fontSize: "12px",
    cssClass: "apexcharts-xaxis-label",
};

function SalesChannelChart(props) {

    const [chartOptions, setChartOptions] = useState({});
    const [series, setSeries] = useState([]);

    const isMountedRef = useIsMountedRef();

    const {
        currency,
        chartData,
        stacked,
        setMounted,
    } = props;

    // const cleanCurrencyFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
    const cleanCurrencyFormatter = new Intl.NumberFormat( "en-US", {
        style: "currency",
        currency: currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
    });

    const getCleanCurrencyFormatter = (value) => {
        return cleanCurrencyFormatter.format(value);
    };

    useEffect(() => {
        if (isMountedRef.current) {
            setMounted(true);
        }
    }, [isMountedRef])

    useEffect(() => {

        if (chartData !== undefined) {
            let labels = chartData.labels;

            let new_series = [];
            let new_chartOptions = {};

            for(let i=0; chartData.data.length>i;i++){
                new_series.push({
                    name: chartData.data[i].label,
                    group: chartData.data[i].group,
                    data: chartData.data[i].data,
                    type: "bar",
                })

            }

            let tooltip = {
                shared: true,
                followCursor: true,
                custom: ({series, seriesIndex, dataPointIndex, w}) => {
                    let percentage = "0"
                    let percentageColor = "green";
                    let percentageSpan = {
                        series1: '',
                        series2: '',
                        series3: '',
                    };
                    let previousYear = parseInt(labels[dataPointIndex].slice(-4),10) -1;
                    previousYear = labels[dataPointIndex].slice(0, -4) + previousYear;


                    if(labels.includes(previousYear)){
                        let previousIndex =0;
                        for(let i=0; labels.length>i;i++){
                            if(labels[i] === previousYear){
                                previousIndex = i;
                            }
                        }
                        //calculate percentage
                        percentage = 0;
                        if(series[0][dataPointIndex] != 0){
                            percentage = ((series[0][dataPointIndex]-series[0][previousIndex])/series[0][previousIndex])*100;
                        }
                        percentageColor = percentage >0 ? "green" : "red";
                        percentageSpan.series1 = '<span style="font-weight: 700; font-size: 10px; color: '+percentageColor+';"> ('+percentage.toFixed(0)+'% YoY)</span>';

                        percentage = 0;
                        if(series[1][dataPointIndex] !== 0){
                            percentage = ((series[1][dataPointIndex]-series[1][previousIndex])/series[1][previousIndex])*100;
                        }
                        percentageColor = percentage >0 ? "green" : "red";
                        percentageSpan.series2 = '<span style="font-weight: 700; font-size: 10px; color: '+percentageColor+';"> ('+percentage.toFixed(0)+'% YoY)</span>';

                        percentage = 0;
                        if(series[0][dataPointIndex]+series[1][dataPointIndex] != 0){
                            percentage = (((series[0][dataPointIndex]+series[1][dataPointIndex])-(series[0][previousIndex]+series[1][previousIndex]))/(series[0][previousIndex]+series[1][previousIndex])) * 100;
                        }
                        percentageColor = percentage >0 ? "green" : "red";
                        percentageSpan.series3 = '<span style="font-weight: 700; font-size: 10px; color: '+percentageColor+';"> ('+percentage.toFixed(0)+'% YoY)</span>';
                    }


                    return(
                        '<div style="width: 230px; height: 150px;">'+
                        '<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">'+labels[dataPointIndex]+'</div>'+

                        '<div style="padding: 10px; font-family: Helvetica, Arial, sans-serif;">' +
                        '<div>'+
                        '<span style="display: inline-block; background-color: '+w.config.colors[0]+'; border-radius: 20px; height: 10px; width: 10px; margin-right: 5px;"></span>' +
                        '<span class="apexcharts-tooltip-text-y-label" style="font-size: 12px;">'+new_series[0].name+': </span>' +
                        '<span style="font-weight: 700; font-size: 12px;">'+ getCleanCurrencyFormatter(series[0][dataPointIndex]) +'</span>' +
                        percentageSpan.series1 +
                        '</div>'+

                        '<div style="padding: 10px; font-family: Helvetica, Arial, sans-serif;">' +
                        '<div>'+
                        '<span style="display: inline-block; background-color: '+w.config.colors[1]+'; border-radius: 20px; height: 10px; width: 10px; margin-right: 5px;"></span>' +
                        '<span class="apexcharts-tooltip-text-y-label" style="font-size: 12px;">'+new_series[1].name+': </span>' +
                        '<span style="font-weight: 700; font-size: 12px;">'+ getCleanCurrencyFormatter(series[1][dataPointIndex]) +' </span>' +
                        percentageSpan.series2 +
                        '</div>'+

                        '<div style="padding: 10px; font-family: Helvetica, Arial, sans-serif;">' +
                        '<div>'+
                        '<span class="apexcharts-tooltip-text-y-label" style="font-size: 12px;">Total: </span>' +
                        '<span style="font-weight: 700; font-size: 12px;">'+ getCleanCurrencyFormatter(series[1][dataPointIndex] + series[0][dataPointIndex]) +'</span>' +
                        percentageSpan.series3 +
                        '</div>'+

                        '</div>'+
                        '</div>'
                    );
                },
            }

    
            new_chartOptions = {
                chart: {
                    id: "barChart",
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    dropShadow: {
                        enabled: false,
                    },
                    animations: {
                        enabled: false,
                    },
                    height: "100%",
                    type: "bar",
                    stacked: stacked,
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                legend:{show: false},
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                grid: {
                    show: true,
                    borderColor: "#eaecec",
                    xaxis: { lines: { show: true } },
                    yaxis: { lines: { show: true } },
                },
                markers: {
                    strokeWidth: [5, 5, 5, 5],
                    strokeOpacity: 1,
                    fillOpacity: 1,
                    hover: { sizeOffset: 1 },
                },
                colors: ['#84344e','#E3FF00','#313132','#9d555e','#8a8a8b','#b4cb00','#dbff51','#c99b98','#b5b5b5','#a7bd00','#84344e'],
                tooltip: tooltip,
                xaxis: {
                    categories: labels,
                    type: "category",
                    labels: {
                        style: chartFont,
                    },
                    tickPlacement: "between",
                },
                yaxis: {
                    title: {
                        text: "",
                    },
                    min: 0,
                    labels: {
                        formatter: (val) => getCleanCurrencyFormatter(val),
                        style: chartFont,
                    },
                },
            };

            setTimeout(()=>{
                setSeries(new_series);
                setChartOptions(new_chartOptions);
            },100);

        }

    }, [props.chartData])

    return (
        <ResponsiveContainer>
            <Chart options={chartOptions} series={series} ref={isMountedRef}/>
        </ResponsiveContainer>
    );
}

function SalesChannel(props) {
    const { currency } = useContext(HeaderContext);


    // const [timelineData, setTimelineData] = useState(props.widgetData ?? null);
    const timelineData = props.widgetData ?? null;
    const [widgetLoading, setWidgetLoading] = useState((props.widgetData !== null && props.widgetData !== undefined) ? false : (props.widgetDataLoading ?? true));
    const [chartMounted, setChartMounted] = useState(false);


    const {
        headerTitle = "",
        headerSubtitle = "",
    } = props;

    return (
        <div className="card h-100">
            <div className="card-header bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <h5 className="mb-0">{headerTitle}</h5>
                        {headerSubtitle !== "" ? <p className="body-1 text-secondary mb-0 mt-2">{headerSubtitle}</p> : ""}
                    </div>

                </div>
            </div>

            <div className="card-body w-100">{
                    widgetLoading ? 
                    <WidgetSpinner />
                    :
                    timelineData && (
                        <SalesChannelChart
                            currency={currency}
                            chartData={timelineData}
                            stacked={props.stacked ?? null}
                            setMounted={(value) => setChartMounted(value)}
                        />
                    )
                }
            </div>
        </div>
    );
}

export default SalesChannel;
