import React, { useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { Form, Button, Container, Spinner } from "react-bootstrap";
import { AuthContext } from "../../contexts/AuthContext";

import logo from "../../assets/logo.svg";

import CopyrightNotice from "../../components/helper/CopyrightNotice";

import "./signin.css";

const VerifySuccess = ({ history }) => {
    const { loginWithRedirect } = useAuth0();

    const { 
        isLoading, loading: isAuthLoading
        , isLoggedIn
        , isAPIAuthenticated, apiUser
        , user: auth0User 
    } = useContext(AuthContext);

    useEffect(() => {
        if (!isLoading && !isAuthLoading) {
            if (isLoggedIn && isAPIAuthenticated && auth0User) {
                history.replace("/");
            }
        }
    }, [isLoading, isAuthLoading, isLoggedIn, isAPIAuthenticated, auth0User])

    const onSuccess = async () => {
        if (!isLoading && !isAuthLoading) {
            if (isLoggedIn && isAPIAuthenticated) {
                if (!auth0User) {
                    // console.log('redirect signup', apiUser?.email);
                    loginWithRedirect({authorizationParams: {
                        login_hint: apiUser?.email ?? '',
                        screen_hint: "signup",
                    }})
                }
                else {
                    // console.log('redirect login');
                    history.replace("/");
                }
            }
            else if (isLoggedIn && !isAPIAuthenticated) {
                // console.log('redirect verify');
                history.replace("/verify");
            }
            else {
                // console.log('redirect default');
                loginWithRedirect({authorizationParams: {
                    screen_hint: "signup",
                }})
            }
        }
    };

    const renderForm = () => {
        const onSubmit = () => {
            onSuccess();
        };

        return (
            <div className="d-flex align-items-center flex-column justify-content-center" style={{ height: "100vh" }}>
                <div className="text-center w-100">
                    <Form className="form-signin" noValidate onSubmit={onSubmit}>
                        <img src={logo} alt="Fortress" width="300" height="130"></img>
                        <hr />
                        <h3 className="mb-3">{`Hello, ${apiUser?.name ?? ""}`}</h3>
                        <h2 className="mb-3">Thank you for verifiying up your email!</h2>
                        <div className="text-left">
                            <p className="body-1-bold mb-3">On the next screen you will be promoted to setup a login.</p>
                            <p className="mb-3">Please enter the same email you verified and set a password</p>
                            <p className="mb-3">You will also receive another email verification from @auth0user.net</p>
                            <p className="mb-3">All further login communication will also come from @auth0user.net <b>Please check your spam too</b></p>
                        </div>

                        <Button
                            className="btn btn-lg btn-primary btn-block"
                            variant="primary"
                            disabled={false}
                            type="submit"
                        >
                            {false ? (
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                    // animation="border"
                                ></Spinner>
                            ) : (
                                "Continue to Login Setup"
                            )}
                        </Button>
                    </Form>
                </div>

                <CopyrightNotice/>
            </div>
        );
    };

    return <Container fluid>{renderForm()}</Container>;
};

export default VerifySuccess;
