import React, { useContext, useState } from "react";
import { Form, Button, Container, Spinner } from "react-bootstrap";
import * as Yup from "yup";

import { resendVerify } from "../../api/user";

import { AuthContext } from "../../contexts/AuthContext";
import { ToastContext } from "../../contexts/ToastContext";

import logo from "../../assets/logo.svg";
import "./signin.css";

import CopyrightNotice from "../../components/helper/CopyrightNotice";

const validationSchema = Yup.object({
    email: Yup.string().required("Email is required").email("Must be a valid email address"),
});

const Verify = ({ history }) => {
    const { setLogOut } = useContext(AuthContext);
    const { toast } = useContext(ToastContext);
    const { token, apiUser } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const renderForm = () => {
        const onSuccess = async () => {
            setLoading(true);
            try {
                const res = await resendVerify(token);
                toast(res.data.message);
                setLoading(false);
            } catch (err) {
                //console.log(err);
                toast("Request Error!");
                setLoading(false);
            }
        };

        return (
            <div className="d-flex align-items-center flex-column justify-content-center" style={{ height: "100vh" }}>
                <div className="text-center w-100">
                    <Form className="form-signin" noValidate>
                        <img src={logo} alt="Fortress" width="300" height="130"></img>
                        <hr />
                        <h3 className="mb-3">{`Hello, ${apiUser?.name ?? ""}`} </h3>
                        <h3 className="mb-3">Please verify your email</h3>
                        <p>To continue into the portal, please verify your email address: </p>
                        <Button
                            className="btn btn-lg btn-primary btn-block"
                            variant="primary"
                            disabled={loading}
                            onClick={onSuccess}
                        >
                            {loading ? (
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                    // animation="border"
                                ></Spinner>
                            ) : (
                                "Resend Verification Email"
                            )}
                        </Button>
                    </Form>

                    <Button className="btn btn-link" variant="link" onClick={setLogOut}>
                        Logout
                    </Button>
                </div>

                <CopyrightNotice/>
            </div>
        );
    };

    return <Container fluid>{renderForm()}</Container>;
};

export default Verify;
