import React, { useEffect, useState, useContext } from "react";
import ReactTooltip from "react-tooltip";
import { Row, Col, Card, Form, InputGroup, Button } from "react-bootstrap";
import moment from "moment";

import { AuthContext } from "../../../contexts/AuthContext";
import { BrandContext } from "../../../contexts/BrandContext";
import { HeaderContext } from "../../../contexts/HeaderContext";
import { ToastContext } from "../../../contexts/ToastContext";

import VpartFilter from "../../helper/VpartFilter.js";

import MapChart from "../MapChart";
import BootstrapTablesWrapper from "../BootstrapTablesWrapper";

import { customersHeatmapZip, customersHeatmapState } from "../../../api/clvData";

const defaultColumns = [
    {dataField: "country", text: "country", sort: true, hidden: true },
    {dataField: "AFFGEOID10", text: "AFFGEOID10", sort: true, hidden: true },
    {dataField: "key", text: "ID", sort: true, filter: 'textFilter' },
    {dataField: "geographic_area_name", text: "Geographic Area Name", sort: true, filter: 'textFilter' },
    {dataField: "first_purchased", text: "First Purchased", sort: true, hidden: true },
    {dataField: "last_purchase", text: "Last Purchased", sort: true, hidden: true },
    {dataField: "orders", text: "Orders", sort: true },
    {dataField: "sales", text: "Sales", sort: true, formatter: 'dollarFormatter' },
    {dataField: "units", text: "Units", sort: true },
    {dataField: "unique_skus_sold", text: "Unique SKUs Sold", sort: true, hidden: true },
    {dataField: "total_population", text: "Total Population", sort: true },
];

const vpartColumns = [
    {dataField: "country", text: "country", sort: true, hidden: true },
    {dataField: "key", text: "", hidden: true },
    {dataField: "AFFGEOID10", text: "AFFGEOID10", sort: true, hidden: true },
    {dataField: "State_code", text: "State", sort: true, filter: 'textFilter' },
    {dataField: "geographic_area_name", text: "Geographic Area Name", sort: true, filter: 'textFilter' },
    {dataField: "vpart", text: "Vpart", sort: true, filter: 'textFilter' },
    {dataField: "first_purchased", text: "First Purchased", sort: true, hidden: true },
    {dataField: "last_purchase", text: "Last Purchased", sort: true, hidden: true },
    {dataField: "orders", text: "Orders", sort: true },
    {dataField: "sales", text: "Sales", sort: true, formatter: 'dollarFormatter' },
    {dataField: "units", text: "Units", sort: true },
    {dataField: "unique_skus_sold", text: "Unique SKUs Sold", sort: true, hidden: true },
    {dataField: "total_population", text: "Total Population", sort: true },
];

const CLVUSHeatmap = (props) => {

    const { token } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);
    const { selectedDefaultDates } = useContext(HeaderContext);

    const [loading, setLoading] = useState(true);
    const [noData, setNoData] = useState(true);

    const [geoMapData, setGeoMapData] = useState(undefined);
    const [geotableData, setGeoTableData] = useState(undefined);
    
    const [topographicFile, setTopographicFile] = useState();
    const [content, setContent] = useState("");
    
    const [heatmapType, setHeatmapType] = useState('state');
    const [selectedMapType, setSelectedMapType] = useState(heatmapType);
    const [selectedVparts, setSelectedVparts] = useState([]);
    const [isSelectedVparts, setIsSelectedVparts] = useState(false);

    const getHeatmapData = async () => {
        setLoading(true);
        setNoData(true);
        
        setGeoMapData([]);
        setGeoTableData([]);
        
        switch(heatmapType) {
            default:
                case 'state': 
                setTopographicFile("/cb_2019_us_state_500k.json");
                break;
                
            case 'zip':
                case 'zipLow': 
                setTopographicFile("/cb_2019_us_zcta510_500k_min.json");
                break;
                
                case 'zipMid': 
                setTopographicFile("/cb_2019_us_zcta510_500k_mid.json");
                break;
                
                case 'zipHigh': 
                setTopographicFile("/cb_2019_us_zcta510_500k.json");
                break;
        }

        var heatmapDataType = 'state';
        if (heatmapType.slice(0, 3) === 'zip') {
            heatmapDataType = 'zip';
        }
        
        try {
            const searchVparts = selectedVparts?.map( (v) => { return v.vpart } );
            const isSelectedVparts = (searchVparts === undefined || searchVparts.length === 0 ? false : true);
            setIsSelectedVparts(isSelectedVparts);

            if (heatmapDataType === 'state') {
                const stateRes = await customersHeatmapState(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tp_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tp_e).format('YYYY-MM-DD'),
                    wow: selectedDefaultDates.timeframe,
                    vparts: searchVparts,
                });

                // console.log("customersHeatmap State", stateRes);

                if (stateRes?.data !== undefined) {
                    if (isSelectedVparts) {
                        setGeoMapData(stateRes?.data?.map_data);
                        setGeoTableData(stateRes?.data?.table_data);
                    }
                    else {
                        setGeoMapData(stateRes?.data);
                        setGeoTableData(stateRes?.data);
                    }
                    setLoading(false);
                    setNoData(false);
                }
                
                return true;
            }
            else if (heatmapDataType === 'zip') {
                const zipRes = await customersHeatmapZip(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tp_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tp_e).format('YYYY-MM-DD'),
                    wow: selectedDefaultDates.timeframe,
                    vparts: searchVparts,
                });

                // console.log("customersHeatmap Zip", zipRes);

                if (zipRes?.data !== undefined) {
                    if (isSelectedVparts) {
                        setGeoMapData(zipRes?.data?.map_data);
                        setGeoTableData(zipRes?.data?.table_data);
                    }
                    else {
                        setGeoMapData(zipRes?.data);
                        setGeoTableData(zipRes?.data);
                    }
                    setLoading(false);
                    setNoData(false);
                }

                return true;
            }

        } catch (err) {
            toast(err.message);
            return false;
        }
    }

    useEffect(() => {
        if (brand.brandId !== undefined 
            && selectedDefaultDates.timeframe !== undefined
            && selectedDefaultDates.tf_s !== undefined
            && selectedDefaultDates.tf_e !== undefined
        ) {
            getHeatmapData();
        }
    }, [token, brand, selectedDefaultDates]);

    const submitGeoOptions = () => {
        setSelectedMapType(heatmapType)
        getHeatmapData();
    }

    return (
        <Card>
            <Card.Header className="bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <h5>{props.headerTitle}</h5>
                        <Col className="body-1 text-secondary">
                            <Row className="justify-content-start">
                                {props.headerSubtitle !== '' ? <p className="mb-0">{props.headerSubtitle}</p> : ''}
                                
                                <Col className="ml-3">
                                    <Row className="align-items-center">
                                        <span className="mr-2">Low</span>
                                        <span className="bg-blue-100" style={{height: "14px", width: "14px"}}></span>
                                        <span className="bg-blue-200" style={{height: "14px", width: "14px"}}></span>
                                        <span className="bg-blue-300" style={{height: "14px", width: "14px"}}></span>
                                        <span className="bg-blue-400" style={{height: "14px", width: "14px"}}></span>
                                        <span className="bg-blue-500" style={{height: "14px", width: "14px"}}></span>
                                        <span className="bg-blue-600" style={{height: "14px", width: "14px"}}></span>
                                        <span className="bg-blue-700" style={{height: "14px", width: "14px"}}></span>
                                        <span className="bg-blue-800" style={{height: "14px", width: "14px"}}></span>
                                        <span className="ml-2">High</span>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                    </div>
                    <div className="ml-auto ">
                        {props.showConfigButton && (
                            <button
                                type="button"
                                className="btn btn-primary btn-sm "
                                onClick={() => {
                                    //TODO: Add Configuration Modal
                                }}
                            >
                                Config
                            </button>
                        )}
                    </div>
                </div>
            </Card.Header>
            <Card.Body>

                <Col>
                    <Row className="mt-1">
                        <VpartFilter
                            onChange={(value) => setSelectedVparts(value)}
                        >
                        </VpartFilter>
                    </Row>
                    <Row className="mt-3">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Type</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control as="select"
                                value={heatmapType}
                                onChange={(e) => setHeatmapType(e.target.value)}
                            >
                                <option value="state">US States</option>
                                <option value="zip">US ZipCodes</option>
                            </Form.Control>
                        </InputGroup>
                    </Row>
                    <Row className="mt-3 justify-content-end">
                        <Button 
                            className="rounded-0 d-flex"
                            onClick={() => submitGeoOptions()}
                        > Apply
                        </Button>
                    </Row>
                </Col>

                <Row style={{ 'minHeight': props.minHeight ?? '500px' }}>
                    <Col>
                        <MapChart 
                            data={(geoMapData)}
                            widgetLoading={(loading || noData)}
                            setTooltipContent={setContent}
                            geo={topographicFile}
                            type={selectedMapType}
                        />
                        <ReactTooltip html={true}>{content}</ReactTooltip>
                    </Col>
                </Row>

                <BootstrapTablesWrapper
                    data={(geotableData)}
                    keyField="key"
                    tableOnly={true}
                    hasPagination={true}
                    hasFilter={true}
                    columnModal={true}
                    columns={(isSelectedVparts === false ? defaultColumns : vpartColumns )}
                />
            </Card.Body>
        </Card>
    );
}

export default CLVUSHeatmap;