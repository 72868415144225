import React, { useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";

import { HeaderContext } from "../../contexts/HeaderContext";

import TimelineTable from "../BrandReport/widgets/TimelineTable";
import CampaignTimeline from "../BrandReport/widgets/CampaignTimeline";

import "./chartStyle.css";

const Overview = (props) => {
    //Grabs user data from AuthContext.js

    const { setPageTitle, setPageTimeframe, setPageMarketplace } = useContext(HeaderContext);

    /**
     * useEffect with no second arguments = Run on every render
     *
     * Return statement is the cleanup function, runs when the component unmounts (Usually used to cleanup. Here it is used to clean up the page title)
     */
    useEffect(() => {
        setPageTitle("PPC - Timeline");
        setPageMarketplace('Amazon');
        setPageTimeframe("timeframe");
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    });

    return (
        <>
            <main>
                <div className="container-fluid">
                    <Row className="mt-4">
                        <Col sm={12} style={{ height: "34em" }}>
                            <CampaignTimeline showConfigButton={false} widgetTitle="Total Campaign Performance Over Time" />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm={12} style={{ height: "34em" }}>
                            <CampaignTimeline
                                showConfigButton={false}
                                widgetTitle="Sponsored Products Performance"
                                metricPrefix="SP Performance"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm={12} style={{ height: "34em" }}>
                            <CampaignTimeline showConfigButton={false} widgetTitle="Sponsored Products Defensive" metricPrefix="SP Defensive" />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm={12} style={{ height: "34em" }}>
                            <CampaignTimeline showConfigButton={false} widgetTitle="Sponsored Products Research" metricPrefix="SP Research" />
                        </Col>
                    </Row>
                    <div className="mt-4">
                        <TimelineTable showConfigButton={false} widgetTitle="PPC Overview" />
                    </div>
                    <Row className="mt-4">
                        <Col sm={12}></Col>
                    </Row>
                </div>
            </main>
        </>
    );
};

export default Overview;
