import React, { useContext, useState, useEffect } from "react";
import Select from "react-select";

import { BrandContext } from "../../contexts/BrandContext";
import WidgetSpinner from "../../components/helper/WidgetSpinner";

// TODO: Filter Accounts + Marketplaces
const BrandSelect = (props) => {
    const { authorizedBrands } = useContext(BrandContext);

    // Init State from props
    const [selectedBrand, setSelectedBrand] = useState();
    const [selectBrandOptions, setSelectBrandOptions] = useState(authorizedBrands);

    // Init Options
    const [buttonLoading, setButtonLoading] = useState(true);
    const [activeBrandsOnly, setActiveBrandsOnly] = useState(false);
    const [authorizedBrandsLoading, setAuthorizedBrandsLoading] = useState(true);
    const [hasValidImage, setHasValidImage] = useState(true);

    useEffect(() => {
        // console.log('BrandSelect', props, selectedBrand, activeBrandsOnly, authorizedBrands);

        if (props.brand?.brandId) {
            setSelectedBrand(props.brand);
        }

        if (authorizedBrands !== undefined && authorizedBrands.length > 0) {
            setSelectBrandOptions(authorizedBrands);

            if (authorizedBrands.length === 1) {
                setSelectedBrand(authorizedBrands[0]);
                props.onChange(authorizedBrands[0]);
            }

            // setSelectBrandOptions(
            //     authorizedBrands.filter((brand) => {
            //         let display = false;

            //         if (activeBrandsOnly && brand.tier <= 9)
            //             display = true;
            //         else if (activeBrandsOnly && brand.tier > 9)
            //             display = false;
            //         else if (!activeBrandsOnly)
            //             display = true;

            //         // if (selectedMarketplaceOnly[brand.marketplaceId] === true)
            //         //     display = true;
            //         // else if (selectedMarketplaceOnly[brand.marketplaceId] === undefined || selectedMarketplaceOnly[brand.marketplaceId] === false)
            //         //     display = false;

            //         return display;
            //     })
            // );

            setButtonLoading(false);
            setAuthorizedBrandsLoading(false);
        }
    }, [props, activeBrandsOnly, authorizedBrands, setAuthorizedBrandsLoading]);

    const onBrandChange = (newBrand) => {
        props.onChange(newBrand);
    };
    if (authorizedBrandsLoading) {
        return <WidgetSpinner></WidgetSpinner>;
    }
    if (props.wizard === true && selectBrandOptions.length === 1) {
        if (hasValidImage) {
            return (
                <div className="text-left text-dark mt-4">
                    <div className="d-flex align-items-center">
                        <img
                            src={`https://portal.fortressbrand.com/assets/images/brand_logo/${selectBrandOptions[0]?.brandId}.png`}
                            id="brand-logo"
                            alt="Brand Logo"
                            style={{ height: "250px" }}
                            onError={() => {
                                setHasValidImage(false);
                            }}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <div className=" text-dark mt-4">
                    <h2 className="text-center">{selectBrandOptions[0].brandName}</h2>
                </div>
            );
        }
    } else if (props.dropdownOnly === true) {
        return (
            <Select
                className="text-dark w-100 select-border-0"
                placeholder="Select a Brand"
                value={selectedBrand}
                options={selectBrandOptions}
                name="brandId"
                getOptionLabel={(option) => option.brandName}
                getOptionValue={(option) => option.brandId}
                onChange={(brand) => onBrandChange(brand)}
                isMulti={false}
                isSearchable={true}
                isLoading={buttonLoading}
                noOptionsMessage={(inputValue) => "No Client Brands"}
            />
        );
    } else {
        return (
            <div className="text-left text-dark mt-4">
                <span className="body-1-bold text-dark">Client Brands</span>

                <Select
                    className="text-dark w-100 select-border-0"
                    placeholder="Select a Brand"
                    value={selectedBrand}
                    options={selectBrandOptions}
                    name="brandId"
                    getOptionLabel={(option) => option.brandName}
                    getOptionValue={(option) => option.brandId}
                    onChange={(brand) => onBrandChange(brand)}
                    isMulti={false}
                    isSearchable={true}
                    isLoading={buttonLoading}
                    noOptionsMessage={(inputValue) => "No Client Brands"}
                />
            </div>
        );
    }
};

export default BrandSelect;
