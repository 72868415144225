import React, { useContext, useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";

import { Formik } from "formik";
import * as Yup from "yup";
import Select from 'react-select';

import { requestHelp } from "../../api/user";

import { AuthContext } from "../../contexts/AuthContext";
import { ToastContext } from "../../contexts/ToastContext";

const HelpForm = (props) => {
    const { apiUser, token } = useContext(AuthContext);
    const { toast } = useContext(ToastContext);

    const [loading, setLoading] = useState(false);
    
    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required"),
        email: Yup.string().required("Email is required").email("Must be a valid email address"),
        topic: Yup.string().required("Support Topic is required"),
        message: Yup.string().required("Message is required"),
    });

    const onSuccess = async (values) => {
        setLoading(true);

        const data = new FormData();
        data.append('name', values?.name ?? '');
        data.append('email', values?.email ?? '');
        data.append('topic', values?.topic ?? '');
        data.append('message', values?.message ?? '');
        
        if (values?.files !== undefined) {
            for (let i = 0; i < values.files?.length; i++) {
                data.append('files[]', values.files[i]);
            }
        }

        try {
            const res = await requestHelp(token ?? '', data);
            if (res !== undefined && res.data !== undefined) {
                toast(res.data.message);
            } else {
                toast("Unable to send email, But your response has been logged");
            }
            setLoading(false);
        } catch (err) {
            toast("Unable to send email, But your response has been logged");
            setLoading(false);
        }
    };

    const required = () => {
        return <b><i  className="text-danger">*</i></b>;
    }

    return (
        <div>
            <Formik
                onSubmit={onSuccess}
                initialValues={{
                    name: apiUser?.name ?? "",
                    email: apiUser?.email ?? "",
                    topic: "",
                    message: "",
                }}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => {
                    return (
                        <Form className="form-signin" noValidate onSubmit={handleSubmit}>
                            <h3 className="mb-3">Email Support Desk</h3>

                            <div className="text-left pb-4">
                                <Form.Group controlId="formBasicText">
                                    <Form.Label>Name{required()}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder="Please enter your full name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Email address{required()}</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        placeholder="Please enter your email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="formBasicSelect">
                                    <Form.Label>Support Topic{required()}</Form.Label>
                                    <Form.Control 
                                        as="input"
                                        name="topic"
                                        placeholder="Select Support Topic"
                                        value={values.topic}
                                        onChange={handleChange}
                                        isInvalid={errors.topic}
                                        className="d-none"
                                    >
                                    </Form.Control>
                                    <Select
                                        defaultValue={values.topic}
                                        isMulti={true}
                                        onChange={(options) => {
                                            const topic = [];
                                            options.forEach((option) => {
                                                topic.push(option.value);
                                            })
                                            setFieldValue('topic', topic.join(', '));
                                        }}
                                        options={[
                                            {
                                                label: 'Login Topics',
                                                options: [
                                                    { value: 'unable_to_login', label: "Unable to Login" },
                                                    { value: 'forgot_password_reset_password', label: "Forgot Password / Reset Password" },
                                                    { value: 'account_registration_problems', label: "Account Registration Problems" },
                                                ],
                                            },
                                            {
                                                label: 'Platform Topics',
                                                options: [
                                                    { value: "request_for_feature_improvement", label: "Request for Feature Improvement" },
                                                    { value: 'incorrect_data_displayed', label: "Incorrect Data Displayed" },
                                                    { value: 'platform_performance_issues', label: "Platform Performance Issues (slow, freezing, etc.)" },
                                                    { value: 'report_a_bug_or_system_error', label: "Report a Bug or System Error" },
                                                    { value: 'difficulties_with_navigation', label: "Difficulties with Navigation" },
                                                    { value: 'user_interface_display_issues', label: "User Interface/Display Issues" },
                                                    { value: 'problems_with_uploading_or_downloading_content', label: "Problems with Uploading or Downloading Content" },
                                                    { value: 'connectivity_issues', label: "Connectivity Issues" },
                                                    { value: 'difficulty_accessing_certain_content_or_features', label: "Difficulty Accessing Certain Content or Features" },
                                                    { value: 'training_or_usage_guidance_required', label: "Training or Usage Guidance Required" },
                                                    { value: 'compatibility_issues', label: "Compatibility Issues (browser, device, etc.)" },
                                                ],
                                            },
                                            {
                                                label: 'General Topics',
                                                options: [
                                                    { value: 'error_messages', label: "Error Messages" },
                                                    { value: 'other', label: "Other" }
                                                ],
                                            },
                                        ]}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.topic}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="formBasicTextArea">
                                    <Form.Label>Description{required()}</Form.Label>
                                    <Form.Control
                                        as="textarea" rows={3}
                                        name="message"
                                        value={values.message}
                                        onChange={handleChange}
                                        isInvalid={errors.message}
                                        placeholder="Description"
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="formFile">
                                    <Form.Label>Upload Screenshot</Form.Label>
                                    <Form.Control 
                                        as="input"
                                        type="file" multiple
                                        name="files" 
                                        onChange={(e) => {
                                            setFieldValue('files', e.target.files)
                                        }}
                                    />
                                </Form.Group>
                            </div>

                            <Button
                                className="btn btn-lg btn-primary btn-block"
                                variant="primary"
                                disabled={loading}
                                type="submit"
                            >
                                {loading ? (
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        role="status"
                                        aria-hidden="true"
                                        // animation="border"
                                    ></Spinner>
                                ) : (
                                    "Submit"
                                )}
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default HelpForm;