import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Card, OverlayTrigger, Tooltip } from "react-bootstrap";

import WidgetSpinner from "../../../components/helper/WidgetSpinner";
// import { AuthContext } from "../../../contexts/AuthContext";
// import { BrandContext } from "../../../contexts/BrandContext";
// import { HeaderContext } from "../../../contexts/HeaderContext";
// import { ToastContext } from "../../../contexts/ToastContext";

const TextCard = (props) => {
    const [widgetData, setWidgetData] = useState();
    const [widgetLoading, setWidgetLoading] = useState(true);

    // default vertical. horizontal only if defined
    // const [direction, setDirection] = useState(props?.direction !== 'horizontal' ? 'vertical' : 'horizontal')

    const {
        // data,
        // widgetDataLoading,
        cardFooter,
        minHeight,
        minWidthPct,
        headerTitle,
        direction = props.direction !== 'horizontal' ? 'vertical' : 'horizontal',
        variant = props.variant ?? 'default',
    } = props;

    useEffect(() => {
        if (props.data !== undefined && props.widgetDataLoading === false) {
            setWidgetData(props.data);
            setWidgetLoading(false);
        }
        else {
            setWidgetData();
            setWidgetLoading(true);
        }
    }, [
        props.data,
        props.widgetDataLoading,
    ]);    

    const renderWidget = () => {
        return (
            <div className="w-100">
                {renderBody()}
                {cardFooter !== undefined ? 
                    <Card.Footer> {cardFooter} </Card.Footer>
                : ''}
            </div>
        );
    };

    const renderBody = () => {
        if (variant === 'info') {
            if (direction === 'horizontal') {
                return (
                    <Card.Body className="px-0 py-2">
                        {renderInfoCard()}
                    </Card.Body>
                )
            }
            else if (direction === 'vertical') {
                return (
                    <Card.Body className="px-0 py-2">
                        {renderInfoCard()}
                    </Card.Body>
                )
            }
        }
        else {
            if (direction === 'horizontal') {
                return (
                    <Card.Body className="">
                        <Row className="flex-wrap justify-content-around">
                            {renderDefaultCard()}
                        </Row>
                    </Card.Body>
                )
            }
            else if (direction === 'vertical') {
                return (
                    <Card.Body className="px-0 py-2">
                        {renderDefaultCard()}
                    </Card.Body>
                )
            }
        }
    }

    const renderRow = (row, index) => {
        switch (variant) {
            case 'info':
                return renderInfoRow(row, index)
                break;
            
            default:
                return renderDefaultRow(row, index)
                break;
        }
    }

    const renderWithOverlay = (row, index, tooltipDirection="left") => {
        return (
            <OverlayTrigger
                placement={tooltipDirection}
                delay={{ show: 250, hide: 400 }}
                overlay={row[3] && (
                    <Tooltip id={`summary-tooltip-${index}`}>
                        {row[3]}
                    </Tooltip>
                )}
            >
                {renderRow(row, index)}
            </OverlayTrigger>
        )
    }

    const renderDefaultCard = () => {
        return ( widgetData.map((row, index) => (
            (row.length > 0) ? (
                <div key={index} style={(direction === "horizontal" && widgetData.length <= 4 ? {minWidth: `${100 / widgetData.length}%`} : {})}>
                    { row[3] !== undefined ?
                        renderWithOverlay(row, index, (direction === "horizontal" ? "top" : "left"))
                        : 
                        renderDefaultRow(row, index)
                    }
                </div>
            ) : null
        )))
    }

    const renderDefaultRow = (row, index) => {
        if (direction === 'vertical') {
            return (
                <div className={`py-2 mx-3 d-flex flex-row align-items-center justify-content-between border-bottom`}>
                    <div className="px-1 d-flex flex-column text-dark">
                        <span className="body-1">{row[0]}</span>
                    </div>
                    <div className="px-1 d-flex flex-column text-right px-2 flex-grow-1">
                        <span className="text-dark body-1">{row[1]}</span>
                    </div>
                    <div className="px-1 d-flex flex-column text-right" style={{minWidth: minWidthPct ?? "120px"}}>
                        <span className="body-2">{row[2]}</span>
                    </div>
                </div>
            );
        }
        else if (direction === 'horizontal') {
            return (
                <Col className={`justify-content-center align-items-center px-3 border-bottom pb-3`}>
                    <div className="d-flex flex-row w-100 justify-content-start align-items-baseline">
                        <div className="d-flex flex-column text-left text-dark">
                            <span className="text-dark text-h4">{row[0]}</span>
                        </div>
                        <div className="d-flex flex-column text-left pl-3">
                            <span className="body-2 text-h6">{row[2]}</span>
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        <div className="w-100 mb-0 text-left">
                            <span className="text-dark text-h2">{row[1]}</span>
                        </div>
                    </div>
                </Col>
            );
        }
    }

    const renderInfoCard = () => {
        let displayRest = false;
        widgetData.forEach((row, index) => {
            if (row.length > 0 && index >= 1) {
                displayRest = true;
            }
        })

        return (
            <Col>
                <Row className="border-bottom align-items-center">
                    <Col className={`d-flex flex-column py-3 `+(direction === 'vertical' ? ' flex-fill border-bottom ' : '')}>
                        {
                            widgetData.map((row, index) => (
                                (row.length > 0 && index === 0) ? (
                                    <div key={index} className="h-100">
                                        { row[3] !== undefined ?
                                            renderWithOverlay(row, index, 'top')
                                            : 
                                            renderInfoRow(row, index)
                                        }
                                    </div>
                                ) : null
                            ))
                        }
                    </Col>
                    { displayRest ? (
                        <Col className="d-flex flex-column">
                            {
                                widgetData.map((row, index) => (
                                    (row.length > 0 && index >= 1) ? (
                                        <div key={index}>
                                            { row[3] !== undefined ?
                                                renderWithOverlay(row, index, 'right')
                                                : 
                                                renderInfoRow(row, index)
                                            }
                                        </div>
                                    ) : null
                                ))
                            }
                        </Col>
                    ) : null}
                </Row>
            </Col>
        )
    }

    const renderInfoRow = (row, index) => {
        // first one
        if (index === 0) {
            return (
                <Col className={`justify-content-center align-items-center px-0`}>
                    <div className="d-flex flex-row w-100 justify-content-between align-items-baseline">
                        <div className="d-flex flex-column text-left text-dark">
                            <span className="text-dark h3">{row[0]}</span>
                        </div>
                        <div className="d-flex flex-column text-right">
                            <span className="body-2 text-h5">{row[2]}</span>
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        <div className="w-100 mb-0 text-left">
                            <span className="text-dark h1">{row[1]}</span>
                        </div>
                    </div>
                </Col>
            );
        }
        else {
            return (
                <div className={`py-3 d-flex flex-column align-items-center `+(index === (widgetData.length - 1) ? '' : `border-bottom`)}>
                    <Row className="w-100 justify-content-between align-items-baseline">
                        <div className="d-flex flex-column text-dark">
                            <span className="w-100 body-1 text-h5 mb-0">{row[0]}</span>
                        </div>
                        <div className="d-flex flex-column text-right">
                            <span className="w-100 body-1 text-h6 mb-0">{row[2]}</span>
                        </div>
                    </Row>
                    <Row className="w-100 justify-content-start">
                        <div className="d-flex flex-column text-left">
                            <span className="w-100 text-dark text-bold body-1 text-h4 mb-0">{row[1]}</span>
                        </div>
                    </Row>
                </div>
            );
        }
    }

    return (
        <div className={`${variant === 'default' ? 'mt-4' : ''}`}>
            <Card className="h-100 bg-white border-0" style={{ minHeight: minHeight ?? '25em' }}>
                {headerTitle ? (
                    <Card.Header className="bg-white px-2 px-3">
                        <div className="d-flex flex-row align-items-center px-1">
                            <div>
                                <h5 className="mb-0">
                                    {headerTitle}
                                </h5>
                            </div>
                        </div>
                    </Card.Header>
                ) : ''}
                
                <div className="h-100 d-flex justify-content-center align-items-center">
                    {widgetLoading ? <WidgetSpinner /> : renderWidget()}
                </div>
            </Card>
        </div>
    );
}

export default TextCard;