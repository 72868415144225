import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import Select from "react-select";

import { AuthContext } from "../../contexts/AuthContext";
import { BrandContext } from "../../contexts/BrandContext";

import { getVparts } from "../../api/brand";

// Specify props.allowSelectAll = true
// const Select = props => {
//     if (props.allowSelectAll) {
//         return (
//             <Row className="align-items-stretch">
//                 <Col>
//                     <div className="input-group">
//                         <ReactSelect {...props} />
//                         <span className="input-group-append">
//                             <button
//                                 type="button"
//                                 onClick={() => props.onChange(props.options)}
//                                 className="btn btn-primary"
//                             >
//                                 Select All
//                             </button>
//                         </span>
//                     </div>
//                 </Col>
//             </Row>
//         );
//     }

//     return <ReactSelect {...props} />;
// };

const VpartFilter = (props) => {
    const { token } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);

    const [widgetLoading, setWidgetLoading] = useState(true);

    const [vparts, setVparts] = useState();
    const [selectedVparts, setSelectedVparts] = useState([]);

    useEffect(() => {
        const getBrandVparts = async () => {
            try {
                const vparts = await getVparts(token, {
                    brandid: brand.brandId,
                });

                // console.log("VParts", vparts.data);
                setVparts(vparts.data);
                setWidgetLoading(false);
            } catch (err) {
                setWidgetLoading(true);
            }
        };

        if (brand.brandId !== undefined) {
            getBrandVparts();
        } else {
            setWidgetLoading(true);
        }
    }, [token, brand]);

    const onVpartChange = (value) => {
        setSelectedVparts(value);
        props.onChange(value);
    };

    return (
        <Select
            className="text-dark w-100 rounded-0"
            placeholder="Select vParts"
            value={selectedVparts}
            options={vparts}
            name="vpartSelect"
            getOptionLabel={(option) => `${(option.vpartAlt !== null && option.vpartAlt !== undefined && option.vpartAlt !== '' ? option.vpartAlt : option.vpart)} - ${option.shortTitle}`}
            // getOptionValue={(option) => option.vpart}
            // inputValue={(option) => `${(option.vpartAlt !== null && option.vpartAlt !== undefined && option.vpartAlt !== '' ? option.vpartAlt : option.vpart)} - ${option.shortTitle}`}
            onChange={(option) => onVpartChange(option)}
            isMulti={true}
            allowSelectAll={true}
            isSearchable={true}
            isLoading={widgetLoading}
            noOptionsMessage={(inputValue) => "No Brand Vparts"}
        />
    );
};

export const VpartFilterCard = (props) => {
    const [selectedVparts, setSelectedVparts] = useState([]);

    const submitSelectedVparts = () => {
        // console.log('submitSelectedVprts', selectedVparts);
        props.onSelectSubmit(selectedVparts);
    };

    return (
        <Row className="mt-4">
            <Col>
                <Card className="bg-white">
                    <Card.Body className="d-flex flex-row align-items-stretch">
                        <VpartFilter onChange={(value) => setSelectedVparts(value)}></VpartFilter>

                        <Button className="rounded-0 d-flex" onClick={() => submitSelectedVparts()}>
                            {" "}
                            Apply
                        </Button>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default VpartFilter;
