import React, { useContext, useState, useEffect } from "react";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";
import moment from "moment";

import { buyboxOverview } from "../../../api/reseller";

import { AuthContext } from "../../../contexts/AuthContext";
import { BrandContext } from "../../../contexts/BrandContext";
import { HeaderContext } from "../../../contexts/HeaderContext";
import { ToastContext } from "../../../contexts/ToastContext";

import {
    graph_blue_light,
} from "../../../components/helper/ColorFormatter";
import WidgetSpinner from "../../../components/helper/WidgetSpinner";

function BuyboxAggregate(props) {
    const { token } = useContext(AuthContext);
    const { selectedDefaultDates, dataLoading, marketplace } = useContext(HeaderContext);

    const [data, setData] = useState([]);
    const [widgetLoading, setWidgetLoading] = useState(true);
    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);

    const { showConfigButton = true, headerTitle = "Buybox Timeline", headerSubtitle = "", snapshotTime = false } = props;

    /**
     * useEffect with empty array as second argument = Run only once (on the first render)
     */
    useEffect(() => {
        const getData = async () => {
            setWidgetLoading(true);
            try {
                // Always daily
                const res = await buyboxOverview(token, {
                    brandid: brand.brandId,
                    tf_s: snapshotTime ? moment(selectedDefaultDates.tp_s).format('YYYY-MM-DD') : moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                    tf_e: snapshotTime ? moment(selectedDefaultDates.tp_e).format('YYYY-MM-DD') : moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                    timeframe: "daily",
                    marketplace: marketplace,
                });

                setData(res.data.snapshot[0]);

                setWidgetLoading(false);
            } catch (err) {
                setWidgetLoading(false);
                toast(err.message);
            }
        };

        if (!dataLoading) {
            getData();
        }
    }, [token, brand.brandId, toast, dataLoading, selectedDefaultDates, snapshotTime, marketplace]);

    const renderChart = () => {
        let options = {
            chart: {
                height: "100%",
                width: "100%",
                type: "radialBar",
            },
            sparkline: {
                enabled: true,
            },
            grid: {
                padding: {
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                },
            },
            colors: [graph_blue_light],
            plotOptions: {
                radialBar: {
                    //size: 300,
                    //offsetY: 10,
                    hollow: {
                        margin: 15,
                        size: "65%",
                        background: "#fff",
                    },
                    dataLabels: {
                        name: {
                            show: false,
                            offsetY: -8,
                            color: graph_blue_light,
                            fontSize: "13px",
                        },
                        value: {
                            color: graph_blue_light,
                            offsetY: 4,
                            fontSize: "26px",
                            fontWeight: 700,
                            show: true,
                        },
                    },
                },
            },
            labels: ["Aggregate Buybox"],
        };

        let series = [(parseFloat(data["expression"]) * 100).toFixed(2)];

        return (
            <ResponsiveContainer>
                <Chart options={options} series={series} type="radialBar" />
            </ResponsiveContainer>
        );
    };

    return (
        <div className="card h-100" /*style={{ overflow: "auto" }}*/>
            <div className="card-header bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <h5>{props.headerTitle}</h5>
                        {props.headerSubtitle !== "" ? (
                            <p className="body-1 text-secondary mb-0">{props.headerSubtitle}</p>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="ml-auto ">
                        {showConfigButton === true ? (
                            <button
                                type="button"
                                className="btn btn-primary btn-sm "
                                onClick={() => {
                                    //TODO: Add Configuration Modal
                                }}
                            >
                                Config
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>

            <div className="card-body w-100 pt-0 pb-0">{widgetLoading ? <WidgetSpinner /> : renderChart()}</div>
        </div>
    );
}

export default BuyboxAggregate;
