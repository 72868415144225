import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";

const Unauthorized = ({ history }) => {

    const { loginWithRedirect } = useAuth0();

    const [ counter, setCounter ] = useState(60);
    const [ redirectSeconds, setRedirectSeconds ] = useState(10);

    useEffect(() => {
        if (redirectSeconds === 0) {
            loginWithRedirect()
        }

        redirectSeconds > 0 && setTimeout(() => setCounter(setRedirectSeconds(redirectSeconds - 1)), 1000);
    }, [redirectSeconds]);

    return (
        <div>
            <div style={{ height: "100vh" }} className="d-flex justify-content-center align-items-center">
                <div className="text-center" style={{ width: 400 }}>
                    <h1 className="text-center"> 401 Unauthorized </h1>
                    
                    <Button
                        className="btn btn-lg btn-primary btn-block mt-2 py-2 align-items-center"
                        variant="primary"
                        type="button"
                        // onClick={loginWithRedirect()}
                    >
                        <p className="mb-0">Continue to Login</p>
                        <p className="mb-0 text-body-bold"> You will be redirected to login in {redirectSeconds}</p>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Unauthorized;
