import React from "react";

const CopyrightNotice = (props) => {
    const {
        withVersion = false,
    } = props;

    return (
        <div className="mt-5 mb-3 text-muted">
            {withVersion ? (
                <p>Retail Operations Dashboard | Version: {process.env.REACT_APP_VERSION}</p>
            ) : ''}
            <p>© Front Row {new Date().getFullYear()}</p>
        </div>
    )
};

export default CopyrightNotice;