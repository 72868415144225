import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { BrandContext } from "../contexts/BrandContext";
import { HeaderContext } from "../contexts/HeaderContext";

import Loading from "../components/helper/Loading";

import Sidebar from "./Sidebar";
import Header from "./Header";

const AuthBrandRoute = ({ component: Component, noRedirect = false, ...rest }) => {
    const { isLoading, loading: isAuthLoading, isAPIAuthenticated, isLoggedIn } = useContext(AuthContext);

    //Will wait for BrandContext & HeaderContext to finish initializing before rendering
    const { init: brandLoaded, hasSelectedBrand } = useContext(BrandContext);
    const { init: headerLoaded } = useContext(HeaderContext);

    const renderPros = (routeProps) => {
        return (
            <div className="d-flex w-100">
                <div>
                    <Sidebar />
                </div>
                <div className="main-display flex-grow-1">
                    <div className="bg-white" style={{ borderRadius: "30px 0 0 30px" }}>
                        <div>
                            <Header />
                            <div style={{
                                    height: "100vh",
                                    overflow: "auto",
                                    paddingBottom: "150px",
                                }}
                            >
                                <Component {...routeProps} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (!isLoading && !isAuthLoading ? 
        (!headerLoaded || !brandLoaded) ? 
            null 
            : (
                <Route
                    {...rest}
                    render={(routeProps) =>
                        isAPIAuthenticated === true && hasSelectedBrand() === true ? (
                            renderPros(routeProps)
                        ) : isAPIAuthenticated === false ? (
                            noRedirect === false ? (
                                isLoggedIn === true ? (
                                    <Redirect to="/verify" />
                                ) : (
                                    <Redirect to="/login" />
                                )
                            ) : null
                        ) : (noRedirect === false) ? (
                            <Redirect to="/brand" />
                        ) : null
                    }
                />
            )
        : null
    )
    
    /*  we are spreading routeProps to be able to access this routeProps in the component. */
};

export default AuthBrandRoute;
