import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";

import useIsMountedRef from "../../helper/isMountedRef";
import Loading from "../../helper/Loading";
import TextCard from "../../../pages/BrandReport/widgets/TextCard";
import { CurrencyFormatter, PercentFormatter, NumberFormatter } from "../../helper/IntlNumberFormatter";

const TemplateMetricsCard = (props) => {
    // console.log("TemplateMetricsCard", props);

    const isMountedRef = useIsMountedRef();

    const [visible, setVisible] = useState(props.visible ?? true);

    const {
        metricsTemplate,
        data,
        dataMeta,
        vsPrevious,
        pageDataLoading,
        textCardProps = props.textCardProps ?? {
            headerTitle: null,
            direction: 'vertical',
            variant: null,
            minHeight: "auto",
        },
        width,
    } = props;

    useEffect(() => {
        if (props.visible !== false) {
            let is_visible = false;
    
            metricsTemplate.forEach(metricProps => {
                const {
                    visible,
                } = metricProps;
    
                if ((visible ?? true)) {
                    is_visible = true;
                }
            });
    
            setVisible(is_visible);
        }
    }, [props])

    const displayPrevious = (pt_value, py_value, pmsd_value, pc_value, params) => {
        const {
            type = 'percent',
            show_change = false,
            absolute_value = true,
            decimal = false,
            display_arrow = true,
            extra = undefined,
        } = params;

        const value = params.value ?? getPreviousValue(pt_value, py_value, pmsd_value, pc_value);

        switch (type) {
            case 'number':
                return (
                    <NumberFormatter
                        colorText={true}
                        colorBackground={false}
                        replaceEmpty={true}
                        showChange={show_change}
                        displayAbsoluteValue={absolute_value}
                        showExtra={extra}
                        displayDecimal={decimal}
                        displayArrow={display_arrow}
                        value={value}
                    />
                )
        
            case 'percent':
            default:
                return (
                    <PercentFormatter
                        colorText={true}
                        colorBackground={false}
                        replaceEmpty={true}
                        showChange={show_change}
                        displayAbsoluteValue={absolute_value}
                        showExtra={extra}
                        displayDecimal={decimal}
                        displayArrow={display_arrow}
                        value={value}
                    />
                )
        }
    }

    const displayPreviousTooltipPercent = (pt_value, py_value, pmsd_value, pc_value, params) => {
        let extra = params?.extra ?? undefined;
        let show_change = params?.show_change ?? false;
        let absolute_value = params?.absolute_value ?? true;

        return [
            getPreviousDates(),
            <PercentFormatter
                key={0}
                replaceEmpty={true}
                showChange={show_change}
                displayAbsoluteValue={absolute_value}
                showExtra={extra}
                value={getPreviousValue(pt_value, py_value, pmsd_value, pc_value)}
            />,
        ];
    };

    const displayPreviousTooltipNumber = (pt_value, py_value, pmsd_value, pc_value, params) => {
        let extra = params?.extra ?? undefined;
        let show_change = params?.show_change ?? false;
        let absolute_value = params?.absolute_value ?? true;
        let decimal = params?.decimal ?? false;

        return [
            getPreviousDates(),
            <NumberFormatter
                key={0}
                displayDecimal={decimal}
                replaceEmpty={true}
                showChange={show_change}
                displayAbsoluteValue={absolute_value}
                showExtra={extra}
                value={getPreviousValue(pt_value, py_value, pmsd_value, pc_value)}
            />,
        ];
    };

    const displayPreviousTooltipCurrency = (pt_value, py_value, pmsd_value, pc_value, params) => {
        let decimal = params?.decimal ?? false;

        return [
            getPreviousDates(),
            <CurrencyFormatter
                key={0}
                replaceEmpty={true}
                colorText={false}
                displayDecimal={decimal}
                value={getPreviousValue(pt_value, py_value, pmsd_value, pc_value)}
            />,
        ];
    };

    const getPreviousValue = (pt_value, py_value, pmsd_value, pc_value) => {
        if (vsPrevious === "py") {
            return py_value;
        } else if (vsPrevious === "pmsd") {
            return pmsd_value;
        }
        else if (vsPrevious === "pc") {
            return pc_value;
        }
        else {
            return pt_value;
        }
    };

    const getPreviousDates = (suffix = ': ') => {
        if (vsPrevious === "py") {
            return `${dataMeta?.py?.py_s} - ${dataMeta?.py?.py_e}${suffix}`;
        } else if (vsPrevious === "pmsd") {
            return `${dataMeta?.pmsd?.pmsd_s} - ${dataMeta?.pmsd?.pmsd_e}${suffix}`;
        }
        else if (vsPrevious === "pc") {
            return `${dataMeta?.previous_custom?.pc_s} - ${dataMeta?.previous_custom?.pc_e}${suffix}`;
        }
        else {
            return `${dataMeta?.pt?.pt_s} - ${dataMeta?.pt?.pt_e}${suffix}`;
        }
    };
    
    const processTemplate = () => {
        let template_card = [];
        
        metricsTemplate.forEach(metricProps => {
            const {
                row_data = metricProps.row_data ?? data,
                name,
                metric,
                change_metric = metricProps.change_metric ?? metricProps.metric+'_change_pct',
                type,
                format = metricProps.format ?? {},
                tooltip = metricProps.tooltip ?? name,
                visible = metricProps.visible ?? true,
            } = metricProps;

            const current_value = row_data?.[metric] ?? '';

            let metric_template = [];

            if ((visible ?? true)) {

                metric_template = [null, null, null, null];
                metric_template[0] = name;

                if (React.isValidElement(change_metric)) {
                    metric_template[2] = change_metric;
                }
                else {
                    metric_template[2] = displayPrevious(
                        row_data?.[`previous_${change_metric}`],
                        row_data?.[`py_previous_${change_metric}`],
                        row_data?.[`pmsd_previous_${change_metric}`],
                        row_data?.[`pc_previous_${change_metric}`],
                        {...format?.previous ?? {}}
                    );
                }

                // console.log(name, type, format);

                switch (type) {
                    case 'currency':
                        metric_template[1] = (
                            <CurrencyFormatter
                                value={current_value}
                                replaceEmpty={true}
                                displayDecimal={format.decimal ?? false}
                            />
                        )

                        metric_template[3] = (
                            <div>
                                <p>{tooltip}</p>
                                <p className="mb-0">
                                    {displayPreviousTooltipCurrency(
                                        row_data?.[`pt_${metric}`],
                                        row_data?.[`py_${metric}`],
                                        row_data?.[`pmsd_${metric}`],
                                        row_data?.[`pc_${metric}`],
                                        { decimal: format.decimal ?? false }
                                    )}
                                </p>
                            </div>
                        )
                        
                        break;

                    case 'number':
                        metric_template[1] = (
                            <NumberFormatter
                                value={current_value}
                                replaceEmpty={true}
                                displayDecimal={format.decimal ?? false}
                            />
                        )

                        metric_template[3] = (
                            <div>
                                <p>{tooltip}</p>
                                <p className="mb-0">
                                    {displayPreviousTooltipNumber(
                                        row_data?.[`pt_${metric}`],
                                        row_data?.[`py_${metric}`],
                                        row_data?.[`pmsd_${metric}`],
                                        row_data?.[`pc_${metric}`],
                                        {
                                            decimal: format.decimal,
                                            extra: format.extra,
                                            show_change: format.show_change,
                                            absolute_value: format.absolute_value,
                                        }
                                    )}
                                </p>
                            </div>
                        )
                        break;

                    case 'percent':
                        metric_template[1] = (
                            <PercentFormatter
                                value={current_value}
                                replaceEmpty={true}
                                displayDecimal={format.decimal ?? false}
                            />
                        )

                        metric_template[3] = (
                            <div>
                                <p>{tooltip}</p>
                                <p className="mb-0">
                                    {displayPreviousTooltipPercent(
                                        row_data?.[`pt_${metric}`],
                                        row_data?.[`py_${metric}`],
                                        row_data?.[`pmsd_${metric}`],
                                        row_data?.[`pc_${metric}`],
                                        {
                                            decimal: (format.decimal ?? false),
                                            extra: format.extra,
                                            show_change: format.show_change,
                                            absolute_value: format.absolute_value,
                                        }
                                    )}
                                </p>
                            </div>
                        )
                        break;

                    case 'number_percent':
                        metric_template[1] = (
                            <NumberFormatter
                                value={current_value}
                                replaceEmpty={true}
                                displayDecimal={format.decimal ?? 1}
                                showExtra={format.extra ?? "%"}
                            />
                        )

                        metric_template[3] = (
                            <div>
                                <p>{tooltip}</p>
                                <p className="mb-0">
                                    {displayPreviousTooltipNumber(
                                        row_data?.[`pt_${metric}`],
                                        row_data?.[`py_${metric}`],
                                        row_data?.[`pmsd_${metric}`],
                                        row_data?.[`pc_${metric}`],
                                        {
                                            decimal: (format.decimal ?? 1),
                                            extra: (format.extra ?? "%"),
                                            show_change: format.show_change,
                                            absolute_value: format.absolute_value,
                                        }
                                    )}
                                </p>
                            </div>
                        )
                        break;

                    case 'ratio':
                        metric_template[1] = (
                            <NumberFormatter
                                value={current_value}
                                replaceEmpty={true}
                                displayDecimal={format.decimal ?? true}
                                showExtra={format.extra ?? "x"}
                            />
                        )

                        metric_template[3] = (
                            <div>
                                <p>{tooltip}</p>
                                <p className="mb-0">
                                    {displayPreviousTooltipNumber(
                                        row_data?.[`pt_${metric}`],
                                        row_data?.[`py_${metric}`],
                                        row_data?.[`pmsd_${metric}`],
                                        row_data?.[`pc_${metric}`],
                                        {
                                            decimal: (format.decimal ?? true),
                                            extra: (format.extra ?? "x"),
                                            show_change: format.show_change,
                                            absolute_value: format.absolute_value,
                                        }
                                    )}
                                </p>
                            </div>
                        )
                        break;
                
                    default:
                        throw 'Invalid Metric Type';
                        break;
                }
            }

            if (React.isValidElement(tooltip)) {
                metric_template[3] = tooltip;
            }

            template_card.push(metric_template)
        });

        return template_card;
    }

    return visible === true ? (
        pageDataLoading === true ? (
            <Loading></Loading>
        ) : (
            <Col
                className={`${(props.textCardProps.direction ?? '') === 'horizontal' ? 'flex-fill' : ''}`}
                style={width && {flexBasis: width}}
            >
                <TextCard
                    data={data && processTemplate}
                    widgetDataLoading={isMountedRef.current && pageDataLoading}
                    {...textCardProps}
                />
            </Col>
        )
    ) : null;
};

export default TemplateMetricsCard;
