import React, { useContext, useEffect } from "react";

import { Link } from "react-router-dom";
import { Formik } from "formik";
import { Form, Button, Container } from "react-bootstrap";

import { AuthContext } from "../../contexts/AuthContext";
import { useAuth0 } from "@auth0/auth0-react";

import logo from "../../assets/logo.svg";
import "./signin.css";

import CopyrightNotice from "../../components/helper/CopyrightNotice";

const Forgot = ({ history }) => {
    const { 
        // Auth0
        user, isAuthenticated, isLoading
    } = useContext(AuthContext);

    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (!isAuthenticated && !isLoading) {
            redirectForgotPassword()
        }

    }, [user, isAuthenticated]);

    const redirectForgotPassword = () => {
        loginWithRedirect({authorizationParams: {
            // DONT WORK BUT IF THEY EVER ADD
            screen_hint: "forgot-password",
        }})
    }

    const renderForm = () => {
        // const onSuccess = async (values) => {
        //     setLoading(true);
        //     try {
        //         const res = await forgot(values);
        //         toast(res.data.message);
        //         setLoading(false);
        //     } catch (err) {
        //         //console.log(err);
        //         toast("Request Error! Double check your email and try again~");
        //         setLoading(false);
        //     }
        // };

        return (
            <div className="d-flex align-items-center flex-column justify-content-center" style={{ height: "100vh" }}>
                <div className="text-center w-100">
                    <Formik
                        // onSubmit={onSuccess}
                        // initialValues={{
                        //     email: "",
                        // }}
                        // validationSchema={validationSchema}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors }) => {
                            return (
                                <Form className="form-signin" noValidate>
                                    <img src={logo} alt="Fortress" width="300" height="130"></img>
                                    <hr />
                                    {/* <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder="Please enter your email"
                                            autoComplete="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </Form.Group> */}

                                    <Button
                                        className="btn btn-lg btn-primary btn-block"
                                        variant="primary"
                                        type="button"
                                        onClick={redirectForgotPassword()}
                                    >
                                        Recover Password
                                    </Button>
                                </Form>
                            );
                        }}
                    </Formik>
                    <Link to="login">Back to Login</Link>
                </div>
                
                <CopyrightNotice/>
            </div>
        );
    };

    return <Container fluid>{renderForm()}</Container>;
};

export default Forgot;
