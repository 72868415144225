import React, { createContext, useState, useEffect, useContext } from "react";
import moment from "moment-timezone";

import { today, yesterday, YTD_s, MTD_s } from "../components/helper/DateVars";
import { BrandContext } from "./BrandContext";
import localforage from "localforage";

export const HeaderContext = createContext({});

const verifyDates = (dates, dateCheck = false) => {
    const requiredKeys = ["timeframe", "tf_s", "tf_e", "tp_s", "tp_e"];

    if (!dates) return false;

    for (let i = 0; i < requiredKeys.length; i++) {
        if (!(requiredKeys[i] in dates)) {
            return false;
        }
        if (dates[requiredKeys[i]] === null || dates[requiredKeys[i]] === undefined) {
            return false;
        }
    }

    if (dateCheck) {
        return dates.tf_s <= dates.tf_e && dates.tp_s <= dates.tp_e && dates.tf_s <= today && dates.tp_s <= today;
    }

    return true;
};

const getDefaultDates = () => {
    return {
        tf_s: YTD_s, // YTD - Monthly = New Default
        tf_e: yesterday, // 1 day ago
        tp_s: MTD_s, // MTD
        tp_e: yesterday,
        timeframe: "mom",
    };
};

const HeaderProvider = ({ children }) => {
    const [pageTitle, setPageTitle] = useState(process.env.REACT_APP_TITLE);

    const [timeFrame, setTimeFrame] = useState();

    const [currency, setCurrency] = useState("USD");

    const [marketplace, setMarketplace] = useState('All');
    const [pageMarketplace, setPageMarketplace] = useState(true);

    const [weekstart, setWeekstart] = useState('Sunday');

    const [timezone, setTimezone] = useState(moment.tz.guess());

    const [selectedDefaultDates, setSelectedDefaultDates] = useState({});

    const [selectedPreviousCustomDates, setSelectedPreviousCustomDates] = useState({
        pc_s: moment().format('YYYY-MM-DD'),
        pc_e: moment().format('YYYY-MM-DD'),
    });

    const [pageTimeframe, setPageTimeframe] = useState("timeframe");

    const [init, setInit] = useState(false);

    const { brand, init: brandInit } = useContext(BrandContext);

    const [comparativeOptions, setComparativeOptions] = useState(() => {return []});
    const [comparativeValue, setComparativeValue] = useState(() => {return {value: "py", label: "Previous Year"}});

    useEffect(() => {
        const init = async () => {

            let defaultCurrency = await localforage.getItem("fortress-last-selected-currency");

            let defaultMarketplace = await localforage.getItem("fortress-last-selected-marketplaces");

            let defaultWeekstart = await localforage.getItem("fortress-last-selected-weekstart");

            let defaultDates = getDefaultDates();

            if (!defaultCurrency) {
                defaultCurrency = "USD";
            }

            if (!defaultMarketplace) {
                defaultMarketplace = ['All'];
            }
            else if (typeof defaultMarketplace === 'string') {
                // Multi-Select
                defaultMarketplace = [defaultMarketplace];
            }

            if (!defaultWeekstart) {
                defaultWeekstart = 'Sunday';
            }

            setSelectedDefaultDates(defaultDates);
            setCurrency(defaultCurrency);
            setMarketplace(defaultMarketplace);
            setPageMarketplace(true);

            setInit(true);
        };
        init();
    }, []);

    useEffect(() => {
        if (init && currency) {
            localforage.setItem("fortress-last-selected-currency", currency);
        }
    }, [currency, init]);

    useEffect(() => {
        if (init && marketplace) {
            localforage.setItem("fortress-last-selected-marketplaces", marketplace);
        }
    }, [marketplace, init]);

    useEffect(() => {
        if (init && weekstart) {
            localforage.setItem("fortress-last-selected-weekstart", weekstart);
        }
    }, [weekstart, init]);

    const setDates = (dates) => {
        if (verifyDates(dates)) {
            setSelectedDefaultDates(dates);
        }
    };

    const toMomentDateStr = (date) => {
        return moment(date).format('YYYY-MM-DD')
    }

    return (
        <HeaderContext.Provider
            value={{
                init,
                pageTitle,
                setPageTitle,
                timeFrame,
                pageTimeframe,
                pageMarketplace,
                setPageMarketplace,
                currency,
                setCurrency,
                setPageTimeframe,
                selectedDefaultDates,
                selectedPreviousCustomDates,
                setSelectedPreviousCustomDates,
                setDates,
                getDefaultDates,
                verifyDates,
                marketplace,
                setMarketplace,
                timezone,
                setTimezone,
                weekstart,
                setWeekstart,
                toMomentDateStr,
                comparativeOptions,
                setComparativeOptions,
                comparativeValue,
                setComparativeValue,
            }}
        >
            {children}
        </HeaderContext.Provider>
    );
};

export default HeaderProvider;
