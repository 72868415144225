import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";

import { AuthContext } from "../contexts/AuthContext";

import CopyrightNotice from "../components/helper/CopyrightNotice";

const Welcome = ({ history }) => {
    const { apiUser } = useContext(AuthContext);

    return (
        <div className="h-100 d-flex justify-content-center align-items-center">
            <Col>
                <Row className="justify-content-center w-100 align-items-center text-center">
                    <h1 className="text-center"> 
                        {`Hello, ${apiUser?.name ?? ""}`} 
                    </h1>
                </Row>
            </Col>
            <Row className="text-center"
                style={{
                    position: 'absolute',
                    bottom: 0,
                }}
            >
                <CopyrightNotice withVersion={true}/>
            </Row>
        </div>
    );
};

export default Welcome;
