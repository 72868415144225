import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { BrandContext } from "../../contexts/BrandContext";
import { HeaderContext } from "../../contexts/HeaderContext";
export const CurrencyFormatter = (props) => {
    const { brand } = useContext(BrandContext);
    const { currency } = useContext(HeaderContext);

    const locale = brand?.currencyLocale ?? "en-US";

    props = {
        displayDecimal: false, // true means display 2 decimal points. false = no decimal. "clean" = max 2, min 0
        colorText: false, // For color based on value
        replaceEmpty: false, // replaces undefined or '' with 'N/A'
        value: 0,
        ...props,
    };

    const formatCurrency = (value) => {
        let minDecimal = 0;
        let maxDecimal = 0;
        if (props.displayDecimal === true) {
            minDecimal = 2;
            maxDecimal = 2;
        } else if (props.displayDecimal === "clean") {
            minDecimal = 0;
            maxDecimal = 2;
        }
        
        var formatter = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: currency,
            minimumFractionDigits: minDecimal,
            maximumFractionDigits: maxDecimal,
        });

        return formatter.format(value);
    };

    const displayValue = (value) => {
        if (value === "" || value === undefined) {
            if (props.replaceEmpty === true) return "N/A";
            else return "";
        }

        if (props.colorText === true) {
            if (value > 0) {
                return <span className="text-success">{formatCurrency(value)}</span>;
            } else if (value < 0) {
                return <span className="text-danger">{formatCurrency(value)}</span>;
            } else {
                return <span className="text-default">{formatCurrency(value)}</span>;
            }
        } else {
            return formatCurrency(value);
        }
    };

    return <span>{displayValue(props.value)}</span>;
};

export const PercentFormatter = (props) => {
    const { brand } = useContext(BrandContext);

    const {
        colorText = false, // For color based on value
        colorBackground = false, // For background color based on value
        bbxThreshold = false, // BBX has different threshold for color
        displayArrow = false, // FontAwesome Arrows for positive or negative values
        replaceEmpty = false, // replaces undefined or '' with 'N/A'
        showChange = false, // if true = + sign after if positive. false = ''
        displayAbsoluteValue = false, // if true Math.abs(value). false = default
        displayDecimal = props.displayDecimal ?? true,
        showExtra = undefined, // Additional
        value = 0,
    } = props;

    const percentFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
        style: "percent",
        minimumFractionDigits: (displayDecimal ? 2 : 0),
    });

    const formatValue = (value) => {
        if (value === "" || value === undefined) {
            if (props.replaceEmpty === true) {
                return "N/A";
            } else {
                return "";
            }
        } else {
            return percentFormatter.format(value);
        }
    };

    const displayValue = (value) => {
        let textColor = "text-default";
        let backgroundColor = "";

        if (props.bbxThreshold === true) {
            return (
                <div className={value >= 0.66 ? "text-primary" : value >= 0.33 ? "text-warning" : "text-danger"}>
                    {formatValue(value)}
                </div>
            );
        }

        if (props.colorText === true) {
            if (value > 0) textColor = "text-success";
            else if (value < 0) textColor = "text-danger";
        }

        if (colorBackground === true) {
            if (value > 0) backgroundColor = "bg-success";
            else if (value < 0) backgroundColor = "bg-red-100";
        }

        let displayClass = `${textColor} ${backgroundColor}`;
        if (colorBackground === true) {
            displayClass = `body-2 px-2 py-1 ${textColor} ${backgroundColor}`;
        }

        return (
            <span className={displayClass}>
                {props.displayArrow === true && (
                    <FontAwesomeIcon icon={value > 0 ? faArrowUp : faArrowDown} className="mr-2" />
                )}
                {props.showChange === true && value > 0 ? "+" : ""}
                {props.displayAbsoluteValue === true ? formatValue(Math.abs(value)) : formatValue(value)}
                {props.showExtra !== undefined ? props.showExtra : ""}
            </span>
        );
    };

    return <span>{displayValue(props.value)}</span>;
};

export const NumberFormatter = (props) => {
    const { brand } = useContext(BrandContext);

    const locale = brand?.currencyLocale ?? "en-US";

    const {
        displayDecimal = false,
        colorText = false, // For color based on value
        colorBackground = false, // For background color based on value
        replaceEmpty = false, // replaces undefined or '' with 'N/A'
        showChange = false, // if true = + sign after if positive. false = ''
        displayAbsoluteValue = false, // if true Math.abs(value). false = default
        showExtra = undefined, // Additional
        value = 0,
    } = props;

    const wholeNumberFormatter = new Intl.NumberFormat(locale, {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const numberFormatter = new Intl.NumberFormat(locale, {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const oneDecimalFormatter = new Intl.NumberFormat(locale, {
        style: "decimal",
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    });

    const formatValue = (value) => {
        if (value === "" || value === undefined) {
            if (props.replaceEmpty === true) {
                return "N/A";
            } else {
                return "";
            }
        } else {
            if (props.displayDecimal === true) {
                return numberFormatter.format(value);
            } else if (props.displayDecimal === 1) {
                return oneDecimalFormatter.format(value);
            } else {
                return wholeNumberFormatter.format(value);
            }
        }
    };

    const displayValue = (value) => {
        let textColor = "text-default";
        let backgroundColor = "";
        if (props.colorText === true) {
            if (value > 0) textColor = "text-success";
            else if (value < 0) textColor = "text-danger";
        }

        if (colorBackground === true) {
            if (value > 0) backgroundColor = "bg-success";
            else if (value < 0) backgroundColor = "bg-red-100";
        }

        let displayClass = `${textColor} ${backgroundColor}`;
        if (colorBackground === true) {
            displayClass = `body-2 px-2 py-1 ${textColor} ${backgroundColor}`;
        }

        return (
            <span className={displayClass}>
                {props.displayArrow === true && (
                    <FontAwesomeIcon icon={value > 0 ? faArrowUp : faArrowDown} className="mr-2" />
                )}
                {props.showChange === true && value > 0 ? "+" : ""}
                {props.displayAbsoluteValue === true ? formatValue(Math.abs(value)) : formatValue(value)}
                {props.showExtra !== undefined ? props.showExtra : ""}
            </span>
        );
    };

    return <span>{displayValue(props.value)}</span>;
};
