import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { Container } from "react-bootstrap";
import { AuthContext } from "../contexts/AuthContext";

const AuthRoute = ({ component: Component, ...rest }) => {
    const { isLoggedIn, isAPIAuthenticated } = useContext(AuthContext);

    const renderPros = (routeProps) => {
        return (
            <Container fluid className="p-0 h-100 w-100" style={{ maxHeight: "100%", overflow: "auto" }}>
                <Component {...routeProps} />
            </Container>
        );
    };

    // If already verified(authenticated), show already verified page instead.
    if (isLoggedIn === true) {
        return (
            <Route
                {...rest}
                render={(routeProps) =>
                    isLoggedIn === true && isAPIAuthenticated === false ? (
                        renderPros(routeProps)
                    ) : (
                        <Redirect to="/verify-already-success" />
                    )
                }
            />
        );
    } else {
        return (
            <Route
                {...rest}
                render={(routeProps) =>
                    isLoggedIn === true && isAPIAuthenticated === false ? renderPros(routeProps) : <Redirect to="/login" />
                }
            />
        );
    }

    /*  we are spreading routeProps to be able to access this routeProps in the component. */
};

export default AuthRoute;
