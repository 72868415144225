import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { Container } from "react-bootstrap";
import { AuthContext } from "../contexts/AuthContext";

const NoAuthRoute = ({ component: Component, alternateComponent = <Redirect to="/" />, ...rest }) => {
    const { isAuthenticated, isLoggedIn } = useContext(AuthContext);

    const renderPros = (routeProps) => {
        return (
            <Container
                fluid
                className="p-0 h-100 w-100 bg-white"
                style={{ maxHeight: "100%", overflow: "auto" }}
            >
                <Component {...routeProps} />
            </Container>
        );
    };

    if (!isAuthenticated && !isLoggedIn) {
        return <Route {...rest} render={(routeProps) => renderPros(routeProps)} />;
    } else if (!isAuthenticated && isLoggedIn) {
        return <Route {...rest} render={(routeProps) => <Redirect to="/verify" />} />;
    } else {
        return <Route {...rest} render={(routeProps) => <Redirect to="/" />} />;
    }

    /*  we are spreading routeProps to be able to access this routeProps in the component. */
};

export default NoAuthRoute;
