import React from "react";
import { Container } from "react-bootstrap";

import HelpForm from "../../components/forms/HelpForm";
import CopyrightNotice from "../../components/helper/CopyrightNotice";

const Help = ({ history }) => {

    return (
        <Container fluid>
            <div className="d-flex align-items-center flex-column justify-content-center" style={{ height: "100vh" }}>
                <div className="text-center w-100">
                    <HelpForm />
                    <CopyrightNotice/>
                </div>
            </div>
        </Container>
    );
};

export default Help;
