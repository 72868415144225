import React, { useContext, useState, useEffect } from "react";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";

import { HeaderContext } from "../../../contexts/HeaderContext";

import {
    graph_yellow,
    graph_yellow_dark,
} from "../../../components/helper/ColorFormatter";
import WidgetSpinner from "../../../components/helper/WidgetSpinner";

const chartFont = {
    colors: "grey",
    fontSize: "12px",
    //fontFamily: 'Helvetica, Arial, sans-serif',
    //fontWeight: 400,
    cssClass: "apexcharts-xaxis-label",
};

function SNSPerformancePY(props) {
    const { dataLoading } = useContext(HeaderContext);

    const [snsChartData, setSNSChartData] = useState();
    const [snsChartLabels, setSNSChartLabels] = useState();
    const [widgetLoading, setWidgetLoading] = useState(true);

    const {
        headerTitle = "SNS Performance",
        widgetData = null,
        widgetDataLoading = null,
    } = props;

    useEffect(() => {
        const getData = async () => {
            if (widgetData !== null) {
                setWidgetLoading(true);
                setSNSChartData(widgetData[0]);
                setSNSChartLabels(widgetData[1]);
                setWidgetLoading(false);
            } else {
                setWidgetLoading(true);
            }
        };

        if (!dataLoading) {
            if (widgetDataLoading === null || widgetDataLoading === false) {
                getData();
            }
        }
    }, [
        dataLoading,
        widgetData,
        widgetDataLoading,
    ]);

    const renderChart = () => {
        if (snsChartData !== undefined && snsChartLabels !== undefined) {
            var widgetColors = [
                graph_yellow, // Previous
                graph_yellow_dark, // Current
            ];

            var series = [
                {
                    name: snsChartData[1]?.label, // Previous
                    data: snsChartData[1]?.data,
                    type: "line",
                },
                {
                    name: snsChartData[0]?.label, // Current
                    data: snsChartData[0]?.data,
                    type: "line",
                },
            ];
    
            var chartOptions = {
                chart: {
                    id: props.widgetID,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    dropShadow: {
                        enabled: false,
                    },
                    animations: {
                        enabled: false,
                    },
                    height: "100%",
                    type: "line",
                },
                legend: { show: true, position: "top", horizontalAlign: "right", floating: false, offsetY: 0, offsetX: 0 },
                colors: widgetColors,
                dataLabels: {
                    enabled: false,
                    enabledOnSeries: [0, 1],
                    style: {
                        fontSize: "13px",
                        fontFamily: "Nunito",
                    },
                    formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                        return (value !== null ? props.widgetFormatter(value) : '');
                    },
                    background: {
                        enabled: true,
                    },
                    offsetY: -10,
                },
                stroke: {
                    show: true,
                    curve: "smooth",
                    lineCap: "butt",
                    colors: widgetColors,
                    width: [3, 3],
                    dashArray: [5, 0]
                },
                grid: {
                    show: true,
                    borderColor: "#eaecec",
                    xaxis: { lines: { show: true } },
                    yaxis: { lines: { show: true } },
                    strokeDashArray: [5, 0],
                },
                markers: {
                    size: [1, 1],
                    colors: widgetColors,
                    strokeColors: widgetColors,
                    strokeWidth: [5, 5],
                    strokeOpacity: 1,
                    fillOpacity: 1,
                    hover: { sizeOffset: 1 },
                },
                tooltip: {
                    shared: true,
                    followCursor: true,
                    y: {
                        formatter: function (val, w) {
                            return props.widgetFormatter(val);
                        },
                    },
                },
                xaxis: {
                    categories: snsChartLabels,
                    type: 'datetime',
                    labels: {
                        style: chartFont,
                    },
                    tickPlacement: "between",
                },
                yaxis: {
                    title: {
                        text: "",
                    },
                    min: 0,
                    labels: {
                        formatter: (val) => props.widgetFormatter(val),
                        style: chartFont,
                    },
                },
            };
        }
        else {
            setWidgetLoading(true);
        }

        return snsChartData && snsChartLabels && (
            <ResponsiveContainer>
                <Chart options={chartOptions} series={series} type="line" />
            </ResponsiveContainer>
        );
    };

    return (
        <div className="card h-100" /*style={{ overflow: "auto" }}*/>
            <div className="card-header bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <h5>{headerTitle}</h5>
                    </div>
                </div>
            </div>

            <div className="card-body w-100">{widgetLoading ? <WidgetSpinner /> : renderChart()}</div>
        </div>
    );
}

export default SNSPerformancePY;
