import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import Select from "react-select";

import { BrandContext } from "../../contexts/BrandContext";
import { HeaderContext } from "../../contexts/HeaderContext";

const currencies = [
    { value: "USD", label: "USD" },
    { value: "CAD", label: "CAD" },
    { value: "EUR", label: "EUR" },
    { value: "GBP", label: "GBP" },
    // { value: "JPY", label: "JPY" },
    // { value: "MXN", label: "MXN" },
    // { value: "PLN", label: "PLN" },
    // { value: "SEK", label: "SEK" },
];
const CurrencyFilter = (props) => {
    //const { brand } = useContext(BrandContext);
    const { currency, setCurrency } = useContext(HeaderContext);

    //const [widgetLoading, setWidgetLoading] = useState(true);

    const onCurrencyChange = (value) => {
        setCurrency(value);
    };

    return (
        <Select
            className="text-dark w-100 rounded-0 select-border-0"
            placeholder="Select Currency"
            value={{ value: currency, label: currency }}
            options={currencies}
            name="currencySelect"
            getOptionLabel={(option) => `${option.label}`}
            getOptionValue={(option) => option.value}
            onChange={(option) => onCurrencyChange(option.value)}
            isMulti={false}
            allowSelectAll={false}
            isSearchable={true}
            isLoading={false}
            noOptionsMessage={(inputValue) => "No Currencies Available"}
        />
    );
};

export default CurrencyFilter;
