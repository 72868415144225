import React, { useContext, useEffect } from "react";
import { Form, Button, Container } from "react-bootstrap";

import { useAuth0 } from "@auth0/auth0-react";
import { AuthContext } from "../../contexts/AuthContext";

import logo from "../../assets/logo.svg";
import "./signin.css";

import CopyrightNotice from "../../components/helper/CopyrightNotice";

const VerifySuccess = ({ history }) => {

    const { loginWithRedirect } = useAuth0();
    const { 
        token
        , isLoading, loading: isAuthLoading
        , isLoggedIn
        , isAPIAuthenticated, apiUser
        , user: auth0User 
    } = useContext(AuthContext);


    useEffect(() => {
        onSuccess()
    }, [isLoading, isAuthLoading, isLoggedIn, isAPIAuthenticated, auth0User])

    const onSuccess = async () => {
        if (!isLoading && !isAuthLoading) {
            if (isLoggedIn && isAPIAuthenticated) {
                if (!auth0User) {
                    loginWithRedirect({authorizationParams: {
                        login_hint: apiUser?.email ?? '',
                        screen_hint: "signup",
                    }})
                }
                else {
                    history.replace("/verify-already-success");
                }
            }
            else if (isLoggedIn && !isAPIAuthenticated) {
                history.replace("/verify");
            }
            else {
                loginWithRedirect({authorizationParams: {
                    screen_hint: "signup",
                }})
            }
        }
    };

    const renderForm = () => {
        const onSubmit = () => {
            onSuccess();
        };

        return (
            <div className="d-flex align-items-center flex-column justify-content-center" style={{ height: "100vh" }}>
                <div className="text-center">
                    <Form className="form-signin" noValidate onSubmit={onSubmit}>
                        <img src={logo} alt="Fortress" width="300" height="130"></img>
                        <hr />
                        <h2 className="mb-3">You're Verified!</h2>
                        {isAPIAuthenticated ? (
                            <div>
                                <p className="mb-3">You've successfully verified your email.</p>

                                <Button
                                    className="btn btn-lg btn-primary btn-block py-2"
                                    variant="primary"
                                    type="submit"
                                    onClick={onSubmit}
                                >
                                    <p className="mb-0">Continue</p>
                                </Button>
                            </div>
                        ) : (
                            <div>
                                <p className="mb-1">You've successfully verified your email.</p>
                                <p className="mb-3 body-1-bold">Please create an account with the same email</p>

                                <Button
                                    className="btn btn-lg btn-primary btn-block py-2"
                                    variant="primary"
                                    type="submit"
                                    onClick={onSubmit}
                                >
                                    <p className="mb-0">Continue to Register</p>
                                </Button>
                            </div>
                        )}
                    </Form>
                </div>

                <CopyrightNotice/>
            </div>
        );
    };

    return <Container fluid>{renderForm()}</Container>;
};

export default VerifySuccess;
