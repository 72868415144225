import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";

import { HeaderContext } from "../../contexts/HeaderContext";

import useIsMountedRef from "../../components/helper/isMountedRef";

import CLVUSHeatmap from "../../components/dataRender/dashboardCharts/CLVUSHeatmap";


const CustomerGeography = (props) => {
    // console.log("CustomerGeography", props);
    const { setPageTitle, setPageTimeframe, setPageMarketplace } = useContext(HeaderContext);

    const isMountedRef = useIsMountedRef();

    useEffect(() => {
        setPageTitle("Customer Geography");
        setPageMarketplace(false);
        setPageTimeframe('snapshot');
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    });

    return (
        <main className="d-flex justify-content-start align-items-top">
            <Col>
                <Row className="mt-4">
                    <Col>
                        <CLVUSHeatmap
                            widgetDataLoading={(isMountedRef.current)}
                            headerTitle={<span>Heatmap Brand Sales &mdash; United States</span>}
                            headerSubtitle={"Sales per State / Zip Codes"}
                        />
                    </Col>
                </Row>
            </Col>
        </main>
    );
};

export default CustomerGeography;
