import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

export const getNumberFormatter = (format, brand, currency) => {
    let formatter = intlNumberFormatter(brand.currencyLocale ?? 'en-US', true);

    switch(format) {
        case 'number':
            formatter = intlNumberFormatter(brand.currencyLocale ?? 'en-US', false);
            break;
        case 'decimal':
            formatter = intlNumberFormatter(brand.currencyLocale ?? 'en-US', true);
            break;
        case 'float':
            formatter = intlNumberFormatter(brand.currencyLocale ?? 'en-US', true, 4);
            break;
        case 'currency':
            formatter = intlCurrencyFormatter(currency, brand.currencyLocale ?? 'en-US', false);
            break;
        case 'percent':
            formatter = intlPercentFormatter(brand.currencyLocale ?? 'en-US', true);
            break;
        case 'percent_whole':
            formatter = intlPercentFormatter(brand.currencyLocale ?? 'en-US', false);
            break;
    }

    return formatter
}

export const percentFormatter = new Intl.NumberFormat("en-US", { style: "percent", minimumFractionDigits: 2 });

export const wholeNumberFormatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});
export const numberFormatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const oneDecimalFormatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
});

const displayLineFormatColor = (formatter, value, extra = undefined, show_change = false, absolute_value = true) => {
    return (
        <span
            className={`body-2 px-2 py-1 ${
                value < 0 ? "text-danger bg-red-100" : value > 0 ? "text-success bg-success" : "text-default"
            }`}
        >
            {value > 0 ? (
                <FontAwesomeIcon icon={faArrowUp} className="mr-2" />
            ) : (
                <FontAwesomeIcon icon={faArrowDown} className="mr-2" />
            )}
            {show_change === true && value > 0 ? "+" : ""}
            {absolute_value === true ? formatter.format(Math.abs(value)) : formatter.format(value)}
            {extra !== undefined ? extra : ""}
        </span>
    );
};

export const IntFormatter = (value) => {
    return wholeNumberFormatter.format(value);
};

export const lineIntFormatter = (value) => {
    if (value === "" || value === undefined) return "N/A";

    return wholeNumberFormatter.format(value);
};

export const lineIntColorFormatter = (value, extra = undefined, show_change = false, absolute_value = true) => {
    if (value === "" || value === undefined) return "N/A";
    return displayLineFormatColor(wholeNumberFormatter, value, extra, show_change, absolute_value);
};

export const lineFloatFormatter = (value) => {
    if (value === "" || value === undefined) return "N/A";
    return numberFormatter.format(value);
};

export const roasFormatter = (value) => {
    if (value === "" || value === undefined) return "N/A";
    return numberFormatter.format(value) + "x";
};

export const lineFloatColorFormatter = (value) => {
    if (value === "" || value === undefined) return "N/A";
    return (
        <span className={value < 0 ? "text-danger" : value > 0 ? "text-success" : "text-default"}>
            {numberFormatter.format(value)}
        </span>
    );
};

export const lineOneDecimalFormatter = (value, extra = undefined) => {
    if (value === "" || value === undefined) return "N/A";
    return `${oneDecimalFormatter.format(value)}${extra !== undefined ? extra : ""}`;
};

export const linePercentFormatter = (value) => {
    if (value === "" || value === undefined) return "N/A";
    return percentFormatter.format(value);
};

export const linePercentColorFormatter = (value, extra = undefined, show_change = false, absolute_value = false) => {
    if (value === "" || value === undefined) return "N/A";
    return displayLineFormatColor(percentFormatter, value, extra, show_change, absolute_value);
};

export const tablePercentFormatter = (cell, row, rowIndex) => {
    return linePercentFormatter(cell);
};

export const tablePercentColorFormatter = (cell, row, rowIndex) => {
    if (cell === "" || cell === undefined) return "N/A";
    return (
        <div className={cell < 0 ? "text-danger" : cell > 0 ? "text-success" : "text-default"}>
            {percentFormatter.format(cell)}
        </div>
    );
};

// Different threshold & color than percentColor
export const tablePercentBBXColorFormatter = (cell, row, rowIndex) => {
    if (cell === "") return "N/A";
    return (
        <div className={cell >= 0.66 ? "text-enalytics-blue" : cell >= 0.33 ? "text-warning" : "text-danger"}>
            {percentFormatter.format(cell)}
        </div>
    );
};

export const tableRatioFormatter = (cell, row, rowIndex) => {
    return roasFormatter(cell);
};

export const tableNumberFormatter = (cell, row, rowIndex) => {
    return lineFloatFormatter(cell);
};

export const tableIntFormatter = (cell, row, rowIndex) => {
    return lineIntFormatter(cell);
};

export const intlPercentFormatter = (locale = "en-US", decimal = false) => {
    if (decimal === true) {
        return new Intl.NumberFormat(locale, { style: "percent", minimumFractionDigits: 2 });
    }
    else {
        return new Intl.NumberFormat(locale, { style: "percent", minimumFractionDigits: 0 });
    }
};

export const intlNumberFormatter = (locale = "en-US", decimal = false, maxDigits = 2) => {
    if (decimal === true) {
        return new Intl.NumberFormat(locale, {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: maxDigits,
        })
    }
    else {
        return new Intl.NumberFormat(locale, {
            style: "decimal",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
    }
};

// Currency formatters
export const dollarFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});
export const dollarDecimalFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const intlCurrencyFormatter = (currency, locale = "en-US", decimal = false) => {
    if (decimal === true) {
        return new Intl.NumberFormat(locale, {
            style: "currency",
            currency: currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    } else {
        return new Intl.NumberFormat(locale, {
            style: "currency",
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
    }
};

const getCurrencyFormatter = (currency = "USD", locale = "en-US", decimal = false) => {
    if (decimal === true) {
        return intlCurrencyFormatter(currency, locale, decimal);
    } else {
        return intlCurrencyFormatter(currency, locale, decimal);
    }
};

export const DollarFormatter = (value, currency = "USD", locale = "en-US") => {
    return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(value);
};

export const lineDollarFormatter = (value, currency = "USD", locale = "en-US") => {
    if (value === "" || value === undefined) return "N/A";
    return getCurrencyFormatter(currency, locale, /*decimal=*/ false).format(value);
};

export const lineDollarColorFormatter = (value, currency = "USD", locale = "en-US") => {
    if (value === "" || value === undefined) return "N/A";
    if (value > 0)
        return (
            <span className="text-success">{getCurrencyFormatter(currency, locale, /*decimal=*/ false).format(value)}</span>
        );
    else if (value < 0)
        return (
            <span className="text-danger">{getCurrencyFormatter(currency, locale, /*decimal=*/ false).format(value)}</span>
        );
    else if (value === 0)
        return (
            <span className="text-default">{getCurrencyFormatter(currency, locale, /*decimal=*/ false).format(value)}</span>
        );
    else return getCurrencyFormatter(currency, locale, /*decimal=*/ false).format(value);
};

export const DollarDecimalFormatter = (value, currency = "USD", locale = "en-US") => {
    return getCurrencyFormatter(currency, locale, /*decimal=*/ true).format(value);
};

export const lineDollarDecimalFormatter = (value, currency = "USD", locale = "en-US") => {
    if (value === "" || value === undefined) return "N/A";
    return getCurrencyFormatter(currency, locale, /*decimal=*/ true).format(value);
};

export const tableDollarFormatter = (cell, row, rowIndex) => {
    return lineDollarFormatter(cell);
};

export const tableColorDollarFormatter = (cell, row, rowIndex) => {
    return lineDollarColorFormatter(cell);
};
