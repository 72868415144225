import React from "react";
import { Spinner } from "react-bootstrap";

const WidgetSpinner = (props) => {
    return (
        <div style={{ height: "100%" }} className="d-flex justify-content-center align-items-center">
            <div style={{ width: "100%", height: "100%" }} className="d-flex justify-content-center align-items-center p-4">
                <Spinner
                    as="span"
                    animation="grow"
                    role="status"
                    aria-hidden="true"
                    // animation="border"
                ></Spinner>
            </div>
        </div>
    );
};

export default WidgetSpinner;
