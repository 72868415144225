import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Card, ProgressBar } from "react-bootstrap";
import SimpleBar from 'simplebar-react';
import moment from "moment";

import { topSNS } from "../../../api/brandData";

import { AuthContext } from "../../../contexts/AuthContext";
import { BrandContext } from "../../../contexts/BrandContext";
import { HeaderContext } from "../../../contexts/HeaderContext";
import { ToastContext } from "../../../contexts/ToastContext";

import WidgetSpinner from "../../../components/helper/WidgetSpinner";
import {
    yesterday,
    YTD_s,
    last_MTD_s,
    last_MTD_e,
} from "../../../components/helper/DateVars";

function TopSNS(props) {
    const { token } = useContext(AuthContext);
    const { selectedDefaultDates, dataLoading, marketplace } = useContext(HeaderContext);

    const [data, setData] = useState([]);
    const [widgetLoading, setWidgetLoading] = useState(true);
    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);

    const [widgetStartDate, setWidgetStartDate] = useState(selectedDefaultDates?.tf_s);
    const [widgetEndDate, setWidgetEndDate] = useState(selectedDefaultDates?.tf_e);
    const [widgetMetric, setWidgetMetric] = useState({
        sales: true,
        units: false,
        orders: false,
    });
    const [widgetTitle, setWidgetTitle] = useState(props.headerTitle ?? 'Top SNS');
    const [widgetSubTitle, setWidgetSubTitle] = useState(props.headerSubtitle ?? '');

    const [initialized, setInitialized] = useState(false);

    const {
        showConfigButton = true,
        headerTitle = widgetTitle,
        headerSubtitle = widgetSubTitle,
        widgetData = null,
        widgetDataLoading = null,
    } = props;

    useEffect(() => {
        if (!dataLoading) {
            if (props.timeframe !== undefined && props.timeframe !== "") {

                if (props.timeframe === "MTD") {
                    setWidgetTitle("Top SNS (Last Month)");

                    setWidgetStartDate(last_MTD_s);
                    setWidgetEndDate(last_MTD_e);

                } else if (props.timeframe === "YTD") {
                    setWidgetTitle("Top SNS (YTD)");

                    setWidgetStartDate(YTD_s);
                    setWidgetEndDate(yesterday);
                }
            }
            else {
                setWidgetStartDate(selectedDefaultDates.tf_s);
                setWidgetEndDate(selectedDefaultDates.tf_e);
            }

            // If all are set to false, set sales = true; Keep atleast one
            if (widgetMetric.sales !== true && widgetMetric.units !== true && widgetMetric.orders !== true) {
                setWidgetMetric({ ...widgetMetric, sales: true });
            }

            setInitialized(true);
        }
    }, [setInitialized, props.timeframe, dataLoading]);

    useEffect(() => {
        const getData = async () => {
            setWidgetLoading(true);
            try {
                const res = await topSNS(token, {
                    brandid: brand.brandId,
                    tf_s: moment(widgetStartDate).format('YYYY-MM-DD'),
                    tf_e: moment(widgetEndDate).format('YYYY-MM-DD'),
                    top: 10,
                    timeframe: selectedDefaultDates.timeframe,
                    marketplace: marketplace,
                });

                setData(res.data);
                setWidgetLoading(false);
            } catch (err) {
                setWidgetLoading(false);
                toast(err.message);
            }
        };

        if (initialized) {
            getData();
        }
    }, [token, 
        brand.brandId, 
        marketplace,
        toast, 
        dataLoading, 
        selectedDefaultDates, 
        initialized, 
        widgetStartDate, 
        widgetEndDate,
        widgetData,
        widgetDataLoading,
    ]);

    const renderChart = () => {
        return (
            <Col>
                { data.data && data.data.map( (item, index) =>
                    <Row key={index}>
                        <Col sm={12}>
                            <label>{item.name}</label>
                        </Col>
                        <Col sm={12}>
                            <ProgressBar
                                now={item.activesubscriptions}
                                min={0}
                                max={data?.max_subscriptions}
                                className="progress-bar-sns"
                            />
                            <span className="position-absolute body-2 text-white" style={{top: 0, left: '30px'}}>{item.activesubscriptions}</span>
                        </Col>
                    </Row>
                )}
            </Col>
        );
    };

    return (
        <Card className="h-100" /*style={{ overflow: "auto" }}*/>
            <Card.Header className="bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <h5>{headerTitle}</h5>
                        {headerSubtitle !== '' ? <p className="body-1 text-secondary mb-0">{headerSubtitle}</p> : ''}
                    </div>
                    <div className="ml-auto ">
                        {showConfigButton === true ? (
                            <button
                                type="button"
                                className="btn btn-primary btn-sm "
                                onClick={() => {
                                    //TODO: Add Configuration Modal
                                }}
                            >
                                Config
                            </button>
                        ) : null}
                    </div>
                </div>
            </Card.Header>
            
            { widgetLoading ?  (
                    <Card.Body>
                        <WidgetSpinner />
                    </Card.Body>
                ) : (
                    <SimpleBar 
                        forceVisible="y"
                        autoHide={false}
                        className="sidebarjs-black mt-2"
                        style={{ 'overflow': 'hidden' }}
                    >
                        <Card.Body className="pt-0 pb-0">
                            { renderChart() }
                        </Card.Body>
                    </SimpleBar>
                )
            }
        </Card>
    );
}

export default TopSNS;
