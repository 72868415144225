import React, { createContext, useState, useEffect, useContext } from "react";
import { loginAuth0, getUser, logoutAPI } from "../api/user";

import { useAuth0 } from "@auth0/auth0-react";
import { ToastContext } from "./ToastContext";
import localforage from "localforage";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
    const { logout, user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    
    const [loading, setLoading] = useState(true);
    const [tokenInit, setTokenInit] = useState(false);
    const [apiUser, setAPIUser] = useState(null);
    const [auth0AccessToken, setAuth0AccessToken] = useState(null);
    const [token, setToken] = useState("");

    const [auth0Complete, setAuth0Complete] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAPIAuthenticated, setIsAPIAuthenticated] = useState(false);

    const defaultVisibility = {
        paid: true,
        sns: true,
        traffic: true,
        ntb: true,
    };

    const [visibleMetrics, setVisibleMetrics] = useState(defaultVisibility);

    const { toast } = useContext(ToastContext);

    const setAuthToken = (token) => {
        setToken(token);
    };

    const clearUserSessionCookies = () => {
        localforage.removeItem("fortress-last-selected-brand");
        localforage.removeItem("fortress-last-selected-dates");
        localforage.removeItem("fortress-last-selected-sidenav");
    };

    // const isValidPortalUser = (auth0User) => {
    //     const getClaimUserRoles = (auth0User) => {
    //         const claimUserRoles = process.env.REACT_APP_AUTH0_AUDIENCE + 'auth0/user/roles';
    //         const roles = auth0User?.[claimUserRoles] ?? [];
    
    //         return roles;
    //     }
    //     const roles = getClaimUserRoles(auth0User);
    //     return (roles.includes('ftb_user') ? true : false);
    // }

    const getForageToken = async () => {
        let defaultToken = await localforage.getItem("fortress-jwt-token");
        setToken(defaultToken);
        setTokenInit(true);
    }

    useEffect(() => {
        const init = async () => {
            getForageToken()
        };
        init();
    }, []);


    useEffect(() => {
        if (user && isAuthenticated && !isLoading) {
            setAuth0Complete(true);
        }
    }, [user, isAuthenticated, isLoading]);

    // This hook is responsible for getting the auth0AccessToken if it doesn't exist
    useEffect(() => {
        if (auth0Complete && isAuthenticated && user && !auth0AccessToken) {
            const getAccessToken = async () => {
                try {
                    const accessToken = await getAccessTokenSilently({
                            authorizationParams: {
                            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                            scope: "read:current_user read:users update:users",
                        },
                    });
            
                    setAuth0AccessToken(accessToken);

                    const loginWithAuth0 = async () => {
                        try {
                            const res = await loginAuth0(user?.email, accessToken);

                            if (res.status === 200 && res?.data?.jwt !== undefined) {
                                
                                const userToken = res.data.jwt;
                    
                                clearUserSessionCookies();
                    
                                setAuthToken(userToken);
                            } else if (checkMaintenanceMode(res)) {
                                setTimeout(() => setMaintenanceMode(), 2000);
                            } else {
                                
                                toast("Unauthorized!");
                                setTimeout(() => setLogOut(), 2000);

                            }
                        } catch (err) {
                            
                            if (checkMaintenanceMode(err)) {
                                setTimeout(() => setMaintenanceMode(), 2000);
                            }
                            else {
                                toast("Unauthorized!");
                                setTimeout(() => setLogOut(), 2000);
                            }

                        }
                    };

                    loginWithAuth0();
                } catch (e) {
                    // Handle error
                }
            };
        
            getAccessToken();
        }
    }, [auth0Complete, isAuthenticated, user, auth0AccessToken, getAccessTokenSilently]);

    // This hook is responsible for getting the user data if the local token exists
    useEffect(() => {
        const authAPIToken = async () => {
            if (tokenInit) {
                localforage.setItem("fortress-jwt-token", token);
    
                if (token) {
                    const getUserData = async () => {
                        try {
                            const { data } = await getUser(token);

                            data.defaultVisibility = defaultVisibility;

                            setAPIUser(data);
                            setVisibleMetrics(data.defaultVisibility);
                            setLoading(false);
                        } catch (err) {
                            toast("An error has occurred! Your session has expired.");
                            setLogOut();
                        }
                    };
                    
                    setLoading(true);
                    getUserData();
                }
                else {
                    setLoading(false);
                }
            }
            else {
                getForageToken()
            }
        }

        authAPIToken()
    }, [token, toast, setAPIUser, tokenInit]);
  
    useEffect(() => {
        const loggedIn = apiUser && apiUser.inactive !== 1 ? true : false;
        const isAuth = apiUser && apiUser.inactive !== 1 && apiUser.email_verified_at !== null ? true : false;

        setIsAPIAuthenticated(isAuth);
        setIsLoggedIn(loggedIn);
    }, [apiUser, setIsAPIAuthenticated, setIsLoggedIn]);

    const setLogOut = async () => {
        setLoading(true);
        localforage.clear();
        setAuth0Complete(false);
        setToken("");
        setAPIUser(null);

        try {
            const tokenHolder = token;
            await logoutAPI(tokenHolder);
        } catch (err) {
        }
        
        // console.log('logged out from api, now auth0');
        logout({ logoutParams: { returnTo: window.location.origin+'/#/login' } })
    };

    const setMaintenanceMode = async () => {
        toast("Service is currently in maintenance mode. Please try again later.");

        setLoading(true);
        localforage.clear();
        setAuth0Complete(false);
        setToken("");
        setAPIUser(null);

        try {
            const tokenHolder = token;
            await logoutAPI(tokenHolder);
        } catch (err) {
        }
        
        // console.log('logged out from api, now auth0');
        logout({ logoutParams: { returnTo: window.location.origin+'/#/418' } })
    };

    const checkMaintenanceMode = async (response, withRedirect = false) => {
        if ((response?.status !== undefined && response?.status === 418)
            || response == 'Error: Request failed with status code 418'
        ) {
            if (withRedirect) {
                setMaintenanceMode();
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    }

    return (
        <AuthContext.Provider
            value={{
                // Service
                checkMaintenanceMode,
                setMaintenanceMode,
                // Auth0
                auth0AccessToken,
                user,
                isAuthenticated,
                isLoading,
                auth0Complete,
                setAuth0Complete,
                // API
                token,
                setAuthToken,
                isAPIAuthenticated,
                isLoggedIn,
                apiUser,
                loading,
                setLogOut,
                clearUserSessionCookies,
                // Page
                visibleMetrics,
                setVisibleMetrics,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
