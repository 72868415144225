import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { Container } from "react-bootstrap";
import { AuthContext } from "../contexts/AuthContext";

const AuthRoute = ({ component: Component, ...rest }) => {
    const { isAPIAuthenticated, isLoggedIn } = useContext(AuthContext);

    const renderPros = (routeProps) => {
        return (
            <Container fluid className="p-0 h-100 w-100" style={{ maxHeight: "100%", overflow: "auto" }}>
                <Component {...routeProps} />
            </Container>
        );
    };

    return isLoggedIn ? (
        <Route
            {...rest}
            render={(routeProps) =>
                isLoggedIn === true && isAPIAuthenticated === true ? (
                    renderPros(routeProps)
                ) : isLoggedIn === true ? (
                    <Redirect to="/verify" />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    ) : null;
    /*  we are spreading routeProps to be able to access this routeProps in the component. */
};

export default AuthRoute;
