export const defaultGraphLabelStyle = {
    colors: "gray",
    fontSize: "12px",
    //fontFamily: 'Helvetica, Arial, sans-serif',
    //fontWeight: 400,
    cssClass: "apexcharts-yaxis-label",
}

// Graph Colors
export const graph_green =         '#00965A'; // enalytics-green (Sales)
export const graph_green_dark =    '#54A50A'; // front-row-green (Previous Sales)
export const graph_red =           '#010101'; // enalytics-red (Spend)

export const graph_yellow =        '#E5CB00'; // enalytics-yellow-lines (Previous SNS)
export const graph_yellow_dark =   '#D0B800'; // enalytics-yellow-dark (SNS)

export const graph_blue =          '#5699BF'; // enalytics-blue (Previous Paid)
export const graph_blue_dark =     '#0D5780'; // enalytics-blue-dark (Paid)
export const graph_blue_light =    '#B2CEDE'; // enalytics-blue-light (BBX)

export const graph_gray =          '#B3B3B3'; // enalytics-gray (Organic)
export const graph_gray_light =    '#E6E6E6'; // enalytics-gray-light (??)
export const graph_gray_dark =     '#939598'; // dark-gray/cool-gray (Units)

export const graph_magenta =          '#B3B3B3'; // enalytics-magenta (Ratios)
export const graph_magenta_dark =     '#939598'; // enalytics-magenta-dark (Previous Ratios)
export const graph_magenta_light =    '#E6E6E6'; // enalytics-magenta-light (??)

// Enalytics Colors NOT USED in GRAPH
// $enalytics-yellow:      #FADF10 !default;

const primary_color = [2, 72, 141]; // Dark Blue
const secondary_color = [253, 205, 16]; // Yellow
const tertiary_color = [98, 207, 124]; // Green
const quaternary_color = [8, 120, 255]; // Light Blue

function getColorString(rgb_array, transparency=1)
{
    return `rgba(${rgb_array[0]}, ${rgb_array[1]}, ${rgb_array[2]}, ${transparency})`;
}

function getRandomRGB()
{
    var rand = Math.random; 
    const max = 255;
    return [Math.round(rand()*max), Math.round(rand()*max), Math.round(rand()*max)];
}

function getLineOptions(value) {
    switch (value) {
        case 'dash':
            return {
                'fill': false,
                'borderDash': [10, 15],
                'pointBorderWidth': 0,
                'pointRadius': 0,
                'pointHoverRadius': 0,
                'pointHoverBorderWidth': 0,
                'pointHitRadius': 5,
            };
            break;
        case 'solid':
            return {
                'fill': false,
                'pointBorderWidth': 0,
                'pointRadius': 0,
                'pointHoverRadius': 0,
                'pointHoverBorderWidth': 0,
                'pointHitRadius': 5,
            };
        case 'dots':
        case '':
        default:
            return {
                'fill': false, // Fill Area Under Line
                'pointBorderWidth': 5,
                'pointRadius': 5,
                'pointHoverRadius': 7,
                'pointHoverBorderWidth': 2,
                'pointHitRadius': 5, // For events/tooltip
            };
            break;
    }
}

export function getRandomColor(transparency=1)
{
    const color = getRandomRGB();
    return getColorString(color, transparency);
}

export function getPrimaryColor(transparency=1)
{
    return getColorString(primary_color, transparency);
}

export function getSecondaryColor(transparency=1)
{
    return getColorString(secondary_color, transparency);
}

export function getTertiaryColor(transparency=1)
{
    return getColorString(tertiary_color, transparency);
}

export function getQuaternaryColor(transparency=1)
{
    return getColorString(quaternary_color, transparency);
}


export function getChartJSLineOptions(line='', color='')
{
    var options = getLineOptions(line);

    var color_rgb = [];

    switch (color) {
        case 'primary':
            color_rgb = primary_color;
            break;

        case 'secondary':
            color_rgb = secondary_color;
            break;

        case 'tertiary':
            color_rgb = tertiary_color;
            break;

        case 'quaternary':
            color_rgb = quaternary_color;
            break;

        case 'random':
        case '':
        default:
            color_rgb = getRandomRGB();
            break;
    }

    const lineOptions =  {
        'borderColor': getColorString(color_rgb, 0.95),
        'pointBackgroundColor': getColorString(color_rgb, 0.8),
        'pointBorderColor': getColorString(color_rgb),
        'pointHoverBackgroundColor': getColorString(color_rgb),
        'pointHoverBorderColor': getColorString(color_rgb),
        ...options
    };

    return lineOptions;
}