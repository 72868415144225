import React, { createContext, useState, useEffect, useContext } from "react";
import { AuthContext } from "./AuthContext";
import { getAuthorizedBrands } from "../api/user";

import localforage from "localforage";

export const BrandContext = createContext({});

const BrandProvider = ({ children }) => {
    const [authorizedBrands, setAuthorizedBrands] = useState([]);
    const { token, loading: authLoading, isAPIAuthenticated, auth0Complete } = useContext(AuthContext);
    const [init, setInit] = useState(false);
    const [authorizedBrandsLoaded, setAuthorizedBrandsLoaded] = useState(false);
    const [brand, setBrand] = useState({});
    const [currency, setCurrency] = useState("");

    //Initialize list of authorized brands
    useEffect(() => {
        // console.log(!authLoading, token, isAPIAuthenticated, (!authLoading && token && isAPIAuthenticated));
        if (!authLoading && token && isAPIAuthenticated) {
            const getBrands = async () => {
                try {
                    setAuthorizedBrandsLoaded(false);
                    const { data } = await getAuthorizedBrands(token);
                    setAuthorizedBrands(data);
                    setAuthorizedBrandsLoaded(true);
                } catch (err) {
                    setAuthorizedBrands([]);
                    setAuthorizedBrandsLoaded(true);
                    console.log(err);
                }
            };

            getBrands();
        }
    }, [token, authLoading, isAPIAuthenticated]);

    //Then initialize brand once authorizedBrands is loaded.
    useEffect(() => {
        if (authorizedBrandsLoaded) {
            if (init === false) {
                const initBrand = async () => {
                    let localBrand = await localforage.getItem("fortress-last-selected-brand");
                    if (!localBrand) {
                        localBrand = {};
                    }
                    let localCurrency = await localforage.getItem("fortress-last-selected-currency");
                    setCurrency(localCurrency);

                    //If localBrand exists, use it, if not, there is no selected brand.
                    if (localBrand?.brandId) {
                        const brandidInt = parseInt(localBrand.brandId);
                        const useBrand = authorizedBrands.filter((value) => {
                            return value.brandId === brandidInt;
                        })[0];
                        setBrand(useBrand);
                    } else {
                        setBrand("");
                    }

                    setInit(true);
                };

                initBrand();
            } else {
                if (brand?.brandId && authorizedBrands.length > 0) {
                    const brandIdInt = parseInt(brand.brandId);
                    setBrand(
                        authorizedBrands.filter((value) => {
                            return value.brandId === brandIdInt;
                        })[0]
                    );
                }
            }
        }
        else if (!authLoading && !token && !isAPIAuthenticated) {
            setInit(true);
        }
    }, [authorizedBrandsLoaded, init, authorizedBrands, brand, authLoading, token, isAPIAuthenticated, auth0Complete]);

    const hasSelectedBrand = () => {
        if (brand?.brandId) return true;
        return false;
    };

    const setSelectedBrand = (brandid) => {
        const brandidInt = parseInt(brandid);
        setBrand(
            authorizedBrands.filter((value) => {
                return value.brandId === brandidInt;
            })[0]
        );
    };

    useEffect(() => {
        if (init) {
            const save = async () => {
                await localforage.setItem("fortress-last-selected-brand", brand);
                if (!currency && brand?.currency) {
                    setCurrency(brand?.currency);
                }
            };
            save();
        }
    }, [brand, init]);

    useEffect(() => {
        if (init) {
            const save = async () => {
                await localforage.setItem("fortress-last-selected-currency", currency);
            };
            save();
        }
    }, [currency, init]);

    return (
        <BrandContext.Provider value={{ brand, setSelectedBrand, init, authorizedBrands, hasSelectedBrand }}>
            {children}
        </BrandContext.Provider>
    );
};

export default BrandProvider;
