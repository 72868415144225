import React, { useEffect, useState, useContext } from "react";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";

import { BrandContext } from "../../contexts/BrandContext";
import { HeaderContext } from "../../contexts/HeaderContext";
import WidgetSpinner from "../../components/helper/WidgetSpinner";
import { metric_property } from "./metricsVars";

const QuantitativeApexChart = (props) => {
    const { brand } = useContext(BrandContext);
    const { currency } = useContext(HeaderContext);
    const [options, setOptions] = useState();
    const [series, setSeries] = useState();
    const [selectedMetrics, setSelectedMetrics] = useState(props.selectedMetrics);
    const [enabledDataLabels, setEnabledDataLabels] = useState(props.enabledDataLabels);
    const [loading, setLoading] = useState(true);
    const [noData, setNoData] = useState(false);

    const percentFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
        style: "percent",
        minimumFractionDigits: 2,
    });
    const dollarFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
        style: "currency",
        currency: currency,
    });
    const currencyFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
        style: "currency",
        currency: currency,
    });
    const wholeNumberFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    const numberFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });

    const formatMetricValue = (metric, value) => {
        if (metric_property[metric]?.formatter === "dollarFormatter") {
            return dollarFormatter.format(value);
        } else if (metric_property[metric]?.formatter === "intlCurrencyFormatter") {
            return currencyFormatter.format(value);
        } else if (metric_property[metric]?.formatter === "percentFormatter") {
            return percentFormatter.format(value);
        } else if (metric_property[metric]?.formatter === "numberFormatter") {
            return numberFormatter.format(value);
        } else if (metric_property[metric]?.formatter === "ratioFormatter") {
            return `${numberFormatter.format(value)}x`;
        } else if (metric_property[metric]?.formatter === "intFormatter") {
            return wholeNumberFormatter.format(value);
        }

        return "";
    };

    const defaultAxis = () => {
        return {
            currency: {
                show: false,
                // showAlways: true,
                seriesName: "currency",
                opposite: false,
                min: 0,
                // forceNiceScale: false,
                // decimalsInFloat: undefined,
                labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    // style: {
                    //     colors: [],
                    //     fontSize: '12px',
                    //     fontFamily: 'Helvetica, Arial, sans-serif',
                    //     fontWeight: 400,
                    //     cssClass: 'apexcharts-yaxis-label',
                    // },
                    formatter: (value) => {
                        return dollarFormatter.format(value);
                    },
                },
                title: {
                    // text: 'Sales',
                },
                crosshairs: {
                    show: false,
                },
                tooltip: {
                    enabled: false,
                },
            },
            number: {
                show: false,
                seriesName: "number",
                opposite: true,
                min: 0,
                labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    formatter: (value) => {
                        return wholeNumberFormatter.format(value);
                    },
                },
                title: {
                    text: "Units/Clicks/Impressions",
                    style: {
                        colors: [],
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                        cssClass: "apexcharts-yaxis-label",
                    },
                },
                crosshairs: {
                    show: false,
                },
                tooltip: {
                    enabled: false,
                },
            },
            ratio: {
                show: false,
                seriesName: "ratio",
                opposite: true,
                min: 0,
                labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    formatter: (value) => {
                        return `${numberFormatter.format(value)}x`;
                    },
                },
                title: {
                    // text: 'Ratio',
                },
                crosshairs: {
                    show: false,
                },
                tooltip: {
                    enabled: false,
                },
            },
            percent: {
                show: false,
                seriesName: "percent",
                opposite: true,
                min: 0,
                max: 1,
                labels: {
                    formatter: (value) => {
                        return percentFormatter.format(value);
                    },
                },
                title: {
                    // text: 'Percent',
                },
                crosshairs: {
                    show: false,
                },
                tooltip: {
                    enabled: false,
                },
            },
        };
    };

    useEffect(() => {
        // console.log(props);
        if (
            props.selectedMetrics !== undefined &&
            props.selectedMetrics.length > 0 &&
            props.enabledDataLabels !== undefined &&
            props.data !== undefined &&
            props.labels !== undefined
        ) {
            let allYAxis = defaultAxis();
            let series = [];

            selectedMetrics.forEach((value, index) => {
                if (props.data[value] !== undefined && metric_property[value] !== undefined) {
                    let lineOptions = {
                        name: metric_property[value].label,
                        key: value,
                        data: props.data[value],
                        type: "line",
                    };

                    // console.log('new line', lineOptions, metric_property[value].axis);
                    series.push(lineOptions);
                    allYAxis[metric_property[value].axis].show = true;
                }
            });

            let activeAxis = [];
            Object.keys(allYAxis).forEach((value, index) => {
                if (allYAxis[value].show === true) activeAxis.push(allYAxis[value]);
            });

            // console.log('drawChart', selectedMetrics, enabledDataLabels, series, activeAxis);

            let chart_options = {
                chart: {
                    height: "100%",
                    type: "line",
                    toolbar: { show: true },
                    dropShadow: {
                        enabled: true,
                        color: "#000",
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2,
                    },
                    animations: {
                        enabled: false,
                    },
                },
                animations: {
                    enabled: false,
                },
                zoom: {
                    enabled: false,
                },
                stroke: {
                    width: 3,
                    dashArray: 0,
                    curve: "smooth",
                },
                colors: ["#93C47D", "#E06666", "#D6B653", "#007CBE", "#073B3A", "#0B6E4F", "#2C4251", "#503D3F", "#3F84E5"],
                grid: {
                    show: true,
                    xaxis: {
                        lines: {
                            show: true,
                        },
                    },
                    yaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                markers: {
                    size: 0,
                    strokeColors: [
                        "#93C47D",
                        "#E06666",
                        "#D6B653",
                        "#007CBE",
                        "#073B3A",
                        "#0B6E4F",
                        "#2C4251",
                        "#503D3F",
                        "#3F84E5",
                    ],
                },
                fill: {
                    type: "solid",
                    opacity: [1, 1],
                },
                legend: {
                    show: true,
                    position: "bottom",
                    horizontalAlign: "right",
                    floating: false,
                    offsetY: 0,
                    offsetX: 0,
                },
                xaxis: {
                    categories: props.labels,
                    type: "category",
                    labels: {
                        style: {
                            colors: "grey",
                            fontSize: "12px",
                            cssClass: "apexcharts-xaxis-label",
                        },
                        formatter: (value, timestamp, index) => {
                            // return filterDisplayDate(value) // Used for when type = 'datetime'
                            if (value) return value;
                        },
                    },
                    tickPlacement: "between",
                },
                yaxis: activeAxis,
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: selectedMetrics.map((value, index) => {
                        if (enabledDataLabels.indexOf(value) !== -1) return index;
                    }),
                    style: {
                        fontSize: "13px",
                        fontFamily: "Nunito",
                    },
                    formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                        return formatMetricValue(selectedMetrics[seriesIndex], value);
                    },
                    background: {
                        enabled: true,
                    },
                    offsetY: -10,
                },
                tooltip: {
                    shared: true,
                    y: {
                        formatter: function (val, w) {
                            return formatMetricValue(selectedMetrics[w.seriesIndex], val);
                        },
                    },
                },
            };

            setOptions(chart_options);
            setSeries(series);
            setLoading(false);

            // console.log('quantitative chart', chart_options, series);
        } else {
            setLoading(true);
        }
    }, [props.selectedMetrics, props.enabledDataLabels, props.data, props.labels]);

    return (
        <div className="d-flex" style={{ height: props.height ?? "500px" }}>
            {loading ? (
                <div className="w-100">
                    <WidgetSpinner />
                </div>
            ) : noData ? (
                <div>No Data</div>
            ) : (
                series && (
                    <ResponsiveContainer>
                        <Chart options={options} series={series} type="line" height={500} />
                    </ResponsiveContainer>
                )
            )}
        </div>
    );
};

export default QuantitativeApexChart;
