import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Card, ButtonGroup, Button } from "react-bootstrap";
import moment from "moment";

import { keywordanalysis } from "../../api/ppc";
import { AuthContext } from "../../contexts/AuthContext";
import { BrandContext } from "../../contexts/BrandContext";
import { HeaderContext } from "../../contexts/HeaderContext";
import { ToastContext } from "../../contexts/ToastContext";
import Loading from "../../components/helper/Loading";

import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport, Search } from "react-bootstrap-table2-toolkit";
import filterFactory /* numberFilter */ from "react-bootstrap-table2-filter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";

const KeywordAnalysis = (props) => {
    //Grabs user data from AuthContext.js
    const { user, token } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);
    const { setPageTitle, setPageTimeframe, setPageMarketplace, selectedDefaultDates, currency, marketplace } = useContext(HeaderContext);

    const [purpose, setPurpose] = useState("Performance");
    const [data, setData] = useState([]);

    const [dataLoading, setDataLoading] = useState(true);

    const { ExportCSVButton } = CSVExport;
    const { SearchBar } = Search;

    const percentFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", { style: "percent" });
    const dollarFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
        style: "currency",
        currency: currency ?? "USD",
    });

    const columns = [
        {
            text: "Product",
            dataField: "vpart",
            headerStyle: (colum, colIndex) => {
                return { width: "270px" };
            },
            formatter: (cell, row, rowIndex) => {
                let asinLink = row["asinLink"] ?? `https://www.amazon.com/dp/${row["asin"]}`;

                let image = (
                    <a href={asinLink} target="_blank" rel="noopener noreferrer">
                        <img
                            className="img-thumbnail rounded mr-2 mb-2"
                            width="45"
                            alt={(row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== '' ? row['vpart_alt'] : row['vpart'])}
                            src={`${row["smallImage"]}`}
                        ></img>
                    </a>
                );
                return (
                    <div className="d-flex flex-row flex-nowrap align-items-center">
                        {row["smallImage"] ? image : ""}
                        <div className="d-flex flex-column w-100 ml-2">
                            <a href={asinLink} target={`_${row["asin"]}`}>
                                <p className="mb-0">{row["canonical_adGroup"]}</p>
                            </a>
                            <div className="d-flex flex-row justify-content-between text-sm">
                                <p className="mb-0">{(row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== '' ? row['vpart_alt'] : row['vpart'])}</p>
                                <p>
                                    <a href={asinLink} target={`_${row["asin"]}`}>
                                        {row["asin"]}
                                    </a>
                                </p>
                                {/* <p>{row['sku'] !== undefined ? row['sku']?.split(',').join(', ') : row['sku']}</p> */}
                            </div>
                        </div>
                    </div>
                );
            },
            csvFormatter: (cell, row, rowIndex) => {
                return (
                    (row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== '' ? row['vpart_alt'] : row['vpart'])
                );
            }
        },
        {
            text: "Keyword",
            dataField: "keywordText",

            sort: true,
            classes: "font-weight-bold",
        },
        {
            text: "Match Type",
            dataField: "matchType",
            classes: "font-weight-bold",
            sort: true,
        },
        {
            text: "Impressions",
            dataField: "Impressions",
            sort: true,
            sortValue: (cell, row) => parseInt(cell),
        },
        {
            text: "Clicks",
            dataField: "Clicks",
            sort: true,
            sortValue: (cell, row) => parseInt(cell),
        },
        {
            text: "Spend",
            dataField: "Spend",
            sort: true,
            formatter: dollarFormatter.format,
            sortValue: (cell, row) => parseFloat(cell),
        },
        {
            text: "Sales",
            dataField: "Sales",
            sort: true,
            formatter: dollarFormatter.format,
            sortValue: (cell, row) => parseFloat(cell),
        },
        {
            text: "RoAS",
            dataField: "RoAS",
            sort: true,
            sortValue: (cell, row) => parseFloat(cell),
            formatter: (cell, row, rowIndex) => {
                if (!cell) return "";

                return cell + "x";
            },
        },
    ];

    /**
     * useEffect with no second arguments = Run on every render
     *
     * Return statement is the cleanup function, runs when the component unmounts (Usually used to cleanup. Here it is used to clean up the page title)
     */
    useEffect(() => {
        setPageTitle("Keyword Analysis");
        setPageMarketplace('Amazon');
        setPageTimeframe("snapshot");
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    });

    /**
     * useEffect with empty array as second argument = Run only once (on the first render)
     */
    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            try {
                const res = await keywordanalysis(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tp_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tp_e).format('YYYY-MM-DD'),
                    purpose: purpose,
                    currency: currency,
                    marketplace: marketplace,
                });

                let floats = ["Clicks", "Impressions", "RoAS", "Sales", "Spend"];

                //Convert strings to numbers, otherwise recharts min max axis parses are not accurate
                res.data.map((val) => {
                    Object.keys(val).forEach((key) => {
                        if (key in floats) {
                            val[key] = parseFloat(val[key]);
                        }
                    });
                    return val;
                });
                
                setData(res.data);
                setDataLoading(false);
            } catch (err) {
                setDataLoading(false);
                toast(err.message);
            }
        };

        getData();
    }, [token, brand, selectedDefaultDates, purpose, currency, toast, marketplace]);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className="card">
                    <h5 className="card-header text-primary">
                        {payload[0].payload.keywordText} - {payload[0].payload.matchType}
                    </h5>
                    <div className="card-body">
                        <h5 className="card-title" style={{ fontWeight: "normal" }}>
                            {payload[0].name}: {dollarFormatter.format(payload[0].value)}
                        </h5>
                        <h5 className="card-title" style={{ fontWeight: "normal" }}>
                            {payload[1].name}: {dollarFormatter.format(payload[1].value)}
                        </h5>
                        <h5 className="card-title" style={{ fontWeight: "normal" }}>
                            RoAS: {(payload[1].value / payload[0].value).toFixed(2)}x
                        </h5>
                    </div>
                </div>
            );
        }

        return null;
    };

    const renderChart = () => {
        return (
            <ToolkitProvider
                bootstrap4
                keyField="keywordId"
                data={data}
                columns={columns}
                search={{
                    searchFormatted: true,
                }}
                exportCSV
            >
                {(props) => (
                    <div>
                        <div className="d-flex flex-row align-items-center p-2">
                            <ExportCSVButton className="btn btn-dark m-0 ml-3 bg-accent-default" {...props.csvProps}>
                                <FontAwesomeIcon icon={faDownload} /> Export CSV
                            </ExportCSVButton>
                            <div className="ml-auto">
                                <SearchBar className="m-0 mt-2 mr-3" {...props.searchProps} />
                            </div>
                        </div>

                        <BootstrapTable
                            {...props.baseProps}
                            pagination={paginationFactory({ sizePerPage: 20, alwaysShowAllBtns: true })}
                            filter={filterFactory()}
                            defaultSorted={[
                                {
                                    dataField: "Sales",
                                    order: "desc",
                                },
                            ]}
                            hover
                            bordered
                        />
                    </div>
                )}
            </ToolkitProvider>
        );
    };

    const renderChart2 = () => {
        return (
            <div className="flex-shrink-1 flex-grow-1" style={{ height: "800px", minWidth: "800px", width: "100%" }}>
                <ResponsiveContainer>
                    <ScatterChart width={400} height={400} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                        <XAxis
                            type="number"
                            dataKey={"Spend"}
                            name="Spend"
                            label={{ value: "Spend", position: "insideBottom", offset: -2 }}
                            tickCount={10}
                        />
                        <YAxis
                            type="number"
                            dataKey={"Sales"}
                            name="Sales"
                            label={{ value: "Sales", angle: -90, position: "insideLeft" }}
                            tickCount={10}
                        />
                        {/*<ZAxis dataKey={"z"} range={[60, 400]} name="score" unit="km" />*/}
                        <CartesianGrid />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />

                        <Scatter name="Keywords by Spend and Sales" data={data} fill="#0878FF"></Scatter>
                    </ScatterChart>
                </ResponsiveContainer>
            </div>
        );
    };

    return (
        <>
            <main>
                <div className="container-fluid">
                    <Row className="mt-4">
                        <Col>
                            <div className="card h-100" /*style={{ overflow: "auto" }}*/>
                                <div className="card-header white bg-white bg-white">
                                    <div className="d-flex flex-row align-items-center">
                                        <ButtonGroup aria-label="buttonTabs">
                                            <Button
                                                variant={purpose === "Performance" ? "dark" : "light"}
                                                className={purpose === "Performance" ? "bg-accent-default" : ""}
                                                size="lg"
                                                onClick={(e) => {
                                                    setPurpose("Performance");
                                                }}
                                            >
                                                Performance
                                            </Button>
                                            <Button
                                                variant={purpose === "Defensive" ? "dark" : "light"}
                                                size="lg"
                                                className={purpose === "Defensive" ? "bg-accent-default" : ""}
                                                onClick={(e) => {
                                                    setPurpose("Defensive");
                                                }}
                                            >
                                                Defensive
                                            </Button>
                                            <Button
                                                variant={purpose === "Research" ? "dark" : "light"}
                                                size="lg"
                                                className={purpose === "Research" ? "bg-accent-default" : ""}
                                                onClick={(e) => {
                                                    setPurpose("Research");
                                                }}
                                            >
                                                Research
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                                <div className="card-header white bg-white bg-white">
                                    <div className="d-flex flex-row align-items-center">
                                        <div>
                                            <p className="text-uppercase small mb-1" style={{ fontSize: "16px" }}>
                                                <strong>{"Keyword Performance"}</strong>
                                            </p>
                                            <p className="mb-0">
                                                {
                                                    "Tracks the efficiency of converting keywords during the time period and their in depth metrics."
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body w-100 pt-0 pb-0">{dataLoading ? <Loading /> : renderChart()}</div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <Card>
                                <Card.Header className="white bg-white">
                                    <div className="d-flex flex-row align-items-center">
                                        <div>
                                            <p className="text-uppercase small mb-1" style={{ fontSize: "16px" }}>
                                                <strong>{"Keyword Performance"}</strong>
                                            </p>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div
                                        className="flex-shrink-1 flex-grow-1"
                                        style={{ height: "800px", minWidth: "800px", width: "100%" }}
                                    >
                                        {dataLoading ? <Loading /> : renderChart2()}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </main>
        </>
    );
};

export default KeywordAnalysis;
