import fortress, { fortress_login } from "./fortress";

export async function login(params) {
    return fortress_login.post("login", params);
}

export async function loginAuth0(email, token) {
    return fortress.post(
        "authLogin",
        { email: email },
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
}
export async function register(params) {
    return fortress_login.post("register", params);
}
export async function forgot(params) {
    return fortress_login.post("forgot", params);
}
export async function reset(params) {
    return fortress_login.post("reset", params);
}

export async function logoutAPI(token) {
    return fortress.post(
        "logout",
        {},
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
}

export async function resendVerify(token) {
    return fortress.post(
        "email/verify/resend",
        {},
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
}

export async function getUser(token) {
    return fortress.get("user", {
        headers: { Authorization: `Bearer ${token}` },
    });
}

export async function getAuthorizedBrands(token) {
    return fortress.get("user/brands", {
        headers: { Authorization: `Bearer ${token}` },
    });
}

export async function getClientOrganizations(user, token, params) {
    if (user.organizationid === 401) {
        return fortress.get("admin/organizations", {
            headers: { Authorization: `Bearer ${token}` },
            params: params,
        });
    }
}

export async function inviteClient(user, token, params) {
    if (user.organizationid === 401) {
        return fortress.post("admin/invite/client", params, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
}

export async function resendInviteClient(user, token, params) {
    if (user.organizationid === 401) {
        return fortress.post("admin/invite/resend", params, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
}

export async function logging(token, params) {
    try {

        if (token !== null && token !== undefined) {
            return fortress.post("logging/log", params, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } else {
            return fortress_login.post("logging/log", params);
        }
    } catch (e) {
        return fortress_login.post("logging/log", params);
    }
}

export async function requestHelp(token, params) {
    try {

        if (token !== null && token !== undefined) {
            return fortress.post("logging/help", params, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': "multipart/form-data charset=utf-8; boundary=" + Math.random().toString().substr(2),
                },
            });
        } else {
            return fortress_login.post("logging/help", params, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': "multipart/form-data charset=utf-8; boundary=" + Math.random().toString().substr(2),
                }
            });
        }
    } catch (e) {
        return fortress_login.post("logging/help", params, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': "multipart/form-data charset=utf-8; boundary=" + Math.random().toString().substr(2),
            }
        });
    }
}