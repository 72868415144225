import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import moment from "moment";

import { AuthContext } from "../../contexts/AuthContext";
import { BrandContext } from "../../contexts/BrandContext";
import { HeaderContext } from "../../contexts/HeaderContext";

import useIsMountedRef from "../../components/helper/isMountedRef";
import Loading from "../../components/helper/Loading";
import DataError from "../../components/helper/DataError";
import ChartDescription from "../../components/helper/ChartDescription";
import BootstrapTablesWrapper from "../../components/dataRender/BootstrapTablesWrapper";

import BundleLine from "../BrandReport/widgets/BundleLine";
import BundlePie from "../BrandReport/widgets/BundlePie.js";
import { DollarFormatter, IntFormatter } from "../../components/helper/NumberFormatters";

import { bundlePerformanceCharts, bundlePerformanceData, bundleAggregatedData } from "../../api/brandData";

const percentFormatter = new Intl.NumberFormat("en-US", { style: "percent", minimumFractionDigits: 2 });

const BundlePerformance = (props) => {
    // console.log("BundlePerformance", props);
    const { token } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { setPageTitle, setPageTimeframe, setPageMarketplace, selectedDefaultDates, currency, marketplace } = useContext(HeaderContext);

    const [pageDataLoading, setPageDataLoading] = useState(true);
    const isMountedRef = useIsMountedRef();

    const [activeTabLine, setActiveTabLine] = useState("units");

    const [activeTabPie, setActiveTabPie] = useState("sales");
    const [dataCharts, setDataCharts] = useState();

    const [activeTabTable, setActiveTabTable] = useState("aggregated");
    const [bundleTimeTable, setBundleTimeTable] = useState();
    const [bundleAggregatedTable, setbundleAggregatedTable] = useState();

    const [[dataError, errorStatus, errorMessage, errorResponse], setDataError] = useState([false, "", "", ""]);

    useEffect(() => {
        setPageTitle("Bundles Performance");
        setPageMarketplace('Amazon');
        setPageTimeframe("timeframe");
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    });

    useEffect(() => {
        const getData = async () => {
            setPageDataLoading(true);
            setDataError([false, "", "", ""]);
            try {
                const res2 = await bundlePerformanceData(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                    wow: selectedDefaultDates.timeframe,
                    currency: currency,
                    // marketplace: marketplace,
                });

                setBundleTimeTable(res2.data?.bundlePerformanceData?.data);
                // console.log("bundlePerformanceData Data", res2.data);

                if (
                    res2?.data?.bundlePerformanceData?.data === undefined ||
                    res2?.data?.bundlePerformanceData?.data.length === 0
                ) {
                    setPageDataLoading(false);
                    setDataError([true, "", "No Bundle Data", ""]);
                    return;
                }

                const res = await bundlePerformanceCharts(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                    wow: selectedDefaultDates.timeframe,
                    currency: currency,
                });

                setDataCharts(res.data);
                // console.log("bundlePerformanceCharts Data", res.data);

                const res3 = await bundleAggregatedData(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                    wow: selectedDefaultDates.timeframe,
                    currency: currency,
                });

                if (res3.data?.bundleAggregatedData?.data !== undefined) {
                    let total_bundle_sales = res3.data.bundleAggregatedData.data.reduce((carry, row, index) => {
                        return carry + row["bundle_sales"] ?? 0;
                    }, 0);

                    res3.data.bundleAggregatedData.data.map((row, index) => {
                        row["percent_total"] = row["bundle_sales"] / total_bundle_sales;
                        return row;
                    });

                    setbundleAggregatedTable(res3.data.bundleAggregatedData.data);
                    // console.log("bundleAggregatedData Data", res3.data, total_bundle_sales);
                }

                setPageDataLoading(false);
            } catch (err) {
                // console.log(err);
                setPageDataLoading(false);
                setDataError([true, err.response?.status, err.response?.statusText, err.response?.data]);
            }
        };

        if (brand.brandId !== undefined && selectedDefaultDates.timeframe !== undefined) {
            setPageDataLoading(false);
            getData();
        } else {
            setPageDataLoading(true);
        }
    }, [token, brand, selectedDefaultDates, currency, marketplace]);

    const pageDollarFormatter = (value) => {
        return DollarFormatter(value, currency, brand.brandLocale);
    };

    return pageDataLoading === true ? (
        <Loading></Loading>
    ) : dataError === true ? (
        <DataError showFull="true" status={errorStatus} message={errorMessage} response={errorResponse}></DataError>
    ) : (
        <main className="d-flex justify-content-start align-items-top">
            <Col>
                <Row className="mt-4">
                    <Col>
                        <Tabs activeKey={activeTabLine} onSelect={(k) => setActiveTabLine(k)}>
                            <Tab className="bg-white" eventKey={"units"} title={"Units"}>
                                <Row className="pt-2">
                                    <Col md={8}>
                                        <div style={{ height: "34em" }}>
                                            <BundleLine
                                                widgetID={"bundlePerformanceUnits"}
                                                headerTitle="Bundle Performance Over Time (Units)"
                                                dataLabel={"Bundle Units"}
                                                widgetData={[dataCharts?.bundlePerformanceCharts?.unitsLine]}
                                                widgetFormatter={IntFormatter}
                                                widgetDataLoading={isMountedRef && pageDataLoading}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div style={{ height: "34em" }}>
                                            <BundlePie
                                                widgetID={"bundlePieUnits"}
                                                headerTitle="Bundle Percent of Sales (Units)"
                                                widgetData={[dataCharts?.bundlePerformanceCharts?.pieUnits]}
                                                widgetFormatter={IntFormatter}
                                                widgetDataLoading={isMountedRef && pageDataLoading}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab className="bg-white" eventKey={"sales"} title={"Sales"}>
                                <Row className="pt-2">
                                    <Col md={8}>
                                        <div style={{ height: "34em" }}>
                                            <BundleLine
                                                widgetID={"bundlePerformanceUnits"}
                                                headerTitle="Bundle Performance Over Time (Sales)"
                                                dataLabel={"Bundle Sales"}
                                                widgetData={[dataCharts?.bundlePerformanceCharts?.salesLine]}
                                                widgetFormatter={pageDollarFormatter}
                                                widgetDataLoading={isMountedRef && pageDataLoading}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div style={{ height: "34em" }}>
                                            <BundlePie
                                                widgetID={"bundlePieSales"}
                                                headerTitle="Bundle Percent of Sales (Sales)"
                                                widgetData={[dataCharts?.bundlePerformanceCharts?.pieSales]}
                                                widgetFormatter={pageDollarFormatter}
                                                widgetDataLoading={isMountedRef && pageDataLoading}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col>
                        <Tabs activeKey={activeTabTable} onSelect={(k) => setActiveTabTable(k)}>
                            <Tab eventKey={"aggregated"} title={"Bundle Totals"}>
                                <div>
                                    {bundleAggregatedTable !== undefined && (
                                        <BootstrapTablesWrapper
                                            data={bundleAggregatedTable}
                                            keyField="index"
                                            description={
                                                <ChartDescription
                                                    title={"Bundle Performance Aggregated"}
                                                    description={"Total Bundle Performance in the timeframe"}
                                                />
                                            }
                                            hasPagination={true}
                                            hasFilter={true}
                                            defaultSorted={[
                                                {
                                                    dataField: "bundle_sales",
                                                    order: "desc",
                                                },
                                            ]}
                                            columns={[
                                                // { dataField: "brand_name", text: "Brand", sort: false, type: "string" },
                                                {
                                                    dataField: "largeImage",
                                                    text: "Bundle Image",
                                                    sort: false,
                                                    hidden: true,
                                                    type: "string",
                                                    filter: "textFilter",
                                                    formatter: (cell, row, rowIndex) => {
                                                        return (
                                                            <div className="d-flex flex-row flex-nowrap align-items-center">
                                                                <a
                                                                    href={row["asinLink"]}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <img
                                                                        className="img-thumbnail rounded mr-2 mb-2"
                                                                        width="45"
                                                                        alt={row["vpart"]}
                                                                        src={`${row["image"]}`}
                                                                    ></img>
                                                                </a>
                                                            </div>
                                                        );
                                                    },
                                                },
                                                {
                                                    dataField: "bundle_asin",
                                                    text: "Bundle ASIN",
                                                    sort: true,
                                                    type: "string",
                                                    filter: "textFilter",
                                                    headerStyle: (colum, colIndex) => {
                                                        return { minWidth: "135px", maxWidth: "270px" };
                                                    },
                                                    formatter: (cell, row, rowIndex) => {
                                                        return (
                                                            <a
                                                                href={row["asinLink"]}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {cell}
                                                            </a>
                                                        );
                                                    },
                                                },
                                                {
                                                    dataField: "bundle_title",
                                                    text: "Bundle Title",
                                                    type: "string",
                                                    sort: true,
                                                    formatter: (cell, row, rowIndex) => {
                                                        return (
                                                            <div
                                                                style={{
                                                                    display: "-webkit-box",
                                                                    WebkitBoxOrient: "vertical",
                                                                    WebkitLineClamp: 3,
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                }}
                                                                title={cell}
                                                            >
                                                                {cell}
                                                            </div>
                                                        );
                                                    },
                                                },
                                                {
                                                    dataField: "bundle_units",
                                                    text: "Bundle Units",
                                                    type: "number",
                                                    sort: true,
                                                },
                                                {
                                                    dataField: "bundle_sales",
                                                    text: "Bundle Sales",
                                                    type: "number",
                                                    sort: true,
                                                    formatter: "dollarFormatter",
                                                },
                                                {
                                                    dataField: "percent_total",
                                                    text: "Bundle % of Total",
                                                    type: "number",
                                                    sort: true,
                                                    formatter: "percentFormatter",
                                                },
                                            ]}
                                        />
                                    )}
                                </div>
                            </Tab>
                            <Tab eventKey={"overtime"} title={"Sales Over Time"}>
                                <div>
                                    {bundleTimeTable !== undefined && (
                                        <BootstrapTablesWrapper
                                            data={bundleTimeTable}
                                            keyField="index"
                                            description={
                                                <ChartDescription
                                                    title={"Bundle Performance Over Time"}
                                                    description={"Raw data of bundle sales over time"}
                                                />
                                            }
                                            hasPagination={true}
                                            hasFilter={true}
                                            defaultSorted={[
                                                {
                                                    dataField: "sale_date",
                                                    order: "desc",
                                                },
                                            ]}
                                            columns={[
                                                // { dataField: "brand_name", text: "Brand", sort: false, type: "string" },
                                                {
                                                    dataField: "largeImage",
                                                    text: "Bundle Image",
                                                    sort: false,
                                                    hidden: true,
                                                    type: "string",
                                                    filter: "textFilter",
                                                    formatter: (cell, row, rowIndex) => {
                                                        return (
                                                            <div className="d-flex flex-row flex-nowrap align-items-center">
                                                                <a
                                                                    href={row["asinLink"]}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <img
                                                                        className="img-thumbnail rounded mr-2 mb-2"
                                                                        width="45"
                                                                        alt={row["vpart"]}
                                                                        src={`${row["image"]}`}
                                                                    ></img>
                                                                </a>
                                                            </div>
                                                        );
                                                    },
                                                },
                                                {
                                                    dataField: "bundle_asin",
                                                    text: "Bundle ASIN",
                                                    sort: true,
                                                    type: "string",
                                                    filter: "textFilter",
                                                    headerStyle: (colum, colIndex) => {
                                                        return { minWidth: "135px", maxWidth: "270px" };
                                                    },
                                                    formatter: (cell, row, rowIndex) => {
                                                        return (
                                                            <a
                                                                href={row["asinLink"]}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {cell}
                                                            </a>
                                                        );
                                                    },
                                                },
                                                {
                                                    dataField: "bundle_title",
                                                    text: "Bundle Title",
                                                    type: "string",
                                                    sort: true,
                                                    formatter: (cell, row, rowIndex) => {
                                                        return (
                                                            <div
                                                                style={{
                                                                    display: "-webkit-box",
                                                                    WebkitBoxOrient: "vertical",
                                                                    WebkitLineClamp: 3,
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                }}
                                                                title={cell}
                                                            >
                                                                {cell}
                                                            </div>
                                                        );
                                                    },
                                                },
                                                {
                                                    dataField: "bundle_discount",
                                                    text: "Bundle Discount",
                                                    type: "number",
                                                    sort: true,
                                                    formatter: (cell, row, rowIndex) => {
                                                        return <div>{percentFormatter.format(cell)}</div>;
                                                    },
                                                },
                                                {
                                                    dataField: "sale_date",
                                                    text: "Sale Date",
                                                    type: "date",
                                                    sort: true,
                                                    filter: "dateFilter",
                                                    headerStyle: (colum, colIndex) => {
                                                        return { width: "300px" };
                                                    },
                                                },
                                                {
                                                    dataField: "bundle_price",
                                                    text: "Bundle Price",
                                                    type: "number",
                                                    sort: true,
                                                    formatter: "dollarFormatter",
                                                },
                                                {
                                                    dataField: "bundle_units",
                                                    text: "Bundle Units",
                                                    type: "number",
                                                    sort: true,
                                                },
                                                {
                                                    dataField: "bundle_sales",
                                                    text: "Bundle Sales",
                                                    type: "number",
                                                    sort: true,
                                                    formatter: "dollarFormatter",
                                                },
                                                {
                                                    dataField: "page_views",
                                                    text: "Page Views",
                                                    type: "number",
                                                    sort: true,
                                                    formatter: "dollarFormatter",
                                                },
                                                {
                                                    dataField: "cnv",
                                                    text: "CNV",
                                                    type: "number",
                                                    sort: true,
                                                    formatter: "percentFormatter",
                                                },
                                            ]}
                                        />
                                    )}
                                </div>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Col>
        </main>
    );
};

export default BundlePerformance;
