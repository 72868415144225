import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Card, Button, Tabs, Tab, Accordion } from "react-bootstrap";
import moment from "moment";

import { AuthContext } from "../../contexts/AuthContext";
import { BrandContext } from "../../contexts/BrandContext";
import { HeaderContext } from "../../contexts/HeaderContext";

import BootstrapTablesWrapper from "../../components/dataRender/BootstrapTablesWrapper";
import WidgetSpinner from "../../components/helper/WidgetSpinner";
import { VpartFilterCard } from "../../components/helper/VpartFilter.js";

import { brandMetrics, vPartMetrics } from "../../api/brandData";

import QuantitativeApexChart from "./QuantitativeApexChart";
import { metric_property, metrics } from "./metricsVars";

const defaults = ["Total Sales", "Organic Sales", "SNS Sales", "Paid Sales"];

const QuantitativeReport = (props) => {
    // console.log('QuantitativeReport', props);

    const { token } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { setPageTitle, setPageTimeframe, selectedDefaultDates, currency, marketplace } = useContext(HeaderContext);

    const [dataLoading, setDataLoading] = useState(true);

    // const [data, setData] = useState();
    const [chartData, setChartData] = useState();
    const [chartLabels, setChartLabels] = useState();

    const [brandDataColumns, setBrandDataColumns] = useState([]);
    const [brandData, setBrandData] = useState();
    const [brandAggregatedData, setBrandAggregatedData] = useState();
    const [brandAggregatedTableData, setBrandAggregatedTableData] = useState();

    const [selectedVparts, setSelectedVparts] = useState([]);
    const [vpartData, setVpartData] = useState();
    const [vpartDataColumns, setVpartDataColumns] = useState([]);

    const [selectedMetrics, setSelectedMetrics] = useState(defaults);
    const [enabledDataLabels, setEnabledDataLabels] = useState(["Total Sales"]);

    const categories = [
        "Global",
        "Organic",
        "SNS",
        "Paid",
        "SPSB",
        "SP",
        "SP Performance",
        "SP Defensive",
        "SP Research",
        "SB",
        "SD",
        "DSP",
    ];
    const [activeMetricTab, setActiveMetricTab] = useState("Global");
    const [activeTab, setActiveTab] = useState("brand");

    const getQuantitativeDataTableCols = (array) => {
        return array.map((value) => {
            const col = {
                dataField: value,
                text: metric_property[value]?.label ?? value,
                type: metric_property[value]?.type ?? "string",
                sort: true,
                formatter: metric_property[value]?.formatter ?? undefined,
                headerStyle: { width: "150px" },
            };
            return col;
        });
    };

    const [random, setRandom] = useState(Math.random());
    const reRender = () => setRandom(Math.random());

    const updateSelectedVparts = (value) => {
        // console.log('updateSelectedVparts', value);
        setSelectedVparts(value);
    };

    useEffect(() => {
        setPageTitle("Quantitative Report");
        setPageTimeframe("timeframe");
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    });

    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            try {
                const res = await brandMetrics(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                    wow: selectedDefaultDates.timeframe,
                    currency: currency,
                    marketplace: marketplace,
                });
                const newCols = getQuantitativeDataTableCols(res.data?.metrics);

                // setData(res.data);
                setBrandDataColumns(newCols);
                setBrandData(res.data?.data);
                setChartLabels(res.data?.labels);
                setBrandAggregatedData(res.data?.brand_aggregate);
                setBrandAggregatedTableData([res.data?.brand_aggregate]); // Array of 1
                setChartData(res.data?.chartData);

                setDataLoading(false);
                // console.log("QuantitativeReport Brand Data", res.data, newCols);
            } catch (err) {
                setDataLoading(false);
            }
        };

        const getVpartData = async () => {
            setDataLoading(true);
            try {
                const searchVparts = selectedVparts?.map((v) => {
                    return v.vpart;
                });

                const res = await vPartMetrics(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                    wow: selectedDefaultDates.timeframe,
                    vparts: searchVparts,
                    currency: currency,
                    marketplace: marketplace,
                });
                const newCols = getQuantitativeDataTableCols(res.data.metrics);

                setVpartData(res.data);
                setVpartDataColumns(newCols);

                // If vpart selected updated brand data too
                if (searchVparts !== undefined && searchVparts.length > 0) {
                    setChartData(res.data?.chartData);
                    setChartLabels(res.data?.labels);

                    setBrandDataColumns(newCols);
                    setBrandData(res.data?.brand_data);
                    setBrandAggregatedData(res.data?.brand_aggregate);
                    setBrandAggregatedTableData([res.data?.brand_aggregate]); // Array of 1

                    // setData(res.data);
                }

                setDataLoading(false);
                // console.log("QuantitativeReport vPart Data", res.data, newCols);
            } catch (err) {
                // console.log('vpartdata', err);
                setDataLoading(false);
            }
        };

        if (brand.brandId !== undefined && selectedDefaultDates.timeframe !== undefined) {
            const searchVparts = selectedVparts?.map((v) => {
                return v.vpart;
            });

            // Reset
            setChartData();
            setChartLabels();

            // Get, if vpart selected only need vpart, otherwise get both
            if (searchVparts !== undefined && searchVparts.length > 0) {
                getVpartData();
            } else {
                getData();
                getVpartData();
            }
        } else {
            setDataLoading(true);
        }
    }, [token, brand, selectedDefaultDates, selectedVparts, currency, marketplace]);

    const toggleChartMetric = (metric, element = undefined) => {
        const mIndex = selectedMetrics.indexOf(metric);
        const lIndex = enabledDataLabels.indexOf(metric);
        if (mIndex === -1) {
            // Not found, add
            selectedMetrics.push(metric);
            enabledDataLabels.push(metric);
            setSelectedMetrics(selectedMetrics);
            setEnabledDataLabels(enabledDataLabels);
        } else {
            // Found, remove
            selectedMetrics.splice(mIndex, 1);
            enabledDataLabels.splice(lIndex, 1);
            setSelectedMetrics(selectedMetrics);
            setEnabledDataLabels(enabledDataLabels);
        }
        reRender();
    };

    const toggleChartDataLabels = (metric, element = undefined) => {
        const mIndex = selectedMetrics.indexOf(metric);
        const lIndex = enabledDataLabels.indexOf(metric);
        if (lIndex === -1 && mIndex === -1) {
            // Not found anywhere, add both
            selectedMetrics.push(metric);
            enabledDataLabels.push(metric);
            setSelectedMetrics(selectedMetrics);
            setEnabledDataLabels(enabledDataLabels);
        }
        if (lIndex === -1 && mIndex !== -1) {
            // Not found as datalabel, add
            enabledDataLabels.push(metric);
            setEnabledDataLabels(enabledDataLabels);
        } else {
            // Found, remove
            enabledDataLabels.splice(lIndex, 1);
            setEnabledDataLabels(enabledDataLabels);
        }
        reRender();
    };

    const renderFilter = () => {
        return (
            <Accordion style={{borderBottom: '1px solid rgba(0, 0, 0, 0.125)'}}>
                <Card>
                    <Card.Header className="bg-white">
                        <Accordion.Toggle as={"h5"} variant="link" className="blue-600 mb-0" eventKey="0">
                            Show Filters
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div className="d-flex justify-content-center w-100">
                                {dataLoading ? (
                                    <WidgetSpinner />
                                ) : (
                                    brandAggregatedData && (
                                        <div>
                                            <Row className="d-flex justify-content-center">
                                                {selectedMetrics?.length > 0 &&
                                                    selectedMetrics.map((metric_prop, index) => {
                                                        return (
                                                            <div key={index}>{getMetricButton(metric_prop, "bg-white")}</div>
                                                        );
                                                    })}
                                            </Row>
                                            <Row className="d-flex justify-content-center">
                                                <Col>
                                                    <Tabs
                                                        className="justify-content-center"
                                                        activeKey={activeMetricTab}
                                                        onSelect={(k) => setActiveMetricTab(k)}
                                                    >
                                                        {categories &&
                                                            categories.map((category, index) => {
                                                                return (
                                                                    <Tab eventKey={category} title={category} key={index}>
                                                                        <Row className="mt-3 pt-4 d-flex justify-content-center border-top">
                                                                            {metrics.map((metric, ii) => {
                                                                                if (
                                                                                    metric_property[metric].category ===
                                                                                    category
                                                                                ) {
                                                                                    return (
                                                                                        <div key={ii}>
                                                                                            {getMetricButton(metric)}
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </Row>
                                                                    </Tab>
                                                                );
                                                            })}
                                                    </Tabs>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                )}
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    };

    const formatMetricValue = (metric, value) => {
        const percentFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
            style: "percent",
            minimumFractionDigits: 2,
        });
        const dollarFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
            style: "currency",
            currency: currency,
        });
        const currencyFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
            style: "currency",
            currency: currency,
        });
        const wholeNumberFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
            style: "decimal",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
        const numberFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
            style: "decimal",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        });

        if (metric_property[metric]?.formatter === "dollarFormatter") {
            return dollarFormatter.format(value);
        } else if (metric_property[metric]?.formatter === "intlCurrencyFormatter") {
            return currencyFormatter.format(value);
        } else if (metric_property[metric]?.formatter === "percentFormatter") {
            return percentFormatter.format(value);
        } else if (metric_property[metric]?.formatter === "numberFormatter") {
            return numberFormatter.format(value);
        } else if (metric_property[metric]?.formatter === "ratioFormatter") {
            return `${numberFormatter.format(value)}x`;
        } else if (metric_property[metric]?.formatter === "intFormatter") {
            return wholeNumberFormatter.format(value);
        }

        return "";
    };

    const getMetricButton = (name, background = undefined) => {
        const is_enabled = selectedMetrics.indexOf(name) !== -1;
        const is_labelenabled = enabledDataLabels.indexOf(name) !== -1;

        return (
            brandAggregatedData[name] && (
                <Col className="my-2">
                    <Card
                        className={`mx-2 ${background === undefined ? (is_enabled ? "bg-gray" : "bg-white") : background}`}
                    >
                        <Card.Body className="p-1 text-center border-bottom" onClick={(e) => toggleChartMetric(name, e)}>
                            <strong>{name}</strong>
                            <p className="mb-0">{formatMetricValue(name, brandAggregatedData[name])}</p>
                        </Card.Body>
                        <Button
                            variant={is_labelenabled ? "dark" : "default"}
                            className="btn-sm"
                            onClick={(e) => toggleChartDataLabels(name, e)}
                        >
                            Toggle Datalabels
                        </Button>
                    </Card>
                </Col>
            )
        );
    };

    return (
        <main>
            <Col>
                <VpartFilterCard onSelectSubmit={(value) => updateSelectedVparts(value)}></VpartFilterCard>

                <Row className="mt-4">
                    <Col>{renderFilter()}</Col>
                </Row>

                <Row className="mt-4">
                    <Col sm={12}>
                        <Card>
                            <Card.Body>
                                <QuantitativeApexChart
                                    key={random}
                                    data={chartData}
                                    labels={chartLabels}
                                    selectedMetrics={selectedMetrics}
                                    enabledDataLabels={enabledDataLabels}
                                    height={500}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col sm={12}>
                        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                            <Tab eventKey={"brand"} title={"Brand"}>
                                <div>
                                    {dataLoading ? (
                                        <WidgetSpinner />
                                    ) : (
                                        <BootstrapTablesWrapper
                                            data={brandData}
                                            keyField="date"
                                            hasPagination={true}
                                            hasFilter={true}
                                            headerNowrap={true}
                                            rowNowrap={false}
                                            defaultSorted={[
                                                {
                                                    dataField: "date",
                                                    order: "desc",
                                                },
                                            ]}
                                            columns={[
                                                {
                                                    dataField: "date",
                                                    text: "Date",
                                                    sort: true,
                                                    type: "date",
                                                    filter: "textFilter",
                                                    headerStyle: { whiteSpace: "normal", minWidth: "150px" },
                                                },
                                                ...brandDataColumns,
                                            ]}
                                        />
                                    )}
                                </div>
                            </Tab>
                            <Tab eventKey={"brand_aggregated"} title={"Brand Aggregate"}>
                                <div>
                                    {dataLoading ? (
                                        <WidgetSpinner />
                                    ) : (
                                        <BootstrapTablesWrapper
                                            data={brandAggregatedTableData}
                                            keyField="index"
                                            hasPagination={true}
                                            hasFilter={true}
                                            headerNowrap={true}
                                            rowNowrap={false}
                                            columns={[
                                                {
                                                    dataField: "index",
                                                    text: "",
                                                    sort: false,
                                                    hidden: true,
                                                    type: "number",
                                                    // filter: 'textFilter',
                                                },
                                                ...brandDataColumns,
                                            ]}
                                        />
                                    )}
                                </div>
                            </Tab>
                            <Tab eventKey={"vpart"} title={"vParts"}>
                                <div>
                                    {dataLoading ? (
                                        <WidgetSpinner />
                                    ) : (
                                        <BootstrapTablesWrapper
                                            data={vpartData?.data}
                                            keyField="id"
                                            hasPagination={true}
                                            hasFilter={true}
                                            headerNowrap={true}
                                            rowNowrap={false}
                                            defaultSorted={[
                                                {
                                                    dataField: "vpart",
                                                    order: "asc",
                                                },
                                                {
                                                    dataField: "date",
                                                    order: "desc",
                                                },
                                            ]}
                                            columns={[
                                                {
                                                    dataField: "vpart",
                                                    text: "vPart",
                                                    sort: true,
                                                    type: "string",
                                                    filter: "textFilter",
                                                    headerStyle: { whiteSpace: "normal", minWidth: "150px" },
                                                    filterValue: (cell, row) => (row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== '' ? row['vpart_alt'] : row['vpart']),
                                                    formatter: (cell, row, rowIndex) => {
                                                        return (
                                                            (row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== '' ? row['vpart_alt'] : row['vpart'])
                                                        );
                                                    },
                                                    csvFormatter: (cell, row, rowIndex) => {
                                                        return '="'+(
                                                            (row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== '' ? row['vpart_alt'] : row['vpart'])
                                                        )+'"';
                                                    },
                                                },
                                                {
                                                    dataField: "upc",
                                                    text: "UPC",
                                                    sort: true,
                                                    hidden: true,
                                                    type: "string",
                                                    filter: "textFilter",
                                                    csvFormatter: (cell, row, rowIndex) => {
                                                        return (
                                                            `="${row['upc']}"`
                                                        );
                                                    }
                                                },
                                                {
                                                    dataField: "asin",
                                                    text: "ASIN",
                                                    type: "string",
                                                    sort: true,
                                                    hidden: true,
                                                    filter: "textFilter",
                                                    sortValue: (cell, row) => (cell['asin'] ?? ''),
                                                    filterValue: (cell, row, rowIndex) => {
                                                        return cell?.map(obj => obj?.asin).join(', ');
                                                    },
                                                    formatter: (cell, row, rowIndex) => {
                                                        return (
                                                            <div>
                                                                {(cell !== undefined && cell.length > 0) ? (cell.map((value) => {
                                                                    return (
                                                                        <span className="mr-2" key={value['id']}>
                                                                            <a href={value['asinLink']} target={`_${value['asin']}`}>
                                                                                {value['asin']}
                                                                            </a>
                                                                        </span>
                                                                    )

                                                                })) : ''}
                                                            </div>
                                                        )
                                                    },
                                                    csvFormatter: (cell, row, rowIndex) => {
                                                        return cell?.map(obj => obj?.asin).join(', ');
                                                    }
                                                },
                                                {
                                                    dataField: "short_title",
                                                    text: "Short Title",
                                                    sort: true,
                                                    type: "string",
                                                    filter: "textFilter",
                                                    headerStyle: { whiteSpace: "normal", minWidth: "150px" },
                                                },
                                                {
                                                    dataField: "date",
                                                    text: "Date",
                                                    sort: true,
                                                    type: "date",
                                                    filter: "textFilter",
                                                    headerStyle: { whiteSpace: "normal", minWidth: "150px" },
                                                },
                                                ...vpartDataColumns,
                                            ]}
                                        />
                                    )}
                                </div>
                            </Tab>
                            <Tab eventKey={"vpartaggregate"} title={"vParts Aggregate"}>
                                <div>
                                    {dataLoading ? (
                                        <WidgetSpinner />
                                    ) : (
                                        <BootstrapTablesWrapper
                                            data={vpartData?.vpart_aggregate}
                                            keyField="vpart"
                                            hasPagination={true}
                                            hasFilter={true}
                                            headerNowrap={true}
                                            rowNowrap={false}
                                            defaultSorted={[
                                                {
                                                    dataField: "vpart",
                                                    order: "asc",
                                                },
                                            ]}
                                            columns={[
                                                {
                                                    dataField: "vpart",
                                                    text: "vPart",
                                                    sort: true,
                                                    type: "string",
                                                    filter: "textFilter",
                                                    headerStyle: { whiteSpace: "normal", minWidth: "150px" },
                                                    filterValue: (cell, row) => (row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== '' ? row['vpart_alt'] : row['vpart']),
                                                    formatter: (cell, row, rowIndex) => {
                                                        return (
                                                            (row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== '' ? row['vpart_alt'] : row['vpart'])
                                                        );
                                                    },
                                                    csvFormatter: (cell, row, rowIndex) => {
                                                        return '="'+(
                                                            (row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== '' ? row['vpart_alt'] : row['vpart'])
                                                        )+'"';
                                                    },
                                                },
                                                {
                                                    dataField: "upc",
                                                    text: "UPC",
                                                    sort: true,
                                                    hidden: true,
                                                    type: "string",
                                                    filter: "textFilter",
                                                    csvFormatter: (cell, row, rowIndex) => {
                                                        return (
                                                            `="${row['upc']}"`
                                                        );
                                                    }
                                                },
                                                {
                                                    dataField: "asin",
                                                    text: "ASIN",
                                                    type: "string",
                                                    sort: true,
                                                    hidden: true,
                                                    filter: "textFilter",
                                                    sortValue: (cell, row) => (cell['asin'] ?? ''),
                                                    filterValue: (cell, row, rowIndex) => {
                                                        return cell?.map(obj => obj?.asin).join(', ');
                                                    },
                                                    formatter: (cell, row, rowIndex) => {
                                                        return (
                                                            <div>
                                                                {(cell !== undefined && cell.length > 0) ? (cell.map((value) => {
                                                                    return (
                                                                        <span className="mr-2" key={value['id']}>
                                                                            <a href={value['asinLink']} target={`_${value['asin']}`}>
                                                                                {value['asin']}
                                                                            </a>
                                                                        </span>
                                                                    )

                                                                })) : ''}
                                                            </div>
                                                        )
                                                    },
                                                    csvFormatter: (cell, row, rowIndex) => {
                                                        return cell?.map(obj => obj?.asin).join(', ');
                                                    }
                                                },
                                                {
                                                    dataField: "short_title",
                                                    text: "Short Title",
                                                    sort: true,
                                                    type: "string",
                                                    filter: "textFilter",
                                                    headerStyle: { whiteSpace: "normal", minWidth: "150px" },
                                                },
                                                ...vpartDataColumns,
                                            ]}
                                        />
                                    )}
                                </div>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Col>
        </main>
    );
};

export default QuantitativeReport;
