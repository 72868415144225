import React, { useState } from "react";
import { Row, Col, ButtonGroup, ToggleButton } from "react-bootstrap";

const MetricsOptions = (props) => {
    const [selectedMetrics, setSelectedMetrics] = useState(
        props.selectedMetrics ?? {'traffic': true}
    );

    const metricsOptions = [
        { name: 'Traffic & Conversion', value: 'traffic'},
        { name: 'New to Brand', value: 'new_to_brand'},
        { name: 'Repeat Customers', value: 'repeat_customers'},
        { name: 'Subscribe & Save', value: 'subscribe_and_save'},
        { name: 'Advertising', value: 'advertising'},
        { name: 'Buy Box', value: 'buybox'},
    ];

    const updateSelectedMetrics = (value) => {
        const updatedMetrics = {
            ...selectedMetrics,
            [value]: !selectedMetrics[value],
        };
        setSelectedMetrics(updatedMetrics);
        props.onChange(updatedMetrics);
    };

    return (
        <div className={props.className}>
            <div>
                <p className="info-text">Select the set of metrics to view</p>
            </div>
            <div>
                <ButtonGroup className="btn-group-tab border px-1">
                    {metricsOptions.map((option, index) => (
                        <ToggleButton
                            key={index}
                            id={`metrics-${index}`}
                            type="checkbox"
                            variant="tab"
                            name="metrics"
                            value={option.value}
                            checked={(selectedMetrics?.[option.value] !== undefined ? selectedMetrics[option.value] : false)}
                            onChange={(e) => updateSelectedMetrics(e.currentTarget.value)}
                        >
                            {option.name}
                        </ToggleButton>
                    ))}
                </ButtonGroup>
            </div>
        </div>
    )
}

const TimeframeOptions = (props) => {
    const [selectedTimeframe, setSelectedTimeframe] = useState(
        props.selectedTimeframe ?? 'mtd'
    );

    const timeframeOptions = [
        { name: 'Month to Date', value: 'mtd' },
        { name: 'Last Month', value: 'lm' },
        { name: 'Year to Date', value: 'ytd' },
    ];

    const updateSelectedTimeframe = (value) => {
        const updatedTimeframe = value;
        setSelectedTimeframe(updatedTimeframe);
        props.onChange(updatedTimeframe);
    }

    return (
        <div className={props.className}>
            <div>
                <p className="info-text">Select a time period to compare</p>
            </div>
            <div>
                <ButtonGroup className="btn-group-tab border px-1">
                    {timeframeOptions.map((option, index) => (
                        <ToggleButton
                            key={index}
                            id={`timeframe-${index}`}
                            type="radio"
                            variant="tab"
                            name="timeframe"
                            value={option.value}
                            checked={selectedTimeframe === option.value}
                            onClick={(e) => updateSelectedTimeframe(option.value)}
                        >
                            {option.name}
                        </ToggleButton>
                    ))}
                </ButtonGroup>
            </div>
        </div>
    );
}

const ComparativeViewOptions = (props) => {
    const [selectedComparativeView, setSelectedComparativeView] = useState(
        props.selectedComparativeView ?? 'pct'
    );

    const viewOptions = [
        { name: 'Change %', value: 'pct' },
        { name: 'Absolute Change', value: 'abs' },
    ];

    const updateSelectedComparativeView = (value) => {
        setSelectedComparativeView(value);
        props.onChange(value);
    }

    return (
        <div className={props.className}>
            <div>
                <p className="info-text">Select view</p>
            </div>
            <div>
                <ButtonGroup className="btn-group-tab border px-1">
                    {viewOptions.map((option, index) => (
                        <ToggleButton
                            key={index}
                            id={`comparative-${index}`}
                            type="radio"
                            variant="tab"
                            name="comparative"
                            value={option.value}
                            checked={selectedComparativeView === option.value}
                            onClick={(e) => updateSelectedComparativeView(option.value)}
                        >
                            {option.name}
                        </ToggleButton>
                    ))}
                </ButtonGroup>
            </div>
        </div>
    )
}

const ComparativeOptions = (props) => {

    return (
        <Col>
            <Row>
                <MetricsOptions
                    className={'w-100'}
                    onChange={(value) => props.setSelectedMetrics(value)}
                />
            </Row>
            <Row className="pt-3">
                <TimeframeOptions
                    onChange={(value) => props.setSelectedTimeframe(value)}
                />
                <ComparativeViewOptions
                    className={'ml-3'}
                    onChange={(value) => props.setSelectedComparativeView(value)}
                />
            </Row>
        </Col>
    );
};

export default ComparativeOptions;
