import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.render(
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin+'/#/login',
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "openid email profile read:current_user read:users update:users"
      }}
    >
      <App />
    </Auth0Provider>,
    document.querySelector("#root")
);