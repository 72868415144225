import React, { useContext, useState, useEffect } from "react";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";

import { BrandContext } from "../../../contexts/BrandContext";
import { ToastContext } from "../../../contexts/ToastContext";

import {
    graph_green_dark,
    graph_green,
    graph_gray,
    graph_blue_dark,
} from "../../../components/helper/ColorFormatter";

import WidgetSpinner from "../../../components/helper/WidgetSpinner";
import useIsMountedRef from "../../../components/helper/isMountedRef";
import {HeaderContext} from "../../../contexts/HeaderContext";


const chartFont = {
    colors: "gray",
    fontSize: "12px",
    cssClass: "apexcharts-xaxis-label",
};

function SalesChannelChart(props) {
    // console.log('SalesChannelChart', props);

    const [chartOptions, setChartOptions] = useState({});
    const [series, setSeries] = useState([]);

    const isMountedRef = useIsMountedRef();

    const {
        brand,
        currency,
        chartData,
        setMounted,
    } = props;

    const cleanCurrencyFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
        style: "currency",
        currency: currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
    });

    const getCleanCurrencyFormatter = (value) => {
        return cleanCurrencyFormatter.format(value);
    };

    useEffect(() => {
        if (isMountedRef.current) {
            setMounted(true);
        }
    }, [isMountedRef])

    useEffect(() => {

        if (chartData !== undefined) {
            let labels = chartData.labels;

            console.log(chartData);
            let new_series = [];
            let new_chartOptions = {};

            for(let i=0; chartData.data.length>i; i++){
                new_series.push({
                    name: chartData.data[i].label,
                    data: chartData.data[i].data,
                    type: "line",
                })
            }

            let widgetColors = ['#84344e','#E3FF00','#313132','#9d555e','#8a8a8b','#b4cb00','#dbff51','#c99b98','#b5b5b5','#a7bd00','#84344e'];

    
            new_chartOptions = {
                chart: {
                    id: "salesChannelLine",
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    dropShadow: {
                        enabled: false,
                    },
                    animations: {
                        enabled: false,
                    },
                    height: "100%",
                    type: "line",
                },
                // plotOptions: {
                //     bar: {
                //         horizontal: false,
                //         columnWidth: '55%',
                //         endingShape: 'rounded'
                //     },
                // },
                legend:{show: false},
                stroke: {
                    show: true,
                    curve: "smooth",
                    lineCap: "butt",
                    colors: widgetColors,
                },
                grid: {
                    show: true,
                    borderColor: "#eaecec",
                    xaxis: { lines: { show: true } },
                    yaxis: { lines: { show: true } },
                },
                markers: {
                    strokeWidth: [5, 5, 5, 5],
                    strokeOpacity: 1,
                    fillOpacity: 1,
                    hover: { sizeOffset: 1 },
                },
                colors: widgetColors,
                xaxis: {
                    categories: labels,
                    type: "category",
                    labels: {
                        style: chartFont,
                    },
                    tickPlacement: "between",
                },
                yaxis: {
                    title: {
                        text: "",
                    },
                    min: 0,
                    labels: {
                        formatter: (val) => getCleanCurrencyFormatter(val),
                        style: chartFont,
                    },
                },
            };

            setTimeout(()=>{
                setSeries(new_series);
                setChartOptions(new_chartOptions);
            },100);

        }

    }, [props.chartData])

    return (
        <ResponsiveContainer>
            <Chart options={chartOptions} series={series} type="line" ref={isMountedRef}/>
        </ResponsiveContainer>
    );
}

function SalesChannel(props) {
    // console.log('SalesChannel', props);

    const { brand } = useContext(BrandContext);
    const { currency } = useContext(HeaderContext);


    // const [timelineData, setTimelineData] = useState(props.widgetData ?? null);
    const timelineData = props.widgetData ?? null;
    const [widgetLoading, setWidgetLoading] = useState((props.widgetData !== null && props.widgetData !== undefined) ? false : (props.widgetDataLoading ?? true));
    const [chartMounted, setChartMounted] = useState(false);


    const {
        headerTitle = "Collection Performance",
        headerSubtitle = "",
    } = props;

    // console.log(timelineData);


    return (
        <div className="card h-100">
            <div className="card-header bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <h5 className="mb-0">{headerTitle}</h5>
                        {headerSubtitle !== "" ? <p className="body-1 text-secondary mb-0 mt-2">{headerSubtitle}</p> : ""}
                    </div>

                </div>
            </div>

            <div className="card-body w-100">{
                    widgetLoading ? 
                    <WidgetSpinner />
                    :
                    timelineData && (
                        <SalesChannelChart
                            brand={brand}
                            currency={currency}
                            chartData={timelineData}
                            setMounted={(value) => setChartMounted(value)}
                        />
                    )
                }
            </div>
        </div>
    );
}

export default SalesChannel;
