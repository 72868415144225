import React, { useContext, useState, useEffect } from "react";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";
import moment from "moment";

import WidgetSpinner from "../../../components/helper/WidgetSpinner";
import { snsSubscribersOverTime } from "../../../api/brandData";
import { AuthContext } from "../../../contexts/AuthContext";
import { BrandContext } from "../../../contexts/BrandContext";
import { HeaderContext } from "../../../contexts/HeaderContext";
import { ToastContext } from "../../../contexts/ToastContext";

const wholeNumberFormatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const chartFont = {
    colors: "grey",
    fontSize: "12px",
    //fontFamily: 'Helvetica, Arial, sans-serif',
    //fontWeight: 400,
    cssClass: "apexcharts-xaxis-label",
};

function SubscriberCount(props) {
    const { token } = useContext(AuthContext);
    const { selectedDefaultDates, dataLoading, marketplace } = useContext(HeaderContext);

    const [responseData, setResponseData] = useState([]);
    const [widgetLoading, setWidgetLoading] = useState(true);
    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);
    const {
        showConfigButton = true,
        headerTitle = "Number of Subscribers Over Time",
        headerSubtitle = "Subscribers at the end of each month",
        widgetData = null,
        widgetDataLoading = null,
    } = props;

    useEffect(() => {
        const getData = async () => {
            if (widgetData !== null) {
                setWidgetLoading(true);
                setResponseData(widgetData);
                setWidgetLoading(false);
            } else {
                setWidgetLoading(true);
                try {
                    const res = await snsSubscribersOverTime(token, {
                        brandid: brand.brandId,
                        tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                        tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                        wow: "mom",
                        marketplace: marketplace,
                    });

                    setResponseData(res.data);

                    // console.log(res.data);
                    setWidgetLoading(false);
                } catch (err) {
                    setWidgetLoading(false);
                    toast(err.message);
                }
            }
        };

        if (!dataLoading) {
            if (widgetDataLoading === null || widgetDataLoading === false) {
                getData();
            }
        }
    }, [
        token, 
        brand.brandId, 
        toast, 
        dataLoading, 
        selectedDefaultDates, 
        marketplace,
        widgetData, 
        widgetDataLoading
    ]);
    
    const renderChart = () => {
        if (responseData?.data !== undefined && responseData?.labels !== undefined) {
            var widgetColors = [
                "#FFAAAF", // Previous Year -- Red 30
                "#003362",  // Current Year -- Blue 80
            ];
            var series = [
                {
                    name: responseData.data[1]?.label,
                    data: responseData.data[1]?.data,
                    type: "line",
                },
                {
                    name: responseData.data[0]?.label,
                    data: responseData.data[0]?.data,
                    type: "line",
                },
            ];
    
            var chartOptions = {
                chart: {
                    id: props.widgetID ?? 'SubscriberCount',
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    dropShadow: {
                        enabled: true,
                        color: "#000",
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2,
                    },
                    height: "100%",
                    type: "line",
                },
                legend: { show: true, position: "bottom", horizontalAlign: "center", floating: false, offsetY: 0, offsetX: 0 },
                colors: widgetColors,
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [0, 1],
                    style: {
                        fontSize: "13px",
                        fontFamily: "Nunito",
                    },
                    formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                        return (value !== null ? wholeNumberFormatter.format(value) : '');
                    },
                    background: {
                        enabled: true,
                    },
                    offsetY: -10,
                },
                stroke: {
                    show: true,
                    curve: "smooth",
                    lineCap: "butt",
                    colors: widgetColors,
                    width: [3, 3, 3, 3, 3],
                },
                grid: {
                    show: true,
                    borderColor: "#eaecec",
                    xaxis: { lines: { show: true } },
                    yaxis: { lines: { show: true } },
                    strokeDashArray: [5, 0],
                },
                markers: {
                    size: [0, 0],
                    colors: widgetColors,
                    strokeColors: widgetColors,
                    strokeWidth: [5, 2, 2, 2, 2],
                    strokeOpacity: 1,
                    fillOpacity: 1,
                    hover: { sizeOffset: 1 },
                },
                tooltip: {
                    shared: true,
                    followCursor: true,
                    y: {
                        formatter: function (val, w) {
                            return wholeNumberFormatter.format(val);
                        },
                    },
                },
    
                xaxis: {
                    categories: responseData.labels,
                    type: "category",
                    labels: {
                        style: chartFont,
                    },
                    tickPlacement: "between",
                },
                yaxis: {
                    title: {
                        text: "",
                    },
                    min: 0,
                    labels: {
                        formatter: (val) => wholeNumberFormatter.format(val),
                        style: chartFont,
                    },
                },
            };
        }
        else {
            setWidgetLoading(true);
        }

        return series && chartOptions && (
            <ResponsiveContainer>
                <Chart options={chartOptions} series={series} type="line" />
            </ResponsiveContainer>
        );
    };

    return (
        <div className="card h-100" /*style={{ overflow: "auto" }}*/>
            <div className="card-header bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <h5>{headerTitle}</h5>
                        <p className="body-1 text-secondary mb-0">{headerSubtitle}</p>
                    </div>
                    <div className="ml-auto ">
                        {showConfigButton === true ? (
                            <button
                                type="button"
                                className="btn btn-primary btn-sm "
                                onClick={() => {
                                    //TODO: Add Configuration Modal
                                }}
                            >
                                Config
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>

            <div className="card-body w-100 pt-0 pb-0">{widgetLoading ? <WidgetSpinner /> : renderChart()}</div>
        </div>
    );
}

export default SubscriberCount;
