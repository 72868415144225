import React, { useState, useEffect } from "react";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";

import {
    graph_green,
    graph_blue_light,
    graph_gray_dark,
    defaultGraphLabelStyle,
} from "../../../components/helper/ColorFormatter";
import WidgetSpinner from "../../../components/helper/WidgetSpinner";

function BundleLine(props) {
    const [chartData, setChartData] = useState();
    const [widgetLoading, setWidgetLoading] = useState(true);

    const { headerTitle = "Bundle Performance over Time", widgetData = null, widgetDataLoading = null } = props;

    useEffect(() => {
        const getData = async () => {
            if (widgetData !== null) {
                setWidgetLoading(true);
                setChartData(widgetData);
                setWidgetLoading(false);
            } else {
                setWidgetLoading(true);
            }
        };

        getData();
    }, [widgetData, widgetDataLoading]);

    const renderChart = () => {
        if (chartData !== undefined) {
            
            var widgetColors = graph_blue_light;
            if (props.dataLabel === 'Bundle Units') {
                widgetColors = graph_gray_dark;
            }
            else if (props.dataLabel === 'Bundle Sales') {
                widgetColors = graph_green;
            }
            
            var series = [
                {
                    name: props.dataLabel ?? "Bundle",
                    data: chartData[0]?.data,
                    type: "line",
                },
            ];

            var chartLabels = chartData[0]?.labels;

            var chartOptions = {
                chart: {
                    id: props.widgetID,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    dropShadow: {
                        enabled: false,
                    },
                    animations: {
                        enabled: false,
                    },
                    type: "line",
                    stacked: true,
                },
                legend: {
                    show: true,
                    position: "top",
                    horizontalAlign: "right",
                    floating: false,
                    offsetY: 0,
                    offsetX: 0,
                },
                colors: [widgetColors],
                dataLabels: {
                    enabled: false,
                    enabledOnSeries: [0],
                    style: {
                        fontSize: "13px",
                        fontFamily: "Nunito",
                    },
                    formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                        return value !== null ? props.widgetFormatter(value) : "";
                    },
                    background: {
                        enabled: true,
                    },
                    offsetY: -10,
                },
                stroke: {
                    show: true,
                    curve: "smooth",
                    lineCap: "butt",
                    colors: [widgetColors],
                    width: [5],
                },
                grid: {
                    show: true,
                    borderColor: "#eaecec",
                    xaxis: { lines: { show: true } },
                    yaxis: { lines: { show: true } },
                },
                markers: {
                    size: [1],
                    colors: [widgetColors],
                    strokeColors: [widgetColors],
                    strokeWidth: [3],
                    strokeOpacity: 1,
                    fillOpacity: 1,
                    hover: { sizeOffset: 1 },
                },
                tooltip: {
                    shared: true,
                    followCursor: true,
                    y: {
                        formatter: function (val, w) {
                            return props.widgetFormatter(val);
                        },
                    },
                },
                xaxis: {
                    categories: chartLabels,
                    type: "datetime",
                    labels: {
                        style: defaultGraphLabelStyle,
                    },
                    tickPlacement: "between",
                },
                yaxis: {
                    title: {
                        text: "",
                    },
                    min: 0,
                    labels: {
                        formatter: (val) => props.widgetFormatter(val),
                        style: defaultGraphLabelStyle,
                    },
                },
            };
        } else {
            setWidgetLoading(true);
        }

        return (
            chartOptions &&
            series && (
                <ResponsiveContainer>
                    <Chart options={chartOptions} series={series} type="line" />
                </ResponsiveContainer>
            )
        );
    };

    return (
        <div className="card h-100 border-0" /*style={{ overflow: "auto" }}*/>
            <div className="card-header bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <h5>{props.headerTitle}</h5>
                        {props.headerSubtitle !== "" ? (
                            <p className="body-1 text-secondary mb-0">{props.headerSubtitle}</p>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>

            <div className="card-body w-100">{widgetLoading ? <WidgetSpinner /> : renderChart()}</div>
        </div>
    );
}

export default BundleLine;
