import React, { createContext, useState, useEffect, useContext } from "react";
import { useMediaQuery } from 'react-responsive';

import { AuthContext } from "./AuthContext";

import Home from "../assets/icons/svg/Home-Nav.svg";
import Timeline from "../assets/icons/svg/Timeline-Nav.svg";
import Resellers from "../assets/icons/svg/Resellers-Nav.svg";
import Product from "../assets/icons/svg/Product-Nav.svg";
import PPC from "../assets/icons/svg/PPC-Nav.svg";
import Customer from "../assets/icons/svg/Customer-Nav.svg";
import Brand from "../assets/icons/svg/BrandReport-Nav.svg";
import Report from "../assets/icons/svg/QuantitativeReport-Nav.svg";
import MarketIntelligence from "../assets/icons/svg/market-share-report.svg";
import Promotions from "../assets/icons/svg/bullhorn.svg";

// const parseJSON = (json) => {
//   try {
//     return JSON.parse(json);
//   } catch (err) {
//     return null;
//   }
// };

export const SidebarContext = createContext({});

const renderNavIcon = (icon, name = "") => {
  return (
    <img
      className="w-100 bg-transparent"
      src={icon}
      alt={name}
      height="20px"
      width="22px"
    ></img>
  );
};

const sidebarNavArray = [
    {
        key: "brand-summary",
        display: "Brand Summary",
        navLink: "/brand/summary",
        icon: renderNavIcon(Home, "Brand Summary"),
    },
    {
        key: "timeline-overview",
        display: "Timeline Overview",
        navLink: "/brand/timeline",
        icon: renderNavIcon(Timeline, "Timeline"),
    },
    {
        key: "traffic-conversions",
        display: "Traffic & Conversions",
        navLink: "/brand/traffic",
        icon: renderNavIcon(Brand, "Brand"),
    },
    {
        display: "Resellers",
        icon: renderNavIcon(Resellers, "Resellers"),
        children: [
            {
                key: "bbx",
                display: "Buy Box Central",
                navLink: "/ppc/resellers/buybox",
            },
            // {
            //     key: "reseller-central",
            //     display: "Reseller Central",
            //     navLink: "/",
            // },
            // {
            //     key: "reseller-matching",
            //     display: "Reseller Matching",
            //     navLink: "/",
            // },
        ],
    },
    {
    display: "Product Level",
    icon: renderNavIcon(Product, "Products"),
    children: [
            {
                key: "product-performance",
                display: "Product Performance",
                navLink: "/performance/products",
            },
            {
                key: "category-performance",
                display: "Category Performance",
                navLink: "/performance/category",
            },
            {
                key: "bundles",
                display: "Bundles",
                navLink: "/performance/bundles",
            },
        ],
    },
    {
      display: "Promotions",
      icon:     renderNavIcon(Promotions,"Promotions"),
        children: [
            {
                key: "promo-reports",
                display: "Promotional Reporting",
                navLink: "/promotions/reports",
            },
            {
                key: "sns",
                display: "Subscribe & Save",
                navLink: "/performance/sns",
            },
        ],
    },
    {
        display: "PPC",
        icon: renderNavIcon(PPC, "PPC"),
        children: [
            {
                key: "ppc-overview",
                display: "Overview",
                navLink: "/ppc/overview",
            },
            {
                key: "ppc-timeline",
                display: "Timeline",
                navLink: "/ppc/timeline",
            },
            {
                key: "customer-journey",
                display: "Customer Journey",
                navLink: "/ppc/journey",
            },
            {
                key: "products-x-campaign",
                display: "Products x Campaign",
                navLink: "/ppc/products",
            },
            {
                key: "keyword-analysis",
                display: "Keyword Analysis",
                navLink: "/ppc/keywords/analysis",
            },
            // {
            //     key: "new-keyword-cloud",
            //     display: "New Keyword Cloud",
            //     navLink: "/ppc/keywords/cloud",
            // },
        ],
    },
    {
        display: "Customer Analysis",
        icon: renderNavIcon(Customer, "Customer"),
        children: [
            {
                key: "customer-geography",
                display: "Customer Geography",
                navLink: "/customer/geography",
            },
            // {
            //     key: "customer-lifetime-value",
            //     display: "Customer Lifetime Value",
            //     navLink: "/customer/clv",
            // },
            // {
            //     key: "auto-roas",
            //     display: "Auto ROAS",
            //     navLink: "/",
            // },
            // {
            //     key: "product-pairing-analysis",
            //     display: "Product Pairing Analysis",
            //     navLink: "/",
            // },
            // {
            //     key: "reviews",
            //     display: "Reviews",
            //     navLink: "/",
            // },
        ],
    },
    {
        display: "Market Intelligence",
        icon: renderNavIcon(MarketIntelligence, "Market Intelligence"),
        children: [
            {
                key: "market-share",
                display: "Market Share",
                navLink: "/market-intelligence/share",
            },
            {
                key: "report-builder",
                display: "Report Builder",
                navLink: "/market-share/report-builder",
            },
        ],
    },

    // {
    //     key: "brand-report",
    //     display: "Brand Report",
    //     navLink: "/brand/report",
    //     icon: renderNavIcon(Brand, "Brand Report"),
    // },
    {
        key: "quantitative-report",
        display: "Quantitative Report",
        navLink: "/brand/quantitative",
        icon: renderNavIcon(Report, "Quantitative Report"),
    },
];

const SidebarProvider = ({ children }) => {
  const { apiUser } = useContext(AuthContext);

  const isMobile = useMediaQuery({ maxWidth: 768 },);
  
  const [collapsed, setCollapsed] = useState(isMobile ? true : false);
  const [selectedSidebarNav, setSelectedSidebarNav] = useState({});

  useEffect(() => {
    let allowedPages = apiUser?.orgPages;
    let selectedSidebarNavBuild = {};
    if (allowedPages !== undefined) {
        allowedPages.map((page, index) => {
            selectedSidebarNavBuild[page] = true;
        });
    }
    setSelectedSidebarNav(selectedSidebarNavBuild);
}, [apiUser]);

  return (
    <SidebarContext.Provider
      value={{
        collapsed,
        setCollapsed,
        selectedSidebarNav,
        setSelectedSidebarNav,
        sidebarNavArray,
      }}
    >
      {" "}
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarProvider;
