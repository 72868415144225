import React, { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";

import { ComposableMap, ZoomableGroup, Geographies, Geography } from "react-simple-maps";
import { scaleQuantile } from "d3-scale";

const dollarFormatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });

const MapChart = (props) => {
    // const [data, setData] = useState(props.data);
    const [perCapita, setPerCapita] = useState(true);

    const formatMapValue = (data) => {
        if (perCapita === true)
            return data.sales / data.total_population;
        else
            return data.sales
    }

    const renderMap = () => {
        if (props.widgetLoading === false && props.data !== undefined && props.data.length > 0) {
            const colorScale = scaleQuantile()
            .domain(props.data?.map((d) => formatMapValue(d)))
            .range([
                '#E1F5FF',
                '#B2E2FF',
                '#77C9FF',
                '#2DA8FF',
                '#1287E5',
                '#0067BB',
                '#00498F',
                '#003362',
            ]);

            return (
                <Geographies geography={props.geo}>
                    {({ geographies }) =>
                        geographies.map((geo) => {
                            // geo.properties.AFFGEOID -- States
                            // geo.properties.AFFGEOID10 -- ZipCodes
                            const cur = props.data.find(
                                (s) =>
                                    s.AFFGEOID10 === geo.properties.AFFGEOID ||
                                    s.AFFGEOID10 === geo.properties.AFFGEOID10
                            );
                            // console.log(cur, geo);
                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    onMouseEnter={() => {
                                        if (cur !== undefined)
                                            props.setTooltipContent(`
                                                <div class="d-flex flex-column bg-white text-primary px-3 py-2">
                                                    <p class="body-2-bold mb-0">${geo.properties.NAME ?? geo.properties.ZCTA5CE10}</p>
                                                    <hr class="border-black w-100"/>
                                                    <p class="body-2 mb-0"> Sales: ${dollarFormatter.format(cur.sales ?? 0)} </p>
                                                    <p class="body-2 mb-0"> Orders: ${cur.orders} </p>
                                                    <p class="body-2 mb-0"> Units: ${cur.units} </p>
                                                    <p class="body-2 mb-0"> Population: ${cur.total_population} </p>
                                                    <p class="body-2 mb-0"> Per Capita: ${cur.sales / cur.total_population} </p>
                                                    <p class="body-2 mb-0"> Per Capita (x100): ${(cur.sales / cur.total_population) * 100} </p>
                                                </div>
                                            `);
                                        else
                                            props.setTooltipContent(`${geo.properties.NAME}`);
                                    }}
                                    onMouseLeave={() => {
                                        props.setTooltipContent("");
                                    }}
                                    style={{
                                        default: {
                                            fill: (cur && colorScale) ? colorScale( formatMapValue(cur) ) : "#EEEEEE",
                                            stroke: "#000",
                                            strokeWidth: 0.1,
                                            // outline: "none"
                                        },
                                        hover: {
                                            fill: "#ff5533",
                                            outline: "none",
                                        },
                                    }}
                                />
                            );
                        })
                    }
                </Geographies>
            )
        }
        else {
            return (
                <Spinner
                    className="align-self-center mx-auto"
                    as="div"
                    animation="grow"
                    role="status"
                    aria-hidden="true"
                    animation="border"
                ></Spinner>
            );
        }
    }

    return (
        <div>
            <Form.Check 
                type={"checkbox"}
                id={`per-capita-check`}
                label="Per Capita"
                name="per-capita-check"
                className={"mt-2"}
                checked={ perCapita }
                onChange={ e => setPerCapita(e.target.checked)}
            />
            <ComposableMap data-tip="" projection="geoAlbersUsa">
                { props.type === 'zip' ? 
                    (
                        <ZoomableGroup zoom={1}>
                            {renderMap()}
                        </ZoomableGroup>
                    ) : (
                        renderMap()
                    )
                }
            </ComposableMap>
        </div>
    );
};

export default MapChart;
