import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Card, Tabs, Tab } from "react-bootstrap";

import { HeaderContext } from "../../contexts/HeaderContext";

import ProductComparative from "./ProductComparative";
import CategoryComparative from "./CategoryComparative";
import BrandTodayTable from "./BrandOverall/BrandTodayTable";
import BrandComparative from "./BrandOverall/BrandComparative";

const TrafficConversion = (props) => {
    // console.log('TrafficConversion', props);

    const { setPageTitle, setPageTimeframe } = useContext(HeaderContext);

    const [activeTab, setActiveTab] = useState("brand");

    useEffect(() => {
        setPageTitle("Traffic & Conversions");
        setPageTimeframe("weekstart");
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    });

    // Total Orders
    // Total Units
    // Total Sales
    // Sessions
    // Page Views
    // Buy Box %
    // Fortress Page Views
    // Order Session %
    // New to Brand Units
    // New to Brand Sales
    // New to Brand Orders
    // New to SKU Units
    // New to SKU Sales
    // New to SKU Orders
    // New to Brand Order Session %
    // Repeat Units
    // Repeat Sales
    // Customers
    // New to Brand Customers
    // Repeat Customers
    // Repeat Order Session %
    // Sales Rank
    // Amazon GL Code
    // Average Order Value

    // Inventory & Pricing
    // FBA Received
    // Fortress Warehouse Received
    // OnHand Fortress
    // OnHand FBA
    // Promo $
    // Listing Price
    // Average Unit Retail
    // Gross Margin %
    // Unit Cost
    // Sales Lost Due to OOS

    // Advertising
    // Impressions
    // Clicks
    // Units
    // Orders
    // Spend
    // Sales
    // Click Through Rate
    // Conversion Rate

    return (
        <main className="d-flex justify-content-start align-items-top">
            <Col>
                <Row className="mt-4">
                    <Col sm={12}>
                        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                            <Tab eventKey={"brand"} title={"Brand Overall"}>
                                <Card className="nav-tab-card">
                                    <Card.Body>
                                        <BrandTodayTable/>
                                        <BrandComparative/>
                                    </Card.Body>
                                </Card>
                            </Tab>
                            <Tab eventKey={"product"} title={"By Product"}>
                                <ProductComparative/>
                            </Tab>
                            <Tab eventKey={"category"} title={"By Category"}>
                                <CategoryComparative/>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Col>
        </main>
    );
};

export default TrafficConversion;
