import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";

import { AuthContext } from "../../contexts/AuthContext";
import { BrandContext } from "../../contexts/BrandContext";
import { HeaderContext } from "../../contexts/HeaderContext";

import DataError from "../../components/helper/DataError";
import WidgetSpinner from "../../components/helper/WidgetSpinner";
import useIsMountedRef from "../../components/helper/isMountedRef";

import ComparativeTable from "./ComparativeTable";
import { comparativeVpart } from "../../api/brandData";

const ProductComparative = (props) => {
    // console.log('ProductComparative', props);

    const { token } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { currency, marketplace, timezone, weekstart } = useContext(HeaderContext);
    
    const [[dataError, errorStatus, errorMessage, errorResponse], setDataError] = useState([false, "", "", ""]);
    const isMountedRef = useIsMountedRef();
    
    const [response, setResponse] = useState();
    const [dataLoading, setDataLoading] = useState(true);

    useEffect(() => {
        const geVpartData = async () => {
            try {
                // Use snapshot / time period data
                const res = await comparativeVpart(token, {
                    brandid: brand.brandId,
                    weekstart: weekstart,
                    currency: currency,
                    marketplace: marketplace,
                    timezone: timezone,
                });

                // console.log(isMountedRef, res);
                
                if (isMountedRef.current) {
                    setResponse(res?.data);
                }
                
            } catch (err) {
                setDataError([true, err.response?.status, err.response?.statusText, err.response?.data]);
            }

            setDataLoading(false);
        };

        if (
            brand.brandId !== undefined
        ) {
            setDataLoading(true);

            geVpartData();
        }
    }, [token, brand, weekstart, currency, marketplace, isMountedRef]);

    return (
        <Card className="nav-tab-card">
            <Card.Body>
                {dataLoading ? (
                    <WidgetSpinner />
                ) : dataError === true ? (
                    <DataError showFull="true" status={errorStatus} message={errorMessage} response={errorResponse}></DataError>
                ) : (response && 
                        <ComparativeTable
                            data={response?.data}
                            metadata={response?.metadata}
                            type={'vpart'}
                        />
                )}
            </Card.Body>
        </Card>
    )
};

export default ProductComparative;
