import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";

import { AuthContext } from "../../../contexts/AuthContext";
import { BrandContext } from "../../../contexts/BrandContext";
import { HeaderContext } from "../../../contexts/HeaderContext";

import DataError from "../../../components/helper/DataError";
import WidgetSpinner from "../../../components/helper/WidgetSpinner";
import useIsMountedRef from "../../../components/helper/isMountedRef";
import BootstrapTablesWrapper from "../../../components/dataRender/BootstrapTablesWrapper";

import { getNumberFormatter } from "../../../components/helper/NumberFormatters";

import { brandDataToday } from "../../../api/brandData";

const BrandTodayTable = (props) => {
    // console.log('BrandTodayTable', props);

    const { token } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { currency, marketplace, timezone } = useContext(HeaderContext);

    const [[dataError, responseError], setDataError] = useState([false, undefined]);
    const isMountedRef = useIsMountedRef();

    const [response, setResponse] = useState();
    const [dataLoading, setDataLoading] = useState(true);

    useEffect(() => {
        const getBrandTodayData = async () => {
            try {
                // Use snapshot / time period data
                const res = await brandDataToday(token, {
                    brandid: brand.brandId,
                    currency: currency,
                    marketplace: marketplace,
                    timezone: timezone,
                });

                // console.log(isMountedRef, res);
                
                if (isMountedRef.current) {
                    setDataLoading(false);
                    setResponse(res?.data ?? []);
                }
                
            } catch (err) {
                setDataLoading(false);
                setDataError([true, err.response]);
            }

            setDataLoading(false);
        };

        if (
            brand.brandId !== undefined
        ) {
            setDataLoading(true);
            getBrandTodayData();
        }
    }, [token, brand, currency, marketplace, isMountedRef]);

    const renderBrandTable = (data) => {
        // console.log(data);

        const tableColumns = [
            /*0*/ {
                dataField: "row",
                text: "",
                type: "string",
                hidden: false,
                headerStyle: (colum, colIndex) => {
                    return { minWidth: "100px" };
                },
                formatter: (cell, row, rowIndex) => {
                    let formatter = getNumberFormatter('currency', brand, currency);
                    
                    return (
                        <Row>
                            <Col className="h4 h4-normal justify-content-center">
                                {cell}
                            </Col>
                        </Row>
                    );
                },
            },
            /*1*/ {
                dataField: "today",
                text: "Today",
                type: "number",
                sort: false,
                formatter: "dollarFormatter",
            },
            /*2*/ {
                dataField: "yesterday_sales",
                text: "Yesterday",
                type: "number",
                sort: false,
                formatter: (cell, row, rowIndex) => {
                    let formatter = getNumberFormatter('currency', brand, currency);
                    
                    return (
                        <div className="">
                            <Row>
                                <Col className="text-center justify-content-center">
                                    <div className="mb-1">{formatter.format(cell)}</div>
                                    <div>({formatter.format(row.last_week_sales)})</div>
                                </Col>
                            </Row>
                        </div>
                    );
                },
                headerFormatter: (column, index, { sortElement, filterElement }) => {
                    return (
                        <div className="">
                            <Row>
                                <Col className="text-center justify-content-center">
                                    <div className="mb-1">Yesterday</div>
                                    <div>(vs Last Week)</div>
                                </Col>
                            </Row>
                        </div>
                    );
                }
            },
            /*3*/ {
                dataField: "last_week_sales",
                text: "Last Week Sales",
                type: "number",
                sort: false,
                hidden: true,
                formatter: "dollarFormatter",
            },
            /*4*/ {
                dataField: "sales_30d",
                text: "30 Day Sales",
                type: "number",
                sort: false,
                formatter: (cell, row, rowIndex) => {
                    let currency_formatter = getNumberFormatter('currency', brand, currency);
                    let int_formatter = getNumberFormatter('number', brand, currency);
                    
                    return (
                        <div className="">
                            <Row>
                                <Col className="text-center justify-content-center">
                                    <div className="mb-1">{currency_formatter.format(cell)}</div>
                                    <div>({int_formatter.format(row.units_30d)})</div>
                                </Col>
                            </Row>
                        </div>
                    );
                },
                headerFormatter: (column, index, { sortElement, filterElement }) => {
                    return (
                        <div className="">
                            <Row>
                                <Col className="text-center justify-content-center">
                                    <div className="mb-1">30 Day Sales</div>
                                    <div>(Units)</div>
                                </Col>
                            </Row>
                        </div>
                    );
                }
            },
            /*5*/ {
                dataField: "units_30d",
                text: "30 Day Units",
                type: "number",
                sort: false,
                hidden: true,
                formatter: "intFormatter",
            },
            /*6*/ {
                dataField: "bbx_30d",
                text: "30 Days BuyBox",
                type: "number",
                sort: false,
                headerStyle: (colum, colIndex) => {
                    return { minWidth: "140px" };
                },
                formatter: (cell, row, rowIndex) => {
                    let formatter = getNumberFormatter('percent', brand, currency);
                    
                    return (
                        <Row>
                            <Col className="text-center justify-content-center">
                                {formatter.format(cell)}
                            </Col>
                        </Row>
                    );
                },
                headerFormatter: (column, index, { sortElement, filterElement }) => {
                    return (
                        <div className="">
                            <Row>
                                <Col className="text-center justify-content-center">
                                    <div className="mb-1">30 Days Weighted BuyBox Avg</div>
                                    <div className="text-sm text-gray">(when in stock)</div>
                                </Col>
                            </Row>
                        </div>
                    );
                },
                csvFormatter: (cell, row, rowIndex) => {
                    return cell;
                },
            },
            /*7*/ {
                dataField: "oos_impact_30d",
                text: "30 Days Out of stock impact",
                type: "number",
                sort: false,
                headerStyle: (colum, colIndex) => {
                    return { width: "150px" };
                },
                headerFormatter: (column, index, { sortElement, filterElement }) => {
                    return (
                        <div className="">
                            <Row>
                                <Col className="text-center justify-content-center">
                                    <div>30 Days Out of stock impact</div>
                                </Col>
                            </Row>
                        </div>
                    );
                },
                formatter: (cell, row, rowIndex) => {
                    let formatter = getNumberFormatter('currency', brand, currency);
                    
                    return (
                        <Row>
                            <Col className="text-center justify-content-center">
                                {formatter.format(cell)}
                            </Col>
                        </Row>
                    );
                }
            },
            /*8*/ {
                dataField: "days_on_hand_30d",
                text: "Avg Days on Hand",
                type: "number",
                sort: false,
                headerFormatter: (column, index, { sortElement, filterElement }) => {
                    return (
                        <div className="">
                            <Row>
                                <Col className="text-center justify-content-center">
                                    <div>Avg Days on Hand</div>
                                </Col>
                            </Row>
                        </div>
                    );
                },
                formatter: (cell, row, rowIndex) => {
                    let formatter = getNumberFormatter('number', brand, currency);
                    
                    return (
                        <Row>
                            <Col className="text-center justify-content-center">
                                {formatter.format(cell)}
                            </Col>
                        </Row>
                    );
                }
            },
        ];

        return (
            <BootstrapTablesWrapper
                data={data ?? []}
                keyField="row"
                description={''}
                tableOnly={true}
                minHeight="170px"
                headerClasses={'bg-gray header-align-top'}
                hasPagination={false}
                hasFilter={false}
                hasSearch={false}
                defaultSorted={[]}
                columns={tableColumns}
                columnModal={'none'}
            />
        )
    }

    return (
        <div>
            {dataLoading ? (
                <WidgetSpinner />
            ) : dataError === true ? (
                <DataError displayFull={false} response={responseError}></DataError>
            ) : (response && renderBrandTable(response?.today ?? [])
            )}
        </div>
    )
};

export default BrandTodayTable;
