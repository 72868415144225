import React, { useContext, useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import moment from "moment";

import BootstrapTablesWrapper from "../BootstrapTablesWrapper";
import WidgetSpinner from "../../../components/helper/WidgetSpinner";

import { brandTopLine } from "../../../api/brandData";
import { AuthContext } from "../../../contexts/AuthContext";
import { BrandContext } from "../../../contexts/BrandContext";
import { HeaderContext } from "../../../contexts/HeaderContext";
import { ToastContext } from "../../../contexts/ToastContext";

const tableColumns = [
    /*0*/ { dataField: "row", text: "#", type: "number", hidden: true },
    /*1*/ {
        dataField: "tf_totalSales",
        text: "Total Sales",
        type: "number",
        sort: false,
        formatter: "dollarFormatter",
    },
    /*2*/ {
        dataField: "previous_sales",
        text: "Previous Days",
        type: "number",
        sort: false,
        formatter: "dollarFormatter",
    },
    /*3*/ {
        dataField: "previous_sales_change",
        text: "Sales Change ($)",
        type: "number",
        sort: false,
        formatter: "tableColorDollarFormatter",
    },
    /*4*/ {
        dataField: "previous_sales_change_per",
        text: "Sales Change (%)",
        type: "number",
        sort: false,
        formatter: "percentColorFormatter",
    },
    /*5*/ {
        dataField: "tf_totalUnits",
        text: "Total Units",
        type: "number",
        sort: false,
        formatter: "intFormatter",
    },
    /*6*/ {
        dataField: "tf_organicUnits",
        text: "Organic Units",
        type: "number",
        sort: false,
        formatter: "intFormatter",
    },
    /*7*/ {
        dataField: "tf_paidUnits",
        text: "Paid Units",
        type: "number",
        sort: false,
        formatter: "intFormatter",
    },
    /*8*/ {
        dataField: "tf_snsUnits",
        text: "SNS Units",
        type: "number",
        sort: false,
        formatter: "intFormatter",
    },
    /*9*/ {
        dataField: "tf_30DSales",
        text: "30 Day Sales",
        type: "number",
        sort: false,
        formatter: "dollarFormatter",
        headerClasses: () => {
            return "bg-gray-light";
        },
        classes: () => {
            return "bg-gray-light";
        },
    },
    /*10*/ {
        dataField: "tf_30Dbbx",
        text: "30 Day Avg BBX",
        type: "number",
        sort: false,
        formatter: "percentBBXColorFormatter",
        headerClasses: () => {
            return "bg-gray-light";
        },
        classes: () => {
            return "bg-gray-light";
        },
    },
];

const ToplineSalesData = (props) => {
    const { token } = useContext(AuthContext);
    const { startDate, endDate, timeFrame, selectedDefaultDates, currency, marketplace } = useContext(HeaderContext);

    const [responseData, setResponseData] = useState();
    const [widgetLoading, setWidgetLoading] = useState(true);
    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);

    const {
        showConfigButton = false,
        headerTitle = "Brand Level Sales",
        headerSubtitle = "Shows sales metrics in dollars and units and by channel. 30 day sales rate and buy box percentage at end columns.",
        widgetData = null,
        widgetDataLoading = null,
    } = props;

    useEffect(() => {
        const getData = async () => {
            if (widgetData !== null) {
                setWidgetLoading(true);
                setResponseData(widgetData);
                setWidgetLoading(false);
            } else {
                setWidgetLoading(true);
                try {
                    // Use snapshot / time period data
                    const res = await brandTopLine(token, {
                        brandid: brand.brandId,
                        tf_s: moment(selectedDefaultDates.tp_s).format('YYYY-MM-DD'),
                        tf_e: moment(selectedDefaultDates.tp_e).format('YYYY-MM-DD'),
                        wow: selectedDefaultDates.timeframe,
                        currency: currency,
                        marketplace: marketplace,
                    });

                    setResponseData(res.data);

                    // console.log(res.data);
                    setWidgetLoading(false);
                } catch (err) {
                    setWidgetLoading(false);
                    toast(err.message);
                }
            }
        };

        getData();
    }, [
        // Context
        token,
        brand.brandId,
        startDate,
        endDate,
        timeFrame,
        toast,
        currency,
        marketplace,
        selectedDefaultDates,

        // Props
        widgetData,
        widgetDataLoading,
    ]);

    const renderChart = () => {
        let newCols = tableColumns;
        if (responseData?.info !== undefined) {
            if (responseData?.info?.pt !== undefined) {
                let ptTimeframeInfo = responseData?.info.pt;
                // Previous Timeframe Columns
                newCols[2].text = `Previous ${ptTimeframeInfo.diff} Days`;
                newCols[2].headerAttrs = {
                    title: `${ptTimeframeInfo.pt_s} - ${ptTimeframeInfo.pt_e}: $${ptTimeframeInfo.pt_sales}`,
                };
                newCols[3].headerAttrs = {
                    title: `${ptTimeframeInfo.pt_s} - ${ptTimeframeInfo.pt_e}: $${ptTimeframeInfo.pt_sales}`,
                };
                newCols[4].headerAttrs = {
                    title: `${ptTimeframeInfo.pt_s} - ${ptTimeframeInfo.pt_e}: $${ptTimeframeInfo.pt_sales}`,
                };
            }
            if (responseData?.info?.pm !== undefined) {
                let pmTimeframeInfo = responseData?.info.pm;
                // 30 Day Columns
                newCols[9].headerAttrs = { title: `${pmTimeframeInfo.pm_s} - ${pmTimeframeInfo.pm_e}` };
                newCols[10].headerAttrs = { title: `${pmTimeframeInfo.pm_s} - ${pmTimeframeInfo.pm_e}` };
            }
            // console.log(responseData?.info, newCols);
            // setTableColumns(newCols);
        }

        return (
            <BootstrapTablesWrapper
                data={responseData?.tableData}
                keyField="row"
                columns={newCols}
                minHeight="170px"
                tableOnly={true}
                columnModal={true}
                hasPagination={false}
                hasFilter={false}
                hasSearch={false}
            />
        );
    };

    return (
        <Card className="h-100" /*style={{ overflow: "auto" }}*/>
            <Card.Header className="bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <h5>{headerTitle}</h5>
                        {headerSubtitle !== "" ? <p className="body-1 text-secondary mb-0">{headerSubtitle}</p> : ""}
                    </div>
                    <div className="ml-auto ">
                        {showConfigButton === true ? (
                            <button
                                type="button"
                                className="btn btn-primary btn-sm "
                                onClick={() => {
                                    //TODO: Add Configuration Modal
                                }}
                            >
                                Config
                            </button>
                        ) : null}
                    </div>
                </div>
            </Card.Header>

            <Card.Body className="pb-0">
                <div className="w-100">{widgetLoading ? <WidgetSpinner /> : renderChart()}</div>
            </Card.Body>
        </Card>
    );
};

export default ToplineSalesData;
