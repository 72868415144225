import React, { useContext, useEffect, useState } from "react";

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker,createStaticRanges } from 'react-date-range';
import { addDays,subDays,subMonths,subYears } from 'date-fns';
import { enUS } from 'date-fns/locale';
import {HeaderContext} from "../../contexts/HeaderContext";
import {Button, Spinner} from "react-bootstrap";

const DateRangeSelect = (props) => {
    const {
        dataLoading,
        pageTimeframe,
        selectedDefaultDates
    } = useContext(HeaderContext);


    const staticRanges = createStaticRanges([
        {
            label: 'Month to Date',
            range: () => ({
                startDate: new Date().setDate(1),
                endDate: subDays(new Date(), 1),
            }),
        },
        {
            label: 'Last 30 Days',
            range: () => ({
                startDate: subDays(new Date(), 30),
                endDate: subDays(new Date(), 1),
            }),
        },
        {
            label: 'Last Month',
            range: () => ({
                startDate: subMonths(new Date(), 1).setDate(1),
                endDate: new Date().setDate(0),
            }),
        },
        {
            label: 'Last 3 Months',
            range: () => ({
                startDate: subMonths(new Date(), 4),
                endDate: subDays(new Date(), 1),
            }),
        },
        {
            label: 'Last 6 Months',
            range: () => ({
                startDate: subMonths(new Date(), 7),
                endDate: subDays(new Date(), 1),
            }),
        },
        {
            label: 'Last Year',
            range: () => ({
                startDate: new Date().setFullYear(new Date().getFullYear() - 1,0,1),
                endDate: new Date().setFullYear(new Date().getFullYear() - 1,11,31),
            }),
        },
        {
            label: 'Year to Date',
            range: () => ({
                startDate: new Date().setMonth(0,1),
                endDate: subDays(new Date(), 1),
            }),
        },
        {
            label: 'Account Lifetime',
            range: () => ({
                startDate: new Date(2015, 0, 1),
                endDate: subDays(new Date(), 1),
            }),
        },
    ]);

    const [calDate, setCalDate] = useState([
        {
            startDate: pageTimeframe === "snapshot" ? selectedDefaultDates.tp_s.toDate() : selectedDefaultDates.tf_s.toDate(),
            endDate: pageTimeframe === "snapshot" ? selectedDefaultDates.tp_e.toDate() : selectedDefaultDates.tf_e.toDate(),
            key: 'selection'
        }
    ]);

    const handleSelect = (ranges) => {
        setCalDate([ranges.selection]);
        props.onChange(ranges.selection);
    }

    const clearFilter = () => {
        setCalDate([
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection'
            }
        ])
    }

    const setFilter = () => {
        props.onSelectDate(calDate);
    }
    const applyButton = () => {
        return (
            <Button
                className="btn btn-info bg-accent-default btn-primary"
                onClick={() => setFilter()}
                disabled={dataLoading}
            >
                {dataLoading ? (
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"></Spinner>
                ) : (
                    <span>Apply Time Range</span>
                )}
            </Button>
        );
    };

    return (
        <div>
            <div className="btn-filter-container">
                <div className="filter-button-group">
                    <button className="btn btn-info bg-accent-default btn-primary" onClick={clearFilter}>Clear Filter</button>
                    {applyButton()}
                </div>
            </div>
            <DateRangePicker
                onChange={handleSelect}
                locale={enUS}
                months={2}
                direction="horizontal"
                maxDate={new Date()}
                ranges={calDate}
                staticRanges={staticRanges}
                inputRanges={[]}
                calendarFocus="backwards"
                showDateDisplay={false}
                preventSnapRefocus={true}
                // input={true}
            />
        </div>
    );
};

export default DateRangeSelect;
