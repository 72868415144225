import React, { useContext, useState, useEffect, useCallback, useRef } from "react";
import { Row, Col, ProgressBar } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import moment from "moment";

import { topProducts } from "../../../api/brandData";
import { AuthContext } from "../../../contexts/AuthContext";
import { BrandContext } from "../../../contexts/BrandContext";
// import { SidebarContext } from "../../../contexts/SidebarContext";
import { HeaderContext } from "../../../contexts/HeaderContext";
import { ToastContext } from "../../../contexts/ToastContext";

import useIsMountedRef from "../../../components/helper/isMountedRef";
import WidgetSpinner from "../../../components/helper/WidgetSpinner";
import { percentFormatter, DollarFormatter } from "../../../components/helper/NumberFormatters";

import {
    yesterday,
    YTD_s,
    last_MTD_s,
    last_MTD_e,
} from "../../../components/helper/DateVars";

// const useResize = (myRef) => {
//     const [width, setWidth] = useState(0)
//     const [height, setHeight] = useState(0)
    
//     const handleResize = useCallback(() => {
//         setWidth(myRef.current.firstChild.offsetWidth)
//         setHeight(myRef.current.firstChild.offsetHeight)
//     }, [myRef])
  
//     useEffect(() => {
//       window.addEventListener('load', handleResize)
//       window.addEventListener('resize', handleResize)
  
//       return () => {
//         window.removeEventListener('load', handleResize)
//         window.removeEventListener('resize', handleResize)
//       }
//     }, [myRef, handleResize])
  
//     return { width, height }
// }

function RenderProgressBarLabel(props) {
    // const { collapsed } = useContext(SidebarContext);

    const progressBarRef = useRef(null);
    const progressLabelRef = useRef(null);
    
    const minLeft = 30;
    
    const [progressBarWidth, setProgressBarWidth] = useState(0);
    const [labelWidth, setLabelWidth] = useState(0);
    const [displayLeft, setDisplayLeft] = useState(minLeft);
    const [textColor, setTextColor] = useState('text-white');

    const {
        brand,
        currency,
        max,
        value,
    } = props;

    const setWidth = (ref) => {
        if (ref.current) {
            setProgressBarWidth(ref.current ? ref.current.firstChild.offsetWidth : 0);
            // console.log('bar width', ref.current ? ref.current.firstChild.offsetWidth : 0);
        }
    }

    useEffect(() => {
        setWidth(progressBarRef)
    }, [progressBarRef]);

    const handleResize = useCallback(() => {
        setWidth(progressBarRef)
    }, [progressBarRef])

    useEffect(() => {
        window.addEventListener('load', handleResize)
        window.addEventListener('resize', handleResize)
    
        return () => {
            window.removeEventListener('load', handleResize)
            window.removeEventListener('resize', handleResize)
        }
    }, [progressBarRef, handleResize])

    useEffect(() => {
        if (progressLabelRef.current) {
            setLabelWidth(progressLabelRef.current ? progressLabelRef.current.offsetWidth : 0);
            // console.log('label width', progressLabelRef.current ? progressLabelRef.current.offsetWidth : 0);
        }
    }, [progressLabelRef]);

    useEffect(() => {
        let newDisplayLeft = minLeft;

        if (labelWidth && progressBarWidth) {
            const insideBar = ((progressBarWidth - labelWidth - minLeft) > 0 ? true : false);

            if (insideBar) {
                newDisplayLeft = progressBarWidth - labelWidth;
            }
            else {
                newDisplayLeft = progressBarWidth + minLeft;
                setTextColor('text-dark');
            }
        }

        setDisplayLeft(newDisplayLeft);

        // console.log(progressBarWidth, labelWidth, newDisplayLeft);

    }, [labelWidth, progressBarWidth]);

    const getLabel = () => {
        // console.log(displayLeft)

        return (
            <div className="d-flex flex-row justify-content-end">
                <div className="d-flex px-2">
                    <p className="mb-0 text-bold pr-2">{DollarFormatter(value, currency, brand?.currencyLocale)}</p>
                    <p className="mb-0 text-gray-dark">{percentFormatter.format(value / max)}</p>
                </div>
            </div>
        )
    }

    return (
        <div style={{height: '2em'}}>
            <ProgressBar 
                now={value} 
                min={0} 
                max={max}
                className=""
                ref={progressBarRef}
            />
            <span 
                className={`position-absolute body-2 ${textColor}`}
                style={{ top: '2px', left: `${displayLeft}px` }}
                ref={progressLabelRef}
            >
                {getLabel()}
            </span>
        </div>
    )
}

function TopProducts(props) {
    const { token } = useContext(AuthContext);
    const { selectedDefaultDates, dataLoading, currency, marketplace } = useContext(HeaderContext);

    const [data, setData] = useState([]);
    const [widgetLoading, setWidgetLoading] = useState(true);
    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);

    const isMountedRef = useIsMountedRef();

    const [widgetStartDate, setWidgetStartDate] = useState(selectedDefaultDates?.tf_s);
    const [widgetEndDate, setWidgetEndDate] = useState(selectedDefaultDates?.tf_e);
    const [widgetMetric, setWidgetMetric] = useState({
        sales: true,
        units: false,
        orders: false,
    });
    const [widgetTitle, setWidgetTitle] = useState("Top Performing Products");

    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!dataLoading) {
            if (props.timeframe !== undefined && props.timeframe !== "") {
                if (props.timeframe === "MTD") {
                    setWidgetTitle("Top Products (Last Month)");

                    setWidgetStartDate(last_MTD_s);
                    setWidgetEndDate(last_MTD_e);
                } else if (props.timeframe === "YTD") {
                    setWidgetTitle("Top Products (YTD)");

                    setWidgetStartDate(YTD_s);
                    setWidgetEndDate(yesterday);
                }
            } else {
                setWidgetStartDate(selectedDefaultDates.tf_s);
                setWidgetEndDate(selectedDefaultDates.tf_e);
            }

            // If all are set to false, set sales = true; Keep atleast one
            if (widgetMetric.sales !== "true" && widgetMetric.units !== "true" && widgetMetric.orders !== "true") {
                setWidgetMetric({ ...widgetMetric, sales: true });
            }

            setInitialized(true);
        }
    }, [setInitialized, props.timeframe, dataLoading]);

    useEffect(() => {
        const getData = async () => {
            setWidgetLoading(true);
            try {
                const res = await topProducts(token, {
                    brandid: brand.brandId,
                    tf_s: moment(widgetStartDate).format('YYYY-MM-DD'),
                    tf_e: moment(widgetEndDate).format('YYYY-MM-DD'),
                    top: 10,
                    timeframe: selectedDefaultDates.timeframe,
                    currency: currency,
                    marketplace: marketplace,
                });

                if (isMountedRef.current) {
                    setData(res.data);
                    setWidgetLoading(false);
                }
            } catch (err) {
                setWidgetLoading(false);
                toast(err.message);
            }
        };

        if (initialized) {
            getData();
        }
    }, [
        token,
        brand.brandId,
        toast,
        dataLoading,
        selectedDefaultDates,
        initialized,
        widgetStartDate,
        widgetEndDate,
        currency,
        marketplace,
    ]);

    const renderChart = () => {
        return (
            <div>
                {data.data &&
                    data?.data.map((item, index) => (
                        <Row key={index} className="align-items-center" style={{minHeight: '50px'}}>
                            <Col sm={3} className="align-items-center">
                                <label className="mb-0">{item.name}</label>
                            </Col>
                            <Col sm={9}>
                                <RenderProgressBarLabel
                                    brand={brand}
                                    currency={currency}
                                    max={data?.top_sales}
                                    value={item.sales}
                                />
                            </Col>
                        </Row>
                    ))}
            </div>
        );
    };

    return (
        <div className="card h-100" /*style={{ overflow: "auto" }}*/>
            <div className="card-header white bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <p className="small mb-0" style={{ fontSize: "16px" }}>
                            <strong>{widgetTitle}</strong>
                        </p>
                    </div>
                    <div className="ml-auto ">
                        {props.showConfigButton === true ? (
                            <button
                                type="button"
                                className="btn btn-primary btn-sm "
                                onClick={() => {
                                    //TODO: Add Configuration Modal
                                }}
                            >
                                Config
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>

            {widgetLoading ? (
                <div className="card-body">
                    <WidgetSpinner />
                </div>
            ) : (
                <SimpleBar forceVisible="y" autoHide={false} className="mt-2" style={{ overflow: "hidden" }}>
                    <div className="card-body w-100 pt-0 pb-0">{renderChart()}</div>
                </SimpleBar>
            )}
        </div>
    );
}

export default TopProducts;
