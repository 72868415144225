import React, { useEffect, useState, useContext } from "react";
import { Formik } from "formik";
import { Row, Col, Card, Form, Button, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import Select from "react-select";

import { AuthContext } from "../../contexts/AuthContext";
import { HeaderContext } from "../../contexts/HeaderContext";
import { ToastContext } from "../../contexts/ToastContext";

import useIsMountedRef from "../../components/helper/isMountedRef";

import { inviteClient, resendInviteClient, getClientOrganizations } from "../../api/user";

const OrganizationFilter = (props) => {
    const { token, apiUser } = useContext(AuthContext);

    const { toast } = useContext(ToastContext);
    const isMountedRef = useIsMountedRef();

    const [widgetLoading, setWidgetLoading] = useState(true);

    const [clientOrganizations, setClientOrganizations] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState(props.value);

    useEffect(() => {
        if (apiUser.organizationid === 401) {
            setWidgetLoading(true);

            const getData = async () => {
                try {
                    const res = await getClientOrganizations(apiUser, token, {});

                    if (isMountedRef.current) {
                        setWidgetLoading(false);

                        setClientOrganizations(res.data);
                    }
                } catch (err) {
                    toast('Unable to get Organizations');
                    setWidgetLoading(false);
                }
            }

            getData();
        }
    }, [apiUser, token])

    const onOrganizationChange = (option) => {
        setSelectedOrganization(option);
        props.onChange(option);
    };

    // <Select
    //     className="text-dark w-100 rounded-0"
    //     placeholder="Select vParts"
    //     value={selectedVparts}
    //     options={vparts}
    //     name="vpartSelect"
    //     getOptionLabel={(option) => `${(option.vpartAlt !== null && option.vpartAlt !== undefined && option.vpartAlt !== '' ? option.vpartAlt : option.vpart)} - ${option.shortTitle}`}
    //     // getOptionValue={(option) => option.vpart}
    //     // inputValue={(option) => `${(option.vpartAlt !== null && option.vpartAlt !== undefined && option.vpartAlt !== '' ? option.vpartAlt : option.vpart)} - ${option.shortTitle}`}
    //     onChange={(option) => onOrganizationChange(option)}
    //     isMulti={true}
    //     allowSelectAll={true}
    //     isSearchable={true}
    //     isLoading={widgetLoading}
    //     noOptionsMessage={(inputValue) => "No Brand Vparts"}
    // />
    return (
        <Select
            className="text-dark w-100"
            placeholder="Select an Organization"
            value={selectedOrganization}
            options={clientOrganizations}
            name="organizationid"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.organizationid}
            onChange={(option) => onOrganizationChange(option)}
            isMulti={false}
            isSearchable={true}
            isLoading={widgetLoading}
            noOptionsMessage={(inputValue) => "No Client Organization"}
        />
    );
};

const InviteClient = (props) => {
    const { token, apiUser } = useContext(AuthContext);
    const { setPageTitle, setPageTimeframe } = useContext(HeaderContext);
    
    const { toast } = useContext(ToastContext);
    const [loading, setLoading] = useState(false);
    const isMountedRef = useIsMountedRef();

    const [selectedOrganization, setSelectedOrganization] = useState(undefined);

    useEffect(() => {
        setPageTitle("Invite Client");
        setPageTimeframe(null);
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    });

    const renderClientForm = () => {
        const validationSchema = Yup.object({
            email: Yup.string().required("Email is required").email("Must be a valid email address"),
            name: Yup.string().required("Name is required"),
        });

        const onSuccess = async (values) => {
            setLoading(true);

            const params = {
                organizationid: selectedOrganization.organizationid,
                name: values.name,
                email: values.email,
            }

            const confirm = window.confirm(`Add ${params.name} to ${selectedOrganization.name}?`);
            if (confirm) {
                if (isMountedRef.current) {
                    try {
                        const res = await inviteClient(apiUser, token, params);
                        toast(res.data.message);
                        setLoading(false);
                    } catch (err) {
                        toast("Error Sending Invite");
                        setLoading(false);
                    }
                }
            } else {
                setSelectedOrganization(undefined);
                setLoading(false);
            }
        };

        return apiUser.organizationid === 401 ? (
            <Formik
                onSubmit={onSuccess}
                initialValues={{
                    name: "",
                    email: "",
                }}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, handleChange, values, errors }) => {
                    return (
                        <Form style={{'minWidth': '500px'}} noValidate onSubmit={handleSubmit}>
                            <Form.Group controlId="formBasicSelect">
                                <Form.Label>Client Organization</Form.Label>
                                <OrganizationFilter onChange={(value) => setSelectedOrganization(value)} value={selectedOrganization} />
                            </Form.Group>
                            <Form.Group controlId="formBasicText">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Please enter full name"
                                    value={values.name}
                                    onChange={handleChange}
                                    isInvalid={errors.name}
                                />
                                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Please enter your email"
                                    autoComplete="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={errors.email}
                                />
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            </Form.Group>

                            <Button
                                className="btn btn-lg btn-primary btn-block"
                                variant="primary"
                                disabled={loading}
                                type="submit"
                            >
                                {loading ? (
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        role="status"
                                        aria-hidden="true"
                                    ></Spinner>
                                ) : (
                                    "Send Invite To Client"
                                )}
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        ) : null;
    };

    const renderResendClientForm = () => {
        const validationSchema = Yup.object({
            email: Yup.string().required("Email is required").email("Must be a valid email address"),
        });

        const onSuccessResend = async (values) => {
            setLoading(true);

            const params = {
                email: values.email,
            }

            if (isMountedRef.current) {
                try {
                    const res = await resendInviteClient(apiUser, token, params);
                    toast(res.data.message);
                    setLoading(false);
                } catch (err) {
                    toast("Error Resending Invite");
                    setLoading(false);
                }
            }
        };

        return apiUser.organizationid === 401 ? (
            <Formik
                onSubmit={onSuccessResend}
                initialValues={{
                    email: "",
                }}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, handleChange, values, errors }) => {
                    return (
                        <Form style={{'minWidth': '500px'}} noValidate onSubmit={handleSubmit}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Please enter your email"
                                    autoComplete="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={errors.email}
                                />
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            </Form.Group>

                            <Button
                                className="btn btn-lg btn-primary btn-block"
                                variant="primary"
                                disabled={loading}
                                type="submit"
                            >
                                {loading ? (
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        role="status"
                                        aria-hidden="true"
                                    ></Spinner>
                                ) : (
                                    "Resend Invite To User"
                                )}
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        ) : null;
    };

    return (apiUser.organizationid === 401 && (apiUser.role === "Admin" || apiUser.role === "Manager")) ? (
        <main className="d-flex h-100 justify-content-center align-items-center">
            <Col className="d-flex justify-content-center">
                <Row className="mt-4">
                    <Col>
                        <Card>
                            <Card.Header>
                                <span className="h4">Invite Clients</span>
                            </Card.Header>
                            <Card.Body>
                                {renderClientForm()}
                            </Card.Body>
                        </Card>

                        <Card className="mt-4">
                            <Card.Header>
                                <span className="h4">Resend Invite</span>
                            </Card.Header>
                            <Card.Body>
                                {renderResendClientForm()}
                            </Card.Body>
                        </Card>
                        
                    </Col>
                </Row>
            </Col>
        </main>
    ) : null;
};

export default InviteClient;