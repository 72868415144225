import React, { useContext, useState } from "react";
import { Formik } from "formik";
import { Form, Button, Container, Spinner } from "react-bootstrap";
import * as Yup from "yup";

import { register } from "../../api/user";

import { AuthContext } from "../../contexts/AuthContext";
import { ToastContext } from "../../contexts/ToastContext";

import logo from "../../assets/logo.svg";
import "./signin.css";

import CopyrightNotice from "../../components/helper/CopyrightNotice";

const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required").email("Must be a valid email address"),
    clientKey: Yup.string().required("Key is required").min("4", "Key must be at least 4 characters"),
});

const Register = ({ history }) => {
    const { setAuthToken, clearUserSessionCookies } = useContext(AuthContext);
    const { toast } = useContext(ToastContext);
    const [loading, setLoading] = useState(false);

    const renderForm = () => {
        const onSuccess = async (values) => {
            setLoading(true);
            let token = "";
            try {
                const res = await register(values);
                if (res?.data?.jwt !== undefined) {
                    token = res.data.jwt;
                    clearUserSessionCookies();
                    setAuthToken(token);
                    setLoading(false);
                    toast("Successfully logged in~");
                    history.replace("/");
                } else {
                    toast("Unable to register!");
                }
            } catch (err) {
                toast("Unable to register! Check Code. Check Email.");
                setLoading(false);
            }
        };

        return (
            <div className="d-flex align-items-center flex-column justify-content-center" style={{ height: "100vh" }}>
                <div className="text-center w-100">
                    <Formik
                        onSubmit={onSuccess}
                        initialValues={{
                            name: "",
                            email: "",
                            clientKey: "",
                        }}
                        validationSchema={validationSchema}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors }) => {
                            return (
                                <Form className="form-signin" noValidate onSubmit={handleSubmit}>
                                    <img src={logo} alt="Catapult" width="520" height="133"></img>
                                    <hr />
                                    <h3 className="mb-3">Register</h3>
                                    <Form.Group controlId="formBasicText">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            placeholder="Please enter your full name"
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder="Please enter your email"
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword2">
                                        <Form.Label>Client Key</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="clientKey"
                                            value={values.clientKey}
                                            onChange={handleChange}
                                            isInvalid={errors.clientKey}
                                            placeholder="Client Key"
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.clientKey}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Button
                                        className="btn btn-lg btn-primary btn-block"
                                        variant="primary"
                                        disabled={loading}
                                        type="submit"
                                    >
                                        {loading ? (
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                role="status"
                                                aria-hidden="true"
                                                // animation="border"
                                            ></Spinner>
                                        ) : (
                                            "Submit"
                                        )}
                                    </Button>
                                </Form>
                            );
                        }}
                    </Formik>
                    
                    <CopyrightNotice/>
                </div>
            </div>
        );
    };

    return <Container fluid>{renderForm()}</Container>;
};

export default Register;
