import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";

import { BrandContext } from "../../contexts/BrandContext";
import { HeaderContext } from "../../contexts/HeaderContext";

import BootstrapTablesWrapper from "../../components/dataRender/BootstrapTablesWrapper";

import { getNumberFormatter } from "../../components/helper/NumberFormatters";
import { formatMonthDateStr, formatDateStr } from "../../components/helper/DateVars";

import ComparativeOptions from "./ComparativeOptions";

import upArrow from "../../assets/icons/svg/upArrowTilted.svg";
import downArrow from "../../assets/icons/svg/downArrowTilted.svg";

const renderArrowIcon = (icon) => {
    return (
      <img
        className="bg-transparent"
        src={icon}
        alt={''}
        height={'12px'}
        width={'12px'}
      ></img>
    );
};

const ComparativeTable = (props) => {
    // console.log('ComparativeTable', props);

    const {
        data,
        metadata,
        type
    } = props;

    const { brand } = useContext(BrandContext);
    const { currency } = useContext(HeaderContext);

    const [selectedMetrics, setSelectedMetrics] = useState({'traffic': true});
    const [selectedTimeframe, setSelectedTimeframe] = useState('ytd');
    const [selectedComparativeView, setSelectedComparativeView] = useState('pct');

    const [tableData, setTableData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [defaultSorted, setDefaultSorted] = useState([]);
    const [reRenderTable, setReRenderTable] = useState(false);

    const [showDiscontinued, setShowDiscontinued] = useState(true);

    useEffect(() => {

        const {columns, sort} = setMetricColumns();
        const newTableData = ((data !== undefined) ?
            Object.values(data[selectedTimeframe] ?? {})
            : []);

        setTableColumns(columns);
        setDefaultSorted(sort);
        setTableData(newTableData);
        setReRenderTable(prev => !prev);

        // console.log('tableRender', tableColumns, defaultSorted, reRenderTable);

    }, [data, selectedMetrics, selectedTimeframe, selectedComparativeView]);

    const generateMetricColumn = (column) => {
        const {metric, hidden} = column;
        // console.log(column, metadata?.metrics[metric]);
        if (metadata?.metrics[metric] !== undefined) {
            const metric_prop = metadata['metrics'][metric];
            return [{
                dataField: metric,
                text: metric_prop.name,
                hidden: hidden ?? false,
                type: "number",
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { minWidth: "150px" };
                },
                sortValue: (cell, row) => (selectedComparativeView === 'abs' ? cell.tf : cell.change_pct),
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    if (order === 'asc') return a - b;
                    else return b - a;
                },
                formatter: (cell, row, rowIndex) => {
                    let formatter = getNumberFormatter(metric_prop.format, brand, currency);
                    let abs_formatter = getNumberFormatter('number', brand, currency);
                    let pct_formatter = getNumberFormatter('percent_whole', brand, currency);
                    let color = (cell.change_pct >= 0 ? 'text-success' : 'text-danger');
                    
                    let comparativeDisplay = (<div></div>);
                    if (selectedComparativeView === 'abs') {
                        comparativeDisplay = (
                            <div className="d-flex flex-row justify-content-around w-100">
                                <div className="">{formatter.format(cell.tf)}</div>
                                <div className={`text-center ${color} px-2`}>{cell.change >= 0 ? renderArrowIcon(upArrow) : renderArrowIcon(downArrow)}</div>
                                <div className="text-gray-dark">{formatter.format(cell.pt)}</div>
                            </div>
                        );
                    }
                    else {
                        comparativeDisplay = (
                            <div className="d-flex flex-row justify-content-around w-100 px-2">
                                <div className={`text-center ${color}`}>{cell.change >= 0 ? renderArrowIcon(upArrow) : renderArrowIcon(downArrow)}</div>
                                <div className={`text-center ${color} px-2`}>{pct_formatter.format(cell.change_pct)}</div>
                                <div className="">{formatter.format(cell.tf)}</div>
                            </div>
                        );
                    }

                    return (
                        <div className="d-flex flex-nowrap w-100">
                            <div className="d-flex flex-row justify-content-around w-100">
                                {comparativeDisplay}
                            </div>
                        </div>
                    );
                },
                csvFormatter: (cell, row, rowIndex) => {
                    return cell.tf;
                },
            },
            {
                dataField: `${metric}_previous`,
                text: `${metric_prop.name} Previous`,
                sort: true,
                hidden: true,
                type: "number",
                sortValue: (cell, row) => row[metric].pt,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    if (order === 'asc') return a - b;
                    else return b - a;
                },
                formatter: (cell, row, rowIndex) => {
                    let formatter = getNumberFormatter(metric_prop.format, brand, currency);

                    return formatter.format(row[metric].pt);
                },
                csvFormatter: (cell, row, rowIndex) => {
                    return row[metric].pt;
                },
            }];
        }
        
        return [];
    }

    const handleDiscontinuedToggle = (value) => {
        // console.log('handleDiscontinuedToggle', value);

        if (tableData.length > 0) {
            // let tempDataTable = tableData;
            // Off. = Exclude discontinued
            if (value === false) {
                let tempDataTable = tableData.filter((row) => row.is_discontinued === 0);
                // console.log('newData', tempDataTable);
                setTableData(tempDataTable);
            }
            else {
                // On. = Include Discontinued = Reset data
                const newTableData = ((data !== undefined) ?
                    Object.values(data[selectedTimeframe] ?? {})
                    : []);

                setTableData(newTableData);
            }
        }
        
        setShowDiscontinued(value);
    }

    const setMetricColumns = () => {
        let sort = [{ dataField: "vpart", order: "asc" }];
        let columns = [];

        if (type === 'vpart') {
            columns = [
                {
                    dataField: "vpart",
                    text: "Products",
                    type: "string",
                    sort: true,
                    hidden: false,
                    filter: "textFilter",
                    headerStyle: (colum, colIndex) => {
                        return { minWidth: "270px" };
                    },
                    headerFormatter: (column, index, { sortElement, filterElement }) => {
                        return (
                            <div className="d-flex flex-column w-100">
                                <Row className="mb-2">
                                    <Col>
                                        <p className="mb-0">Search Products {sortElement}</p>
                                    </Col>
                                    {/* <Col>
                                        <div className="d-flex align-items-center">
                                            <label className="mb-0 mr-1" style={{whiteSpace:'nowrap'}}>Include Discontinued Items</label>
                                            <Form.Check 
                                                id={`include-discontinued-toggle-switch`}
                                                type="switch"
                                                disabled={false}
                                                checked={showDiscontinued}
                                                onChange={(e) => handleDiscontinuedToggle(e.target.checked)}
                                            />
                                        </div>
                                    </Col> */}
                                </Row>
                                {filterElement}
                            </div>
                        );
                    },
                    formatter: (cell, row, rowIndex) => {
                        return (
                            <div className="d-flex flex-row flex-nowrap align-items-center">
                                <div className="d-flex flex-column w-100 ml-2">
                                    <p className="mb-0">{row["short_title"]}</p>
                                    <div className="d-flex flex-row justify-content-around text-sm">
                                        <p className="mb-0">{(row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== '' ? row['vpart_alt'] : row['vpart'])}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    },
                    csvFormatter: (cell, row, rowIndex) => {
                        return (
                            (row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== '' ? row['vpart_alt'] : row['vpart'])
                        );
                    },
                    sortValue: (cell, row) => (row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== '' ? row['vpart_alt'] : row['vpart']), // we use type name to sort.
                    filterValue: (cell, row) => `${row['short_title']} ${row['vpart_alt']} ${row['vpart']}`,
                },
            ];
        }
        else if (type === 'category') {
            columns = [
                {
                    dataField: "category",
                    text: "Category",
                    type: "string",
                    sort: true,
                    hidden: false,
                },
            ];
        }

        let selectedMetricsColumns = [];
        const defaultColumns = [
            { metric: 'total_orders', hidden: false }, // Total Orders
            { metric: 'total_units', hidden: true }, // Total Units
            { metric: 'total_sales', hidden: false }, // Total Sales
            { metric: 'total_sessions', hidden: false }, // Sessions
            { metric: 'total_page_views', hidden: true }, // Page Views
        ];

        Object.keys(selectedMetrics).map(metric => {
            if (selectedMetrics[metric] === true) {
                switch(metric) {
                    case 'traffic':
                        
                        selectedMetricsColumns.push([
                            ...defaultColumns,
                            { metric: 'order_session_pct', hidden: false }, // Order Session %
                            { metric: 'total_customers', hidden: false }, // Customers
                        ]);
    
                        sort = [{ dataField: "total_orders", order: "desc" }];
    
                        break;
    
                    case 'new_to_brand':
                        
                        selectedMetricsColumns.push([
                            { metric: 'new_to_brand', hidden: false }, // New to Brand Customers
                            { metric: 'new_to_brand_units', hidden: true }, // New to Brand Units
                            { metric: 'new_to_brand_sales', hidden: false }, // New to Brand Sales
                            { metric: 'new_to_brand_orders', hidden: false }, // New to Brand Orders
                            { metric: 'new_to_brand_session_pct', hidden: false }, // New to Brand Order Session %
                            { metric: 'total_customers', hidden: true }, // Customers
                            { metric: 'order_session_pct', hidden: true }, // Order Session %
                        ]);
    
                        sort = [{ dataField: "new_to_brand", order: "desc" }];
    
                        break;

                    case 'repeat_customers':

                        selectedMetricsColumns.push([
                            { metric: 'repeat_customers', hidden: false }, // Repeat Customers
                            { metric: 'total_customers', hidden: true }, // Customers
                            { metric: 'repeat_customer_units', hidden: false }, // Repeat Units
                            { metric: 'repeat_customer_sales', hidden: false }, // Repeat Sales
                            { metric: 'repeat_customer_session_pct', hidden: false }, // Repeat Order Session %
                        ]);
    
                        sort = [{ dataField: "repeat_customers", order: "desc" }];

                        break;

                    case 'subscribe_and_save':
                        
                        selectedMetricsColumns.push([
                            { metric: 'sns_active_subscriptions', hidden: false }, // Subscriptions
                            { metric: 'sns_units', hidden: false }, // Subscribe & Save Units
                            { metric: 'sns_sales', hidden: false }, // Subscribe & Save Sales
                            { metric: 'estimated_sns_discount', hidden: false }, // Estimated SNS Discount
                        ]);
    
                        sort = [{ dataField: "sns_active_subscriptions", order: "desc" }];

                        break;

                    case 'advertising': // Per SP, SB; per Tactic; DSP, SD - on Brand Level Only

                        selectedMetricsColumns.push([
                            { metric: 'paid_impressions', hidden: false}, // Impressions
                            { metric: 'paid_clicks', hidden: false}, // Clicks
                            { metric: 'paid_units', hidden: false}, // Units
                            { metric: 'paid_orders', hidden: false}, // Orders
                            { metric: 'paid_spend', hidden: false}, // Spend
                            { metric: 'paid_sales', hidden: false}, // Sales
                            { metric: 'paid_ctr', hidden: false}, // Click Through Rate
                            { metric: 'paid_cnv_orders', hidden: false}, // Conversion Rate
                        ]);

                        sort = [{ dataField: "paid_sales", order: "desc" }];

                        break;

                    case 'buybox':

                        selectedMetricsColumns.push([
                            { metric: 'total_page_views', hidden: false }, // Total Page Views
                            { metric: 'buybox_percentage', hidden: false }, // Buy Box %
                            { metric: 'buybox_pageviews', hidden: false }, // Fortress Page Views
                        ]);
    
                        sort = [{ dataField: "sns_active_subscriptions", order: "desc" }];

                        break;
                    
                    default:
                        // N/A
                        break;
                }
            }
        });
        
        if (selectedMetrics.traffic === false) {
            selectedMetricsColumns.push(defaultColumns);
        }

        let metrics_in_column = [];
        
        selectedMetricsColumns.forEach((metricArray) => {
            metricArray.forEach((metric) => {
                if (!metrics_in_column.includes(metric.metric)) {
                    columns = columns.concat(generateMetricColumn(metric))
                    metrics_in_column.push(metric.metric);
                }
            });
        });
        
        // console.log(selectedMetrics, selectedMetricsColumns, metrics_in_column, columns);

        return {columns: columns, sort: sort};
    }

    const getTableDescription = () => {
        let description = '';
        // console.log('getTableDescription', metadata?.timeframe[selectedTimeframe]);

        const tf_s = (metadata?.timeframe[selectedTimeframe] !== undefined ? metadata?.timeframe[selectedTimeframe].tf_s : undefined)
        const tf_e = (metadata?.timeframe[selectedTimeframe] !== undefined ? metadata?.timeframe[selectedTimeframe].tf_e : undefined)
        const pt_s = (metadata?.timeframe[selectedTimeframe] !== undefined ? metadata?.timeframe[selectedTimeframe].pt_s : undefined)
        const pt_e = (metadata?.timeframe[selectedTimeframe] !== undefined ? metadata?.timeframe[selectedTimeframe].pt_e : undefined)

        switch(selectedTimeframe) {
            case 'mtd':
                description = `Current MTD (${formatDateStr(tf_s)} - ${formatDateStr(tf_e)}) vs Previous MTD (${formatDateStr(pt_s)} - ${formatDateStr(pt_e)})`
                break;

            case 'ytd':
                description = `Current YTD (${formatDateStr(tf_s)} - ${formatDateStr(tf_e)}) vs Previous YTD (${formatDateStr(pt_s)} - ${formatDateStr(pt_e)})`
                break;
            
            case 'lm':
                description = `Last Full Month (${formatMonthDateStr(tf_s)}) vs Previous Full Month (${formatMonthDateStr(pt_s)})`
                break;
        }

        return (
            <div className="bg-gray w-100 py-3 px-2 text-center body-2-bold">
                {description}
            </div>
        )
    }

    return (
        <div>
            <div>
                <ComparativeOptions
                    setSelectedMetrics={(value) => setSelectedMetrics(value)}
                    setSelectedTimeframe={(value) => setSelectedTimeframe(value)}
                    setSelectedComparativeView={(value) => setSelectedComparativeView(value)}
                />
            </div>
            <div className="mt-4">
                {data && 
                    <BootstrapTablesWrapper
                        key={`product-comparative-${reRenderTable}`}
                        data={tableData ?? []}
                        keyField={type}
                        tableOnly={true}
                        headerClasses={'bg-gray'}
                        description={getTableDescription()}
                        hasPagination={true}
                        hasFilter={true}
                        hasSearch={false}
                        defaultSorted={defaultSorted ?? []}
                        columns={tableColumns ?? []}
                    />
                }
            </div>
        </div>
    );
};

export default ComparativeTable;
