import React from "react";

const ChartDescription = (props) => {
    return (
        <div>
            <h5 className="">{props.title}</h5>
            <p className="body-1 text-secondary mb-0">{props.description}</p>
        </div>
    );
};

export default ChartDescription;
