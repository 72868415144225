import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines } from "@fortawesome/free-solid-svg-icons";

// given a mouse event reference, determines whether the current mouse
// position is either aligned with the top or bottom half of the target element
// in order to indicate which top/bottom boundary is closest to mouse's position
// returns: 'string'
const findClosestTargetBoundary = ({target, pageY = 0}) => {
	if(target){
		const boundingRect = target.getBoundingClientRect()
		const offsetY = boundingRect.top + window.scrollY
		const halfPoint = offsetY + boundingRect.height/2
		const isHalfWay = pageY - halfPoint > 0
		return isHalfWay 
			? 'bottom'
			: 'top'
	}
}

// List component which manages state of the list based on drag/touch inputs.
// passes along event handlers to individual child list item components. 
class OrderList extends React.Component{
	// define initial component state to manage draggable element references within list
	initialState = {
		sourceIndex: false,
		targetBoundary: false,
		targetIndex: false
	}
	// set initial state in component constructor
	constructor(props){
		super(props)
		this.state={
			order: React.Children.map(
				props.children, 
				(_,i)=>i),
			...this.initialState
		}
	}
	// this component should only update during a change of source/touched component,
	// or when the render order has changed
	shouldComponentUpdate(n, m){
		return this.didOrderChange(m.order)
			|| this.state.sourceIndex !== m.sourceIndex
	}
	// given an array indicating a new order as a parameter,
	// determine whether or not the order of components has changed.
	// returns a boolean value.
	didOrderChange = newOrder => (
		!newOrder.every(
			i => newOrder[i] === this.state.order[i]
		)
	)
	// helper function to return an array of components as passed into
	// the list component as children. Used to then augment each child with additional props.
	getChildrenAsArray = () => (
		React.Children.map(
			this.props.children, 
			child=>child
		)
	)
	
	// receives an index of the item in the list that is currently being dragged.
	// calculates element boundary by accessing ref el's children, offset by page scroll.
	// ultimately determines where the drop position will be based on which half of 
	// the child element the cursor is currently located.
	handleDrag = e => {
		const {sourceIndex, targetIndex, order} = this.state
		// only trigger handler if a drag has been initiated
		if(targetIndex !== false){
			// determine the boundary closest to touch position
			const targetBoundary = findClosestTargetBoundary(e)
			// only set the state if the drop boundary has changed frop top/bottom
			// and source/target indexes are non-equal and explicitly non-false
			if( targetBoundary !== this.state.targetBoundary ){
				this.setState({
					targetBoundary,
					order: sourceIndex !== targetIndex !== false 
						? this.reorderList(
							sourceIndex,
							targetIndex,
							targetBoundary
						)
						: order
				})
			}
		}
	}
	
	// event handler which sets the state of which item index 
	// a drag item has most lately entered, updates component state.
	handleDragEnter = index => {
		if(this.state.sourceIndex !== false){
			// console.log('handleDragEnter', this.state.sourceIndex, index);
			this.setState({targetIndex: index})
		}
	}
	
	// once item is released, reset component state
	handleRelease = () => {
		// console.log('handleRelease', this.state, this.initialState);
		this.props.onHandleColumnReorder(this.state.order);
		this.setState(this.initialState)
	}
	
	// during initial touch, determine which element index has been initiated.
	handleTouch = (index, e) => {
		// console.log('handleTouch', this.state.sourceIndex, index);
		this.setState({sourceIndex: index})
	}

	// reference handle events in an object that is easy to destructure
	// and pass along to children components
	handlers = {
		drag: this.handleDrag,
		enter: this.handleDragEnter,
		release: this.handleRelease,
		touch: this.handleTouch
	}
    
	// returns an array with index values representing the new render order of the item list.
	// order is determined by which boundary the cursor is closest to within the target element.
	reorderList = (sourceIndex, targetIndex, targetBoundary) => {
		const {order} = this.state
		// console.log('reorderList', order, sourceIndex, targetIndex);
		// this.props.onHandleColumnReorder(sourceIndex, targetIndex);
		return order
			.map(
				i => i === targetIndex
					? targetBoundary === "bottom" 
						? [i, sourceIndex]
						: [sourceIndex, i]
					: i === sourceIndex
						? []
						: [i]
			)
			.reduce(
				(a = [], item) => a.concat(item)
			)
	}

	render(){
		const {order, sourceIndex, targetIndex} = this.state
		const children = this.getChildrenAsArray()
		const sortedChildren = order.map(
			i => React.cloneElement(
				children[i],
				{
					...this.handlers,
					index: i,
					touched: sourceIndex === i,
					swapped: targetIndex === i && sourceIndex !== i
				}
			)
		)
		// this is what is rendered
		return(
			<div 
				className="w-100"
				onMouseUp={this.handlers.release}
				onMouseLeave={this.handlers.release}>
					{sortedChildren}
			</div>
		)
	}
}

// List component which manages state of the list based on drag/touch inputs.
// passes along event handlers to individual child list item components. 
const OrderListComponent = (props) =>{
	const initialState = {
		sourceIndex: false,
		targetBoundary: false,
		targetIndex: false
	}

	// helper function to return an array of components as passed into
	// the list component as children. Used to then augment each child with additional props.
	const getChildrenAsArray = () => {
		return React.Children.map(
			props.children, 
			child=>child
		);
	}

	const getChildrenOrderArray = () => {
		return React.Children.map(
			props.children, 
			(_,i)=>i
		);
	}

	
	// define initial component state to manage draggable element references within list
	const [sourceIndex, setSourceIndex] = useState(initialState.sourceIndex);
	const [targetBoundary, setTargetBoundary] = useState(initialState.targetBoundary);
	const [targetIndex, setTargetIndex] = useState(initialState.targetIndex);
	const [order, setOrder] = useState(getChildrenOrderArray());

	// receives an index of the item in the list that is currently being dragged.
	// calculates element boundary by accessing ref el's children, offset by page scroll.
	// ultimately determines where the drop position will be based on which half of 
	// the child element the cursor is currently located.
	const handleDrag = e => {
		// only trigger handler if a drag has been initiated
		if(targetIndex !== false){
			// determine the boundary closest to touch position
			const newTargetBoundary = findClosestTargetBoundary(e)
			// only set the state if the drop boundary has changed frop top/bottom
			// and source/target indexes are non-equal and explicitly non-false
			if( newTargetBoundary !== targetBoundary ) {
				setTargetBoundary(newTargetBoundary);
				setOrder(sourceIndex !== targetIndex !== false 
					? reorderList(
						sourceIndex,
						targetIndex,
						targetBoundary
					)
					: order);
			}
		}
	}
	
	// event handler which sets the state of which item index 
	// a drag item has most lately entered, updates component state.
	const handleDragEnter = (index) => {
		if(sourceIndex !== false){
			setSourceIndex(sourceIndex);
		}
	}
	
	// once item is released, reset component state
	const handleRelease = () => {
		// setSourceIndex(initialState.sourceIndex);
		// setTargetBoundary(initialState.targetBoundary);
		// setTargetIndex(initialState.targetIndex);
	}
	
	// during initial touch, determine which element index has been initiated.
	const handleTouch = (index, e) => {
		setSourceIndex(index);
	}

	// reference handle events in an object that is easy to destructure
	// and pass along to children components
	const handlers = {
		drag: handleDrag(),
		enter: handleDragEnter(),
		release: handleRelease(),
		touch: handleTouch()
	}
	
	const getSortedChildren = () => {
		const children = getChildrenAsArray();

		return order.map(
			i => React.cloneElement(
				children[i],
				{
					...handlers,
					index: i,
					touched: sourceIndex === i,
					swapped: targetIndex === i && sourceIndex !== i
				}
			)
		)
	}

	const [sortedChildren, setSortedChildren] = useState(getSortedChildren());

	// onHandleColumnReorder
	// given an array indicating a new order as a parameter,
	// determine whether or not the order of components has changed.
	// returns a boolean value.
	const didOrderChange = (newOrder) => (
		!newOrder.every(
			i => newOrder[i] === order[i]
		)
	)

	// this component should only update during a change of source/touched component,
	// or when the render order has changed
	// useEffect(() => {
	// 	// if (didOrderChange(order)) {
	// 	// }
	// 	const sortedChildren = getSortedChildren();

	// 	setSortedChildren(sortedChildren);
    // }, [sourceIndex, targetIndex]);
	
    
	// returns an array with index values representing the new render order of the item list.
	// order is determined by which boundary the cursor is closest to within the target element.
	const reorderList = (sourceIndex, targetIndex, targetBoundary) => {
		console.log('NEW reorderList', order, sourceIndex, targetIndex)
		props.onHandleColumnReorder(sourceIndex, targetIndex);
		return order.map(
			i => i === targetIndex
				? targetBoundary === "bottom" 
					? [i, sourceIndex]
					: [sourceIndex, i]
				: i === sourceIndex
					? []
					: [i]
			)
			.reduce(
				(a = [], item) => a.concat(item)
			)
	}

	return(
		<div 
			className="w-100"
			onMouseUp={handlers.release}
			onMouseLeave={handlers.release}>
				{sortedChildren}
		</div>
	)
}

// Component wrapper encapsulating an item within the drag-able list.
const ListItem = (props) => {
    const {touch, drag, enter, release, render} = props;
	const {index, content, swapped, touched} = props;

    const currentElement = useRef(null);
    const [offsetY, setOffsetY] = useState(0);

    // helper function:
	// Given an offset parameter, set the reference elements style and transition properties.
	const transform = (offset) => {
		currentElement.style.transform = `translateY(${offset}px)`
		currentElement.style.transition = 'none'
	}

	// helper function:
	// remove the style attribute from the reference element, reinstating the CSS defined properties.
	const resetTransform = () => {
		currentElement.removeAttribute('style')
	}

	// within the lifecycle event, check to see if this component is either the source or target.
	// if so, set the component state to capture its current Y offset from its bounding rect.
    useEffect(() => {
        if (swapped || touched) {
			setOffsetY(currentElement.getBoundingClientRect().top);
		}
    }, []);

    // after a component has updated, check to see if it had an offsetY property within its state.
    // if so, re-calculate the components ref element's current offset and set a transforn to
    // negate the delta. Trigger a slight delay to clear the transform to trigger CSS animation.
    useEffect(() => {
        if (offsetY) {
			const {top} = currentElement.getBoundingClientRect()
			transform(offsetY - top)
			setTimeout(
				()=>{
					resetTransform()
					setOffsetY(0);
				},
				10
			)
		}

        setOffsetY(offsetY);
    }, [offsetY]);

	// render method makes reference to the components root element for future reference to
	// calculate its offset position values for transitioning the component.
	// handlers are mapped to mouse events to trigger reordering as handled by the parent component.
    return(
        <div 
            ref={currentElement}
            className={`item ${touched ? "touched" : ""}`}
            onMouseDown={touch.bind(null, index)}
            onMouseMove={drag}
            onMouseEnter={enter.bind(null, index)}
            onMouseUp={release}>
                {render}
        </div>
    )
}


// Content component within the wrapped ListItem container component.
// passed to the ListItem component via its 'render' prop.
const RowContent = (props) => {

    const {column, keyField, index} = props;

    // For no visibility hidden = false
    // For visibility hidden = true
    // So checked = NOT {hidden}.
    const [checked, setChecked] = useState(!(column.hidden));

    const [colName, setColName] = useState(column.text);

    const handleColumnName = (key, value) => {
        if (value) {
            setColName(value);
            props.onHandleColumnRename(key, value);
            return;
        }
    }
    
    const handleColumnToggle = (key, value) => {
        setChecked(value);
        props.onHandleColumnToggle(key, value);
        return;
    }
    
	return (
        keyField !== column.dataField && (
            <Row key={index} className="justify-content-around px-4 my-2">
                {/* {console.log('render RowContent', keyField, column, index)} */}
                <div className="d-flex align-items-center moveable">
                    <FontAwesomeIcon icon={faGripLines} />
                </div>
                <Col>
                    <Form.Control 
                        placeholder={colName}
                        value={colName} 
                        disabled={keyField === column.dataField ? true : false}
                        onChange={(e) => handleColumnName(column.dataField, e.target.value)}
                    />
                </Col>
                <div className="d-flex align-items-center">
                    <Form.Check 
                        id={`column-${index}-toggle-switch`}
                        type="switch"
                        label="On"
                        disabled={keyField === column.dataField ? true : false}
                        checked={keyField === column.dataField ? true : checked}
                        onChange={(e) => handleColumnToggle(column.dataField, e.target.checked)}
                    />
                </div>
            </Row>
        )
	)
}

// Top-Level container component.
// responsible for passing list props to list components
const DraggableColumnList = (props) => {
    const { columns, onColumnToggle, toggles, keyField } = props;

	const updateColumnVisibility = (key, value) => {
		// console.log('updateColumnVisibility', columns, key, value);

        const colIndex = columns.findIndex(object => object.dataField === key);
		
		// If false, we hide (hidden = true)
        // If true, we show (hidden = false)
		columns[colIndex].hidden = !(value);

        // console.log('updateColumnVisibility END', columns);
		props.updateColumns(columns);
		return;
	}

	const updateColumnName = (key, value) => {
		// console.log('updateColumnName', columns, key, value);

        const colIndex = columns.findIndex(object => object.dataField === key);
		
		// Rename as user supplied
		columns[colIndex].text = value;

        // console.log('updateColumnName END', columns);
		props.updateColumns(columns);
		return;
	}

	const updateColumnOrder = (value) => {
		// console.log('updateColumnOrder', columns, value);
		let temp = [];
		for (var i = 0; i < value.length; i++) {
			// console.log(i, columns[value[i]]);
			temp.push(columns[value[i]]);
		}

        // console.log('updateColumnOrder END', temp);
		props.updateColumns(temp);
		return;
	}

	const [columnsSelectComponents, setColumnsSelectComponents] = useState();

	useEffect(() => {
		setColumnsSelectComponents(columns.map((column) => ({
				...column,
				toggle: toggles[column.dataField],
			}))
			.map((column, index) => (
				<ListItem 
					key={`columnComponent-${index}`} 
					render={
						<RowContent
							index={index}
							column={column}
							keyField={keyField}
							onHandleColumnToggle={(key, value) => updateColumnVisibility(key, value)}
							onHandleColumnRename={(key, value) => updateColumnName(key, value)}
						/>
					}
					/>
			))
		);
    }, [columns]);


    return (
		<div>
			{columnsSelectComponents && (
				<OrderList
					onHandleColumnReorder={(newOrder) => updateColumnOrder(newOrder)}
				>
					{columnsSelectComponents}
				</OrderList>
			)}

			{/* <OrderListComponent 
				// onHandleColumnReorder={(oldValue, newValue) => props.updateColumnOrder(oldValue, newValue)}
			>
				{columnsSelectComponents}
			</OrderListComponent> */}
		</div>
    )
}

export default DraggableColumnList;